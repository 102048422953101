import { isEmpty } from "lodash";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCreateContactMutation, useUpdateContactMutation, useDeleteContactMutation } from "store/apiSlice";

import { IdsText, IdsButton } from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { TrmDetailsDelete } from "pages/ExploreTrms/TrmDetails/TrmDetailsDelete";

import { ContactEditCard } from "./ContactEditCard";
import { ContactCard } from "./ContactCard";

import { circlePlusRegularIcon, userSlashSolidIcon } from "utils/icons";

import type { ApiResponse, Contact } from "types";

export const Contacts: React.FC<{
    trmFamiliarName: string;
    publisherNumber: string;
    contacts: Contact[];
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
}> = ({ trmFamiliarName, publisherNumber, contacts, isEditing, setIsEditing }) => {
    const [contactNumberForEdit, setContactNumberForEdit] = useState("");
    const [contactForDelete, setContactForDelete] = useState<Contact>();

    const [createContact, createContactStatus] = useCreateContactMutation();
    const [updateContact, updateContactStatus] = useUpdateContactMutation();
    const [deleteContact, deleteContactStatus] = useDeleteContactMutation();

    const onCancel = () => {
        setIsEditing(false);
        setContactNumberForEdit("");
    };

    const onSave = async (
        firstName: string,
        lastName: string,
        company: string,
        title: string,
        phone: string,
        extension: string,
        email: string,
    ) => {
        // Do nothing if saving is in progress
        if (createContactStatus.isLoading || updateContactStatus.isLoading) {
            return;
        }

        try {
            let response: ApiResponse;

            const contactModel = {
                firstName,
                lastName,
                company,
                title,
                phone,
                extension,
                email,
                publisherNumber,
            };

            if (contactNumberForEdit) {
                response = await updateContact({ contactNumber: contactNumberForEdit, contact: contactModel }).unwrap();
            } else {
                response = await createContact({ contact: contactModel }).unwrap();
            }

            if (response.responseStatus === "success") {
                setIsEditing(false);
                setContactNumberForEdit("");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onEdit = (contactNumber: string) => {
        setIsEditing(true);
        setContactNumberForEdit(contactNumber);
    };

    const onAddContact = () => {
        setIsEditing(true);
    };

    const onDelete = async (contactNumber: string) => {
        // Do nothing if deleting is in progress
        if (deleteContactStatus.isLoading) {
            return;
        }

        try {
            await deleteContact({ publisherNumber, contactNumber });

            setContactForDelete(undefined);
        } catch (error) {
            console.error(error);
        }
    };

    if (contactForDelete) {
        return (
            <TrmDetailsDelete
                headerText="Delete Contact"
                trmFamiliarName={trmFamiliarName}
                confirmationMessage="Are you sure you want to delete this contact?"
                isLoading={deleteContactStatus.isLoading}
                onClose={() => setContactForDelete(undefined)}
                onDelete={() => onDelete(contactForDelete.contactNumber)}
            >
                <ContactCard
                    firstName={contactForDelete.firstName}
                    lastName={contactForDelete.lastName}
                    company={contactForDelete.company ?? undefined}
                    title={contactForDelete.title ?? undefined}
                    phone={contactForDelete.phone ?? undefined}
                    extension={contactForDelete.extension ?? undefined}
                    email={contactForDelete.email ?? undefined}
                />
            </TrmDetailsDelete>
        );
    }

    return (
        <div className="flex-column fill-height">
            {isEmpty(contacts) && !isEditing ? (
                <NothingFoundBlock
                    title="No Contacts"
                    icon={userSlashSolidIcon}
                    message="Added contacts will show here"
                    actionLabel="Add Contact"
                    actionIcon={circlePlusRegularIcon}
                    onActionClick={onAddContact}
                />
            ) : (
                <div className="flex-column gap-1">
                    <div className="flex-row align-self-end">
                        <IdsText>{contacts.length === 1 ? `${contacts.length} contact` : `${contacts.length} contacts`}</IdsText>
                    </div>
                    <div className="flex-column gap-2">
                        {contacts.map((contact) => (
                            <div key={contact.contactNumber}>
                                {contact.contactNumber === contactNumberForEdit ? (
                                    <ContactEditCard
                                        firstName={contact.firstName}
                                        lastName={contact.lastName}
                                        company={contact.company ?? undefined}
                                        title={contact.title ?? undefined}
                                        phone={contact.phone ?? undefined}
                                        extension={contact.extension ?? undefined}
                                        email={contact.email ?? undefined}
                                        isLoading={createContactStatus.isLoading || updateContactStatus.isLoading}
                                        onCancel={onCancel}
                                        onSave={onSave}
                                    />
                                ) : (
                                    <ContactCard
                                        firstName={contact.firstName}
                                        lastName={contact.lastName}
                                        company={contact.company ?? undefined}
                                        title={contact.title ?? undefined}
                                        phone={contact.phone ?? undefined}
                                        extension={contact.extension ?? undefined}
                                        email={contact.email ?? undefined}
                                        onDelete={!isEditing ? () => setContactForDelete(contact) : undefined}
                                        onEdit={!isEditing ? () => onEdit(contact.contactNumber) : undefined}
                                    />
                                )}
                            </div>
                        ))}
                        {isEditing && !contactNumberForEdit && (
                            <ContactEditCard
                                isLoading={createContactStatus.isLoading || updateContactStatus.isLoading}
                                onCancel={onCancel}
                                onSave={onSave}
                            />
                        )}
                        {!isEditing && (
                            <div
                                className="rounded-edges-rounder"
                                style={{ border: "1px dashed var(--ids-semantic-border-color-neutral-faint)" }}
                            >
                                <IdsButton variant="tertiary" padding="lg" fullWidth clickHandler={onAddContact}>
                                    <div className="flex-row align-center gap-2">
                                        <FontAwesomeIcon icon={circlePlusRegularIcon} size="lg" />
                                        Add Contact
                                    </div>
                                </IdsButton>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
