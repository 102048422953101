import { isEmpty } from "lodash";
import { createContext, useCallback, useMemo, useRef, useState } from "react";

import { IdsContainer, IdsRow, IdsCol, IdsCard } from "@emergn-infinity/ids-react";

import { useGetRelevantAlgorithmsQuery } from "store/apiSlice";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { selectAllRelAlgoritms, resetTrigger } from "./benchmarkTrmSlice";

import { Sidebar } from "./Sidebar";
import { MainPanel } from "./MainPanel";

import { ChooseItem } from "components/ChooseItem";

import { bookSolidIcon } from "utils/icons";

export const BenchmarkTrmContext = createContext<{
    isSelecting?: boolean;
    setIsSelecting?: (isSelecting: boolean) => void;
}>({});

export const BenchmarkTrm = () => {
    const dispatch = useAppDispatch();

    const [isSelecting, setIsSelecting] = useState(false);

    const selectTrmRef = useRef<HTMLIdsDropdownElement>(null);

    const { selectedTrm, selectedMeasure, selectedSector, selectedVintage, selectedFuelType, selectedRelAlgorithms, triggerSelect } =
        useAppSelector((state) => state.benchmarkTrm);

    const { data: relAlgorithms, isFetching: isFetchingRelAlgorithms } = useGetRelevantAlgorithmsQuery(
        {
            trmNumber: selectedTrm,
            measureNumber: selectedMeasure,
            sectorNumber: selectedSector,
            vintageNumber: selectedVintage,
            fuelTypeNumber: selectedFuelType,
        },
        { skip: isEmpty(selectedTrm) || isEmpty(selectedMeasure) },
    );

    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);

    // Selects all relevant algorithms on measure, sector, vintage or fuel type change
    if (relAlgorithms !== undefined && !isFetchingRelAlgorithms && triggerSelect) {
        dispatch(resetTrigger());

        dispatch(selectAllRelAlgoritms([...relAlgorithms]));
    }

    const onChooseClick = useCallback(() => {
        setTimeout(() => {
            selectTrmRef.current?.querySelector("ids-icon")?.click();
        }, 100);
    }, []);

    return (
        <BenchmarkTrmContext.Provider value={useMemo(() => ({ isSelecting, setIsSelecting }), [isSelecting, setIsSelecting])}>
            <IdsContainer fullHeight customClasses="p-0">
                <IdsRow noGutters>
                    <IdsCol xs="3">
                        <IdsCard customClasses="fill-height" style={{ borderRight: "1px solid var(--theme-base-border)" }}>
                            <div slot="slot1" className="fill-height">
                                <Sidebar
                                    selectedTrm={selectedTrm}
                                    selectedMeasure={selectedMeasure}
                                    selectedSector={selectedSector}
                                    selectedVintage={selectedVintage}
                                    selectedFuelType={selectedFuelType}
                                    selectedRelAlgorithms={selectedRelAlgorithms}
                                    selectTrmRef={selectTrmRef}
                                    isFetching={isFetchingRelAlgorithms}
                                    relAlgorithms={relAlgorithms}
                                />
                            </div>
                        </IdsCard>
                    </IdsCol>
                    <IdsCol xs="9">
                        {showMainContent ? (
                            <IdsCard customClasses="fill-height" style={{ boxShadow: "none" }}>
                                <div slot="slot1" className="fill-height">
                                    <MainPanel />
                                </div>
                            </IdsCard>
                        ) : (
                            <ChooseItem
                                icon={bookSolidIcon}
                                title="No TRM Chosen"
                                message="Please select a TRM and Measure to benchmark"
                                actionLabel="Choose TRM"
                                onActionClick={onChooseClick}
                            />
                        )}
                    </IdsCol>
                </IdsRow>
            </IdsContainer>
        </BenchmarkTrmContext.Provider>
    );
};
