import cn from "classnames";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
    IdsModal,
    IdsText,
    IdsDropdown,
    IdsButtonGroup,
    IdsButton,
    IdsTable,
    IdsTableRow,
    IdsTableCell,
    IdsTag,
} from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EditFormTags } from "components/EditFormTags";
import { IconButton } from "components/IconButton";
import { Menu } from "components/Menu";
import { NothingFoundBlock } from "components/NothingFoundBlock";

import {
    useUpdateStandardizingForAlgorithmMutation,
    useGetFuelTypesQuery,
    useGetStdEndUsesQuery,
    useGetStdMeasuresQuery,
    useGetStdPermutationsQuery,
    useGetStdSectorsQuery,
    useGetStdVintagesQuery,
} from "store/apiSlice";

import { arrowProgressSolidIcon, circlePlusRegularIcon, trashRegularIcon } from "utils/icons";

import type { Measure, MeasureAlgorithm, StdPermutation } from "types";

const ALGORITHM_MAPPING_MODAL_STYLE = {
    width: "948px",
    maxWidth: "100%",
    height: "100%",
};

const style = {
    measure: {
        minWidth: 0,
        width: "100%",
    },
    sector: {
        minWidth: "10.125rem",
    },
    endUse: {
        minWidth: "7.5rem",
    },
    vintage: {
        minWidth: "8.25rem",
    },
    fuelType: {
        minWidth: "7.5rem",
    },
    actions: {
        minWidth: "5.25rem",
    },
};

let timeoutId: NodeJS.Timeout;

export const AlgorithmMappings: React.FC<{
    measure: Measure;
    algorithm: MeasureAlgorithm;
    onClose: () => void;
}> = ({ measure, algorithm, onClose }) => {
    const [stdPermutationList, setStdPermutationList] = useState<StdPermutationModel[]>([]);
    const [isPermutationListFilled, setIsPermutationListFilled] = useState(false); // flag to fill standard permutations with data from database

    const [selectedStdMeasure, setSelectedStdMeasure] = useState("");
    const [selectedStdSector, setSelectedStdSector] = useState("");
    const [selectedStdEndUse, setSelectedStdEndUse] = useState("");
    const [selectedStdVintage, setSelectedStdVintage] = useState("");
    const [selectedFuelType, setSelectedFuelType] = useState("");

    const { data: stdPermutations } = useGetStdPermutationsQuery({ algorithmNumber: algorithm.algorithmNumber });
    const { data: stdMeasures } = useGetStdMeasuresQuery();
    const { data: stdSectors } = useGetStdSectorsQuery();
    const { data: stdEndUses } = useGetStdEndUsesQuery({});
    const { data: stdVintages } = useGetStdVintagesQuery();
    const { data: fuelTypes } = useGetFuelTypesQuery();

    const [updateAlgorithmStandardizing, updateAlgorithmStandardizingStatus] = useUpdateStandardizingForAlgorithmMutation();

    const areChangesMade = stdPermutations
        ? stdPermutationList.filter((permutation) => !permutation.isBeingDeleted).length !== stdPermutations.length
        : false;

    if (stdPermutations && !isEmpty(stdPermutations) && isEmpty(stdPermutationList) && !isPermutationListFilled) {
        setIsPermutationListFilled(true);

        setStdPermutationList(
            stdPermutations.map((permutation) => ({
                ...permutation,
                isBeingDeleted: false,
            })),
        );
    }

    useEffect(() => {
        // If permutation is marked as to be deleted,
        // remove it from the permutation list after 10 seconds
        if (stdPermutationList.some((permutation) => permutation.isBeingDeleted)) {
            const stdPermutationNumber = stdPermutationList.find((permutation) => permutation.isBeingDeleted)?.stdPermutationNumber;

            timeoutId = setTimeout(() => {
                setStdPermutationList(
                    stdPermutationList.filter((permutation) => permutation.stdPermutationNumber !== stdPermutationNumber),
                );
            }, 10000);
        }

        // Cancels timeout on every re-run
        return () => {
            clearTimeout(timeoutId);
        };
    }, [stdPermutationList]);

    const stdMeasureList = useMemo(() => {
        return (stdMeasures ?? []).map((measure) => ({
            label: measure.stdMeasureName,
            value: measure.stdMeasureNumber,
        }));
    }, [stdMeasures]);

    const stdSectorList = useMemo(() => {
        return (stdSectors ?? []).map((sector) => ({
            label: sector.stdSectorName,
            value: sector.stdSectorNumber,
        }));
    }, [stdSectors]);

    const stdEndUseList = useMemo(() => {
        return (stdEndUses ?? []).map((endUse) => ({
            label: endUse.stdEndUseName,
            value: endUse.stdEndUseNumber,
        }));
    }, [stdEndUses]);

    const stdVintageList = useMemo(() => {
        return (stdVintages ?? []).map((vintage) => ({
            label: vintage.stdVintageName,
            value: vintage.stdVintageNumber,
        }));
    }, [stdVintages]);

    const fuelTypeList = useMemo(() => {
        return (fuelTypes ?? []).map((ft) => ({
            label: ft.fuelType,
            value: ft.fuelTypeNumber,
        }));
    }, [fuelTypes]);

    const onAddMapping = () => {
        if (!stdMeasures || !stdSectors || !stdEndUses || !stdVintages || !fuelTypes) {
            return;
        }

        const newStdPermutation = {
            stdPermutationNumber: uuidv4(),
            stdMeasureNumber: selectedStdMeasure,
            stdSectorNumber: selectedStdSector,
            stdEndUseNumber: selectedStdEndUse,
            stdVintageNumber: selectedStdVintage,
            fuelTypeNumber: selectedFuelType,
            measure: stdMeasures.find((measure) => measure.stdMeasureNumber === selectedStdMeasure)?.stdMeasureName ?? "",
            sector: stdSectors.find((sector) => sector.stdSectorNumber === selectedStdSector)?.stdSectorName ?? "",
            endUse: stdEndUses.find((endUse) => endUse.stdEndUseNumber === selectedStdEndUse)?.stdEndUseName ?? "",
            vintage: stdVintages.find((vintage) => vintage.stdVintageNumber === selectedStdVintage)?.stdVintageName ?? "",
            fuelType: fuelTypes.find((ft) => ft.fuelTypeNumber === selectedFuelType)?.fuelType ?? "",
            isBeingDeleted: false,
        };

        setStdPermutationList([...stdPermutationList, newStdPermutation]);

        toast.success("Mapping successfully added");
    };

    const onDeleteMapping = (stdPermutationNumber: string) => {
        let newStdPermutationList = [...stdPermutationList];

        // If there already is a permutation that is marked as to be deleted,
        // remove it from the permutation list immediately
        if (newStdPermutationList.some((permutation) => permutation.isBeingDeleted)) {
            const stdPermutation = newStdPermutationList.find((permutation) => permutation.isBeingDeleted);

            if (stdPermutation) {
                newStdPermutationList = newStdPermutationList.filter(
                    (permutation) => permutation.stdPermutationNumber !== stdPermutation.stdPermutationNumber,
                );
            }
        }

        newStdPermutationList = newStdPermutationList.map((permutation) =>
            permutation.stdPermutationNumber === stdPermutationNumber ? { ...permutation, isBeingDeleted: true } : permutation,
        );

        setStdPermutationList(newStdPermutationList);
    };

    const onUndoDelete = (stdPermutationNumber: string) => {
        setStdPermutationList(
            stdPermutationList.map((permutation) =>
                permutation.stdPermutationNumber === stdPermutationNumber ? { ...permutation, isBeingDeleted: false } : permutation,
            ),
        );
    };

    const onSave = async () => {
        if (updateAlgorithmStandardizingStatus.isLoading || isEmpty(stdPermutationList)) {
            return;
        }

        try {
            const dateActivated = new Date(Date.now()).toISOString();

            const algorithms = stdPermutationList
                .filter((permutation) => !permutation.isBeingDeleted)
                .map((permutation) => ({
                    algorithmNumber: algorithm.algorithmNumber,
                    stdMeasureNumber: permutation.stdMeasureNumber,
                    stdSectorNumber: permutation.stdSectorNumber,
                    stdEndUseNumber: permutation.stdEndUseNumber,
                    stdVintageNumber: permutation.stdVintageNumber,
                    fuelTypeNumber: permutation.fuelTypeNumber,
                    dateActivated,
                }));

            await updateAlgorithmStandardizing({ algorithms });

            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal
            version={2}
            isOpen
            closeHandler={onClose}
            showCloseButton
            customClasses={cn("slideout", {
                "empty-state": isEmpty(stdPermutationList),
            })}
        >
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Standardize Algorithm
                </IdsText>
            </div>
            <div className="flex-column" slot="main" style={ALGORITHM_MAPPING_MODAL_STYLE}>
                <div className="flex-row align-end gap-4 pb-4">
                    <EditFormTags
                        trmName={measure.trmFamiliarName}
                        measureName={measure.measureName}
                        algorithm={algorithm.algorithm}
                        withoutPadding
                    />
                    <Menu
                        id="algorithm-mappings-menu"
                        buttonLabel="Add Mapping"
                        buttonIconBefore={circlePlusRegularIcon}
                        buttonVariant="secondary"
                        buttonPadding="sm"
                        actionLabel="Add"
                        actionIsDisabled={
                            isEmpty(selectedStdMeasure) ||
                            isEmpty(selectedStdSector) ||
                            isEmpty(selectedStdEndUse) ||
                            isEmpty(selectedStdVintage) ||
                            isEmpty(selectedFuelType)
                        }
                        width={420}
                        onAction={onAddMapping}
                    >
                        <div className="flex-column gap-3">
                            <IdsDropdown
                                size="sm"
                                idValue="std-measures"
                                isSearchable
                                placeholder="Search Standard Measures"
                                items={stdMeasureList}
                                changeHandler={(stdMeasure) => setSelectedStdMeasure(stdMeasure)}
                                clearHandler={() => setSelectedStdMeasure("")}
                            />
                            <IdsDropdown
                                size="sm"
                                idValue="std-sectors"
                                isSearchable
                                placeholder="Search Standard Sectors"
                                items={stdSectorList}
                                changeHandler={(stdSector) => setSelectedStdSector(stdSector)}
                                clearHandler={() => setSelectedStdSector("")}
                            />
                            <IdsDropdown
                                size="sm"
                                idValue="std-end-uses"
                                isSearchable
                                placeholder="Search End Uses"
                                items={stdEndUseList}
                                changeHandler={(stdEndUse) => setSelectedStdEndUse(stdEndUse)}
                                clearHandler={() => setSelectedStdEndUse("")}
                            />
                            <IdsDropdown
                                size="sm"
                                idValue="std-vintages"
                                isSearchable
                                placeholder="Search Standard Vintages"
                                items={stdVintageList}
                                changeHandler={(stdVintage) => setSelectedStdVintage(stdVintage)}
                                clearHandler={() => setSelectedStdVintage("")}
                            />
                            <IdsDropdown
                                size="sm"
                                idValue="std-fuel-type"
                                isSearchable
                                placeholder="Search Fuel Types"
                                items={fuelTypeList}
                                changeHandler={(fuelType) => setSelectedFuelType(fuelType)}
                                clearHandler={() => setSelectedFuelType("")}
                            />
                        </div>
                    </Menu>
                </div>
                {isEmpty(stdPermutationList) ? (
                    <div className="bg-theme-base fill-height py-4">
                        <NothingFoundBlock
                            icon={arrowProgressSolidIcon}
                            title="Algorithm not mapped"
                            message="Mapped standard permutations will show here"
                        />
                    </div>
                ) : (
                    <IdsTable variant="alternate" spacing="sm">
                        <IdsTableRow rowType="table-heading-row" customClasses="sticky-top">
                            <IdsTableCell cellType="table-heading-cell" heading="Standard Measure" style={style.measure} />
                            <IdsTableCell cellType="table-heading-cell" heading="Sector" style={style.sector} />
                            <IdsTableCell cellType="table-heading-cell" heading="End Use" style={style.endUse} />
                            <IdsTableCell cellType="table-heading-cell" heading="Vintage" style={style.vintage} />
                            <IdsTableCell cellType="table-heading-cell" heading="Fuel Type" style={style.fuelType} />
                            <IdsTableCell cellType="table-heading-cell" style={style.actions} />
                        </IdsTableRow>
                        {stdPermutationList.map((item) => (
                            <IdsTableRow key={item.stdPermutationNumber} rowType="table-body-row">
                                <IdsTableCell style={{ ...style.measure, verticalAlign: "middle" }}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsText>{item.measure}</IdsText>
                                        ) : (
                                            <div className="flex-row align-center gap-2">
                                                <FontAwesomeIcon
                                                    icon={trashRegularIcon}
                                                    size="lg"
                                                    color="var(--ids-semantic-ink-color-brand-b-subtlest)"
                                                />
                                                <IdsText size="md" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                                                    Mapping Deleted
                                                </IdsText>
                                            </div>
                                        )}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.sector}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsTag variant="brand-c" size="sm" style={{ maxWidth: "9.125rem" }}>
                                                <div className="text-truncate">{item.sector}</div>
                                            </IdsTag>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.endUse}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsTag variant="brand-d" size="sm" style={{ maxWidth: "6.5rem" }}>
                                                <div className="text-truncate">{item.endUse}</div>
                                            </IdsTag>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.vintage}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsTag size="sm" style={{ maxWidth: "7.25rem" }}>
                                                <div className="text-truncate">{item.vintage}</div>
                                            </IdsTag>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.fuelType}>
                                    <div>{!item.isBeingDeleted ? <IdsText>{item.fuelType}</IdsText> : null}</div>
                                </IdsTableCell>
                                <IdsTableCell style={{ ...style.actions, verticalAlign: "middle" }}>
                                    <div className="flex-row justify-end">
                                        {!item.isBeingDeleted ? (
                                            <IconButton
                                                icon={trashRegularIcon}
                                                size="lg"
                                                title="Delete Mapping"
                                                onClick={() => onDeleteMapping(item.stdPermutationNumber)}
                                            />
                                        ) : (
                                            <IdsButton
                                                variant="tertiary"
                                                padding="sm"
                                                clickHandler={() => onUndoDelete(item.stdPermutationNumber)}
                                            >
                                                Undo
                                            </IdsButton>
                                        )}
                                    </div>
                                </IdsTableCell>
                            </IdsTableRow>
                        ))}
                    </IdsTable>
                )}
            </div>
            <div slot="footer">
                {areChangesMade && (
                    <IdsButtonGroup spaceBetween="lg">
                        <IdsButton variant="secondary" clickHandler={onClose}>
                            Discard and Cancel
                        </IdsButton>
                        <IdsButton clickHandler={onSave}>Save Changes</IdsButton>
                    </IdsButtonGroup>
                )}
            </div>
        </IdsModal>
    );
};

type StdPermutationModel = StdPermutation & {
    isBeingDeleted: boolean;
};
