import { camelCase } from "lodash";
import { usePopper as useReactPopper } from "react-popper";

import type { ComputedPlacement } from "@popperjs/core";

import { popperOptions } from "./utils";

export const usePopper = (props: UsePopperParams) => {
    const placement = camelCase(props.placement);

    const options = {
        ...popperOptions[placement],
        modifiers: [
            ...popperOptions[placement].modifiers,
            {
                name: "arrow",
                options: {
                    element: props.arrowElement,
                },
            },
            {
                name: "offset",
                options: {
                    offset: props.offset,
                },
            },
        ],
    };

    return useReactPopper(props.referenceElement, props.popperElement, options);
};

interface UsePopperParams {
    /**
     * Reference element for React Popper.
     */
    referenceElement?: Element | null;

    /**
     * Popper element for React Popper.
     */
    popperElement?: HTMLElement | null;

    /**
     * Arrow element for React Popper.
     */
    arrowElement?: HTMLElement | null;

    /**
     * Tooltip placement related to the element.
     */
    placement?: ComputedPlacement;

    /**
     * Offset from its reference element.
     */
    offset?: number[];
}
