import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { IdsModal, IdsText, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";
import { TRM_MODAL_STYLE, TrmFilters } from "pages/ExploreTrms/utils";

import { useUpgradeTrmVersionMutation } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";
import { useSendEventMutation } from "store/signalrSlice";
import { startUpgrade } from "store/upgradeTrmSlice";

import { arrowLeftSolidIcon, arrowRightSolidIcon } from "utils/icons";

import { TrmInformationStep } from "./TrmInformationStep";
import { LinksStep } from "./LinksStep";

import type { TrmDetails, UpdateTrmLinkModel, TrmLink } from "types";

export const TrmUpdateVersion: React.FC<{
    trmDetails: TrmDetails;
    trmLinks: TrmLink[];
    onClose: () => void;
}> = ({ trmDetails, trmLinks, onClose }) => {
    const dispatch = useAppDispatch();

    // TRM Information

    const [trmFamiliarName, setTrmFamiliarName] = useState(trmDetails.trmFamiliarName);
    const [trmName, setTrmName] = useState(trmDetails.trmName);
    const [preparedBy, setPreparedBy] = useState(trmDetails.preparedBy);
    const [applicableYear, setApplicableYear] = useState(String(trmDetails.applicableYear));
    const [yearPrepared, setYearPrepared] = useState(String(trmDetails.yearPrepared));
    const [defSummerPeak, setDefSummerPeak] = useState(trmDetails.defSummerPeak);
    const [defWinterPeak, setDefWinterPeak] = useState(trmDetails.defWinterPeak);

    // Links

    const [links, setLinks] = useState<LinkModel[]>(trmLinks);

    const [step, setStep] = useState(0);
    const [isEditingLinks, setIsEditingLinks] = useState(false);

    const [update, updateStatus] = useUpgradeTrmVersionMutation();
    const [sendEvent] = useSendEventMutation();

    const isNextButtonDisabled =
        isEmpty(trmFamiliarName) ||
        isEmpty(trmName) ||
        isEmpty(preparedBy) ||
        isEmpty(applicableYear) ||
        isEmpty(yearPrepared) ||
        isEmpty(defSummerPeak) ||
        isEmpty(defWinterPeak);

    const onChange = useCallback((value: string | undefined, name: string) => {
        value = value ?? "";

        switch (name) {
            case "trmFamiliarName":
                setTrmFamiliarName(value);

                break;

            case "trmName":
                setTrmName(value);

                break;

            case "preparedBy":
                setPreparedBy(value);

                break;

            case "applicableYear":
                setApplicableYear(value);

                break;

            case "yearPrepared":
                setYearPrepared(value);

                break;

            case "defSummerPeak":
                setDefSummerPeak(value);

                break;

            case "defWinterPeak":
                setDefWinterPeak(value);

                break;
        }
    }, []);

    const onCloseClick = () => {
        if (step === 0) {
            onClose();

            return;
        }

        setStep((prevStep) => prevStep - 1);
        setIsEditingLinks(false);
    };

    const onSubmit = async () => {
        if (step === 0) {
            setStep((prevStep) => prevStep + 1);

            return;
        }

        // Do nothing if saving is in progress
        if (updateStatus.isLoading) {
            return;
        }

        try {
            const trmDetailsModel = {
                trmFamiliarName,
                trmName,
                active: false,
                trmUrl: trmDetails.trmUrl,
                applicableYear: Number(applicableYear),
                yearPrepared: Number(yearPrepared),
                preparedBy,
                eTrmUrl: trmDetails.eTrmUrl,
                defWinterPeak,
                defSummerPeak,
                publisherNumber: trmDetails.publisherNumber,
                links: links.map((link) => ({
                    linkName: link.linkName,
                    linkDescription: link.linkDescription,
                    urlLink: link.urlLink,
                    azureBlobStorageLink: link.azureBlobStorageLink,
                    availability: link.availability!,
                    format: link.format!,
                })),
            };

            const response = await update({ trmDetails: trmDetailsModel }).unwrap();

            if (response.responseStatus === "success") {
                const publisherNumber = trmDetails.publisherNumber;
                const sourceTrmNumber = trmDetails.trmNumber;
                const targetTrmNumber = response.targetTrmNumber;

                dispatch(selectorChange({ selector: "filter", value: TrmFilters.All }));
                dispatch(selectorChange({ selector: "trm", value: targetTrmNumber }));

                const event = {
                    name: "UpgradeAsync",
                    data: [trmDetails.publisherNumber, sourceTrmNumber, targetTrmNumber],
                };

                await sendEvent({ event });

                dispatch(startUpgrade({ publisherNumber, sourceTrmNumber, targetTrmNumber }));

                onClose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Update TRM Version
                </IdsText>
            </div>
            <div slot="main" style={TRM_MODAL_STYLE}>
                {step === 0 && (
                    <TrmInformationStep
                        trmFamiliarName={trmFamiliarName}
                        trmName={trmName}
                        preparedBy={preparedBy}
                        applicableYear={applicableYear}
                        yearPrepared={yearPrepared}
                        defSummerPeak={defSummerPeak}
                        defWinterPeak={defWinterPeak}
                        onChange={onChange}
                    />
                )}
                {step === 1 && (
                    <LinksStep
                        trmFamiliarName={trmFamiliarName}
                        links={links}
                        isEditing={isEditingLinks}
                        setLinks={setLinks}
                        setIsEditing={setIsEditingLinks}
                    />
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onCloseClick}>
                        <div className="flex-row align-center gap-2">
                            {step === 0 ? (
                                "Cancel"
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={arrowLeftSolidIcon} fixedWidth />
                                    Back
                                </>
                            )}
                        </div>
                    </IdsButton>
                    <IdsButton variant="primary" isDisabled={isNextButtonDisabled || isEditingLinks} clickHandler={onSubmit}>
                        <div className="flex-row align-center gap-2">
                            {step === 0 ? (
                                <>
                                    Next
                                    <FontAwesomeIcon icon={arrowRightSolidIcon} fixedWidth />
                                </>
                            ) : (
                                "Submit For Review"
                            )}
                        </div>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

export type LinkModel = UpdateTrmLinkModel & {
    linkNumber: string;
};
