import Login from "./Login";
import { ChangePassword } from "./Login/ChangePassword";
import { ForgotPassword } from "./Login/ForgotPassword";
import { ResetPassword } from "./Login/ResetPassword";
import { SetupAuthenticator } from "./Login/SetupAuthenticator";
import { SsoWrapper } from "./Login/SsoWrapper";
import Home from "./Home";

import { IndexSignature } from "types";

export const availableViews: IndexSignature<any> = {
    Login,
    ChangePassword,
    ForgotPassword,
    ResetPassword,
    SetupAuthenticator,
    SsoWrapper,

    Home,
};
