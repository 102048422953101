import { useCallback, useMemo, useState } from "react";

import { IdsText, IdsCheckbox, IdsTextInput, IdsDivider, IdsTablePagination } from "@emergn-infinity/ids-react";

import { STANDARDIZE_HEADER_HEIGHT } from "pages/StandardizeData/utils";
import { ITEMS_PER_PAGE } from "utils/constants";

import "./style.scss";

export const Standardize: React.FC<{
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    allSelected: boolean;
    pageSize: number;
    pageNumber: number;
    tableComponent: React.ReactElement;
    totalRows?: number;
    totalPages?: number;
    onSelectAll: () => void;
    onChange: (e: any, name: string) => void;
    onPageChange: (pageNumber: number) => void;
}> = ({
    name,
    fullName,
    showAll,
    hideInactive,
    search,
    allSelected,
    pageSize,
    pageNumber,
    tableComponent,
    totalRows,
    totalPages,
    onSelectAll,
    onChange,
    onPageChange,
}) => {
    const [itemsWidth, setItemsWidth] = useState(0);

    const fromNumber = useMemo(() => {
        return pageNumber * pageSize - pageSize + 1;
    }, [pageSize, pageNumber]);

    const toNumber = useMemo(() => {
        let toNumber = pageNumber * pageSize;

        if (totalRows && totalRows < pageNumber * pageSize) {
            toNumber = totalRows;
        }

        return toNumber;
    }, [pageSize, pageNumber, totalRows]);

    const itemsText = totalRows !== undefined && totalRows !== 1 && !name.endsWith("s") ? `${name.toLowerCase()}s` : name.toLowerCase();

    const onPaginationRef = useCallback((paginationNode: HTMLIdsTablePaginationElement) => {
        if (paginationNode) {
            setItemsPerPageWidth(paginationNode, setItemsWidth);
        }
    }, []);

    return (
        <div className="flex-column fill-height standardize">
            <div style={{ minHeight: STANDARDIZE_HEADER_HEIGHT }}>
                <IdsText weight="bold">{`Select ${fullName} to Standardize:`}</IdsText>
                <div className="flex-row gap-4 p-2">
                    <IdsCheckbox
                        idValue="show-all"
                        label={`Show all ${fullName}`}
                        defaultChecked={showAll}
                        title={`If unchecked, only ${fullName} that have not yet been standardized will show in the table below.`}
                        changeHandler={(event) => onChange(event, "showAll")}
                    />
                    <IdsCheckbox
                        idValue="hide-inactive"
                        label={`Hide inactive ${fullName}`}
                        defaultChecked={hideInactive}
                        changeHandler={(event) => onChange(event, "hideInactive")}
                    />
                </div>
            </div>
            <IdsTextInput
                idValue="filter"
                defaultValue={search}
                placeholder={`Type to filter ${fullName} list by text string`}
                changeHandler={(event) => onChange(event, "search")}
            />
            <div className="flex-row p-2">
                <IdsCheckbox idValue="select-all" label="Select all" defaultChecked={allSelected} changeHandler={onSelectAll} />
            </div>
            <IdsDivider />
            <div className="flex-column gap-3 with-scroll">
                <div className="standardize--pagination-container" style={{ position: "relative" }}>
                    <IdsTablePagination
                        ref={onPaginationRef}
                        customClasses="standardize--pagination"
                        currentPage={pageNumber}
                        totalPages={totalPages}
                        showFirstPageButton
                        showLastPageButton
                        listOfItemsElements={ITEMS_PER_PAGE}
                        itemsPerPageText={!name.endsWith("s") ? `${name}s per page` : `${name} per page`}
                        type="paginator"
                        size="sm"
                        iconOnly
                        pageChangeHandler={(pageNumber) => onPageChange(pageNumber)}
                        listOfItemsChangeHandler={(pageSize) => onChange(pageSize, "pageSize")}
                    />
                    {itemsWidth > 0 && (
                        <IdsText
                            style={{
                                position: "absolute",
                                top: "3px",
                                left: itemsWidth + 16,
                            }}
                        >
                            <>
                                {totalRows && totalRows > 0
                                    ? `${fromNumber} - ${toNumber} of ${totalRows} ${itemsText}`
                                    : `${totalRows} ${itemsText}`}
                            </>
                        </IdsText>
                    )}
                </div>
                <div className="with-scroll">{tableComponent}</div>
            </div>
        </div>
    );
};

const setItemsPerPageWidth = (paginationNode: HTMLIdsTablePaginationElement, setItemsWidth: (itemsWidth: number) => void) => {
    let itemsWidth = 0;
    let isLabelNodeFound = false;
    let isDropdownNodeFound = false;
    let timeoutHandler: NodeJS.Timeout;

    const observer = new MutationObserver((mutations, obs) => {
        for (const mutation of mutations) {
            for (const node of mutation.addedNodes) {
                if (node.nodeType === 1 && (node as HTMLLabelElement).htmlFor === "ids-table-pagination-dropdown-items-per-page") {
                    isLabelNodeFound = true;
                    itemsWidth += (node as HTMLElement).clientWidth;
                }

                if (node.nodeType === 1 && (node as HTMLElement).className === "control is-container is-sm") {
                    isDropdownNodeFound = true;

                    // Client width for some reason is 18 px smaller
                    // than when inspecting element through DevTools
                    // 8px is space between label and dropdown
                    itemsWidth += (node as HTMLElement).clientWidth + 18 + 8;
                }

                if (isLabelNodeFound && isDropdownNodeFound) {
                    setItemsWidth(itemsWidth);

                    clearTimeout(timeoutHandler);
                    obs.disconnect();
                }
            }
        }
    });

    observer.observe(paginationNode, {
        childList: true,
        subtree: true,
    });

    timeoutHandler = setTimeout(() => {
        observer.disconnect();
    }, 2000);
};
