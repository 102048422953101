// Login reducer
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";

// Resources reducer
export const API_GET_AUTHORIZED = "API_GET_AUTHORIZED";
export const API_POST = "API_POST";
export const API_POST_AUTHORIZED = "API_POST_AUTHORIZED";
export const API_PUT = "API_PUT";
export const API_PUT_AUTHORIZED = "API_PUT_AUTHORIZED";
export const API_DELETE = "API_DELETE";
export const API_DELETE_AUTHORIZED = "API_DELETE_AUTHORIZED";

// System reducer
export const SET_SERVER_TIMEZONE_OFFSET = "SET_SERVER_TIMEZONE_OFFSET";

// User reducer
export const USER_SAVE = "USER_SAVE";
export const USER_DELETE = "USER_DELETE";

// Window reducer
export const WINDOW_CONTAINER_ADD = "WINDOW_CONTAINER_ADD";
export const WINDOW_CONTAINER_RESET = "WINDOW_CONTAINER_RESET";
export const WINDOW_ADD = "WINDOW_ADD";
export const WINDOW_UPDATE = "WINDOW_UPDATE";
export const WINDOW_REMOVE = "WINDOW_REMOVE";
export const WINDOW_ACTIVATE = "WINDOW_ACTIVATE";
