import { isNil } from "lodash";

import * as actionTypes from "store/actionTypes";
import { endpoints } from "store/apiSlice";

import { setServerTimezoneOffset } from "store/system/actions";
import { windowActivate } from "store/window/actions";

import { createUser, saveUser } from "utils/user";
import { deleteWindowState, windowContainerTypes } from "utils/window";

async function updateUser(action, userName, errorActionType, dispatch) {
    const { data } = action;
    const { serverTimezoneOffset, responseMessage } = data;

    const user = createUser({
        ...data,
        userName,
    });

    if (!isNil(serverTimezoneOffset)) {
        dispatch(setServerTimezoneOffset({ serverTimezoneOffset }));
    }

    switch (user.userStatus) {
        case "active":
            saveUser(user);

            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            break;

        case "expired":
            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            break;

        default:
            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            dispatch({
                type: errorActionType,
                message: responseMessage,
            });
            break;
    }
}

export const login =
    ({ userName, password, authCode, oneTimeCode = "" }) =>
    async (dispatch) => {
        try {
            const loginResponse = await dispatch(endpoints.login.initiate({ userName, password, authCode, oneTimeCode }));

            dispatch({
                type: actionTypes.LOGIN_RESPONSE,
            });

            // Throw if failure
            if (loginResponse.data.responseStatus === "failure") {
                throw new Error(loginResponse.data.responseMessage);
            }

            // Get user rights
            const rightsResponse = await dispatch(endpoints.getSecurityRights.initiate({ accessToken: loginResponse.data.accessToken }));

            // Throw if error
            if (rightsResponse.error) {
                throw new Error(rightsResponse.error?.message);
            }

            // Add user rights to the response
            const response = {
                data: {
                    ...loginResponse.data,
                    rights: rightsResponse.data,
                },
            };

            updateUser(response, userName, actionTypes.LOGIN_ERROR, dispatch);
        } catch (error) {
            dispatch({
                type: actionTypes.LOGIN_ERROR,
                message: error.message,
            });
        }
    };

export const loginClearQrCode = () => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const user = {
        ...getState().user,
        qrCode: undefined,
    };

    saveUser(user);

    dispatch({
        type: actionTypes.USER_SAVE,
        user,
    });
};

export const logout = () => (dispatch, getState) => {
    const { accessToken, refreshToken } = getState().user;
    dispatch(endpoints.logout.initiate({ accessToken, refreshToken }));
    dispatch(resetApp());
};

export const resetLogin = () => (dispatch) => {
    dispatch({
        type: actionTypes.LOGIN_RESET,
    });

    dispatch(
        windowActivate({
            name: "Login",
            containerName: windowContainerTypes.Login,
        }),
    );
};

export const resetApp = () => (dispatch) => {
    deleteWindowState();

    dispatch({
        type: actionTypes.USER_DELETE,
    });

    dispatch(
        windowActivate({
            name: "Login",
            containerName: windowContainerTypes.Login,
        }),
    );
};
