import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCloneAlgorithmMutation, useGridMeasureAlgorithmsQuery } from "store/apiSlice";

import { IdsButton, IdsButtonGroup, IdsRadioButton, IdsTable, IdsTableCell, IdsTableRow, IdsTag } from "@emergn-infinity/ids-react";

import { IconButton } from "components/IconButton";
import { Tile } from "components/Tile";

import { AlgorithmEdit } from "./AlgorithmEdit";
import { AlgorithmDelete } from "./AlgorithmDelete";
import { AlgorithmMappings } from "./AlgorithmMappings";

import { useMeasureDetails } from "pages/ExploreTrms/utils";

import { AlgorithmsRights, fuelTypeUnits } from "utils/constants";
import {
    arrowProgressRegularIcon,
    circlePlusRegularIcon,
    copyRegularIcon,
    penToSquareRegularIcon,
    subtitlesSlashSolidIcon,
    trashRegularIcon,
    triangleExclamationSolidIcon,
} from "utils/icons";
import { hasRights, isAdminUser } from "utils/user";
import { formatAlgorithmText } from "utils/string";

import { MeasureAlgorithm } from "types";
import { NothingFoundBlock } from "components/NothingFoundBlock";
import { Tooltip } from "components/Tooltip";

const sortAlgorithms = (algorithmList: MeasureAlgorithm[]) => {
    return [...algorithmList].sort((a, b) => {
        // Compare sectors
        if (a.sector.localeCompare(b.sector) === 0) {
            // If same sectors, compare end uses
            if (a.endUse.localeCompare(b.endUse) === 0) {
                // If same vintages, compare fuel convert units
                if (a.vintage.localeCompare(b.vintage) === 0) {
                    return fuelTypeUnits[a.fuelConvertUnit] - fuelTypeUnits[b.fuelConvertUnit];
                } else {
                    // If vintages differ, sort alphabetically by vintage
                    return a.vintage.localeCompare(b.vintage);
                }
            } else {
                // If end uses differ, sort alphabetically by end uses
                return a.endUse.localeCompare(b.endUse);
            }
        } else {
            // If sectors differ, sort alphabetically by sectors
            return a.sector.localeCompare(b.sector);
        }
    });
};

export const AlgorithmsTile: React.FC<{
    selectedMeasure: string;
    selectedAlgorithmNumber?: string;
    onAlgorithmSelect: (algorithm: MeasureAlgorithm) => void;
}> = ({ selectedMeasure, selectedAlgorithmNumber, onAlgorithmSelect }) => {
    const [algorithmForDelete, setAlgorithmForDelete] = useState<MeasureAlgorithm>();
    const [duplicatedAlgorithmNumber, setDuplicatedAlgorithmNumber] = useState<string>();
    const [algorithmForEdit, setAlgorithmForEdit] = useState<MeasureAlgorithm>();
    const [algorithmForMapping, setAlgorithmForMapping] = useState<MeasureAlgorithm>();
    const [isAdding, setIsAdding] = useState(false);

    const duplicatedAlgorithmSelectedRef = useRef(false);

    const measure = useMeasureDetails(selectedMeasure);
    const measureNumber = measure?.measureNumber;

    const {
        data: algorithms,
        isLoading: isLoadingAlgorithms,
        isFetching: isFetchingAlgorithms,
        isError: isAlgorithmsError,
    } = useGridMeasureAlgorithmsQuery({ measureNumber: measureNumber! }, { skip: !measureNumber });
    const tableData = isEmpty(measureNumber) || isLoadingAlgorithms || isAlgorithmsError ? [] : sortAlgorithms(algorithms ?? []);

    const editRights = hasRights(AlgorithmsRights);

    const showEmptyState = isEmpty(tableData) && !isLoadingAlgorithms && !isAlgorithmsError && !isEmpty(measureNumber);

    const getCellStyle = (item: MeasureAlgorithm) => {
        return {
            minWidth: 0,
            backgroundColor:
                item.algorithmNumber === selectedAlgorithmNumber ? "var(--ids-table-cell-body-background-color-clear-hover)" : undefined,
        };
    };

    if (tableData.length > 0 && isEmpty(selectedAlgorithmNumber)) {
        onAlgorithmSelect(tableData[0]);
    }

    // Automatically select duplicated algorithm
    if (!isEmpty(duplicatedAlgorithmNumber) && !isFetchingAlgorithms && !duplicatedAlgorithmSelectedRef.current) {
        const duplicatedAlgorithm = tableData.find((a) => a.algorithmNumber === duplicatedAlgorithmNumber);

        if (duplicatedAlgorithm) {
            duplicatedAlgorithmSelectedRef.current = true;

            onAlgorithmSelect(duplicatedAlgorithm);
        }
    }

    const onAlgorithmDeleted = () => {
        setAlgorithmForDelete(undefined);

        if (selectedAlgorithmNumber === algorithmForDelete?.algorithmNumber) {
            if (tableData.length > 0) {
                onAlgorithmSelect(tableData[0]);
            }
        }
    };

    const [clone, cloneStatus] = useCloneAlgorithmMutation();
    const onAlgorithmDuplicate = async (algorithmNumber: string) => {
        if (cloneStatus.isLoading) {
            return;
        }

        try {
            const response = await clone({ algorithmNumber }).unwrap();

            if (response.responseStatus === "success") {
                duplicatedAlgorithmSelectedRef.current = false;

                setDuplicatedAlgorithmNumber(response.algorithmNumber);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onAlgorithmEdit = (algorithm: MeasureAlgorithm) => {
        setAlgorithmForEdit(algorithm);

        onAlgorithmSelect(algorithm);
    };

    const onStandardizeAlgorithm = (algorithm: MeasureAlgorithm) => {
        setAlgorithmForMapping(algorithm);

        onAlgorithmSelect(algorithm);
    };

    return (
        <Tile
            title="Algorithms"
            action={
                editRights && (
                    <IdsButton padding="sm" variant="secondary" clickHandler={() => setIsAdding(true)}>
                        <div className="flex-row gap-2 align-center">
                            <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                            Add Algorithm
                        </div>
                    </IdsButton>
                )
            }
        >
            <div className="p-3">
                {/* Add Algorithm */}
                {isAdding && measure && <AlgorithmEdit measure={measure} onClose={() => setIsAdding(false)} onAdded={onAlgorithmSelect} />}

                {/* Delete Algorithm */}
                {algorithmForDelete && measure && (
                    <AlgorithmDelete measure={measure} algorithm={algorithmForDelete} onClose={onAlgorithmDeleted} />
                )}

                {/* Edit Algorithm */}
                {algorithmForEdit && measure && (
                    <AlgorithmEdit measure={measure} algorithm={algorithmForEdit} onClose={() => setAlgorithmForEdit(undefined)} />
                )}

                {/* Standardize Algorithm */}
                {algorithmForMapping && measure && (
                    <AlgorithmMappings
                        measure={measure}
                        algorithm={algorithmForMapping}
                        onClose={() => setAlgorithmForMapping(undefined)}
                    />
                )}

                {showEmptyState ? (
                    <div className="py-4 bg-theme-base">
                        <NothingFoundBlock icon={subtitlesSlashSolidIcon} title="No Algorithms" message="Algorithms added will show here" />
                    </div>
                ) : (
                    <IdsTable variant="alternate" spacing="sm">
                        <IdsTableRow rowType="table-heading-row" customClasses="sticky-top" style={{ top: -24 }}>
                            <IdsTableCell cellType="table-heading-cell" style={{ minWidth: 0, width: "1rem" }} />
                            <IdsTableCell cellType="table-heading-cell" heading="Sector" style={{ minWidth: 0 }} />
                            <IdsTableCell cellType="table-heading-cell" heading="End Use" style={{ minWidth: 0 }} />
                            <IdsTableCell cellType="table-heading-cell" heading="Vintage" style={{ minWidth: 0 }} />
                            <IdsTableCell cellType="table-heading-cell" heading="Strategy" style={{ minWidth: 0 }} />
                            <IdsTableCell cellType="table-heading-cell" heading="Algorithm" style={{ minWidth: 0, width: "30%" }} />
                            {editRights && <IdsTableCell cellType="table-heading-cell" style={{ minWidth: 0 }} />}
                        </IdsTableRow>
                        {tableData?.map((item) => (
                            <IdsTableRow key={item.algorithmNumber} rowType="table-body-row">
                                <IdsTableCell style={{ ...getCellStyle(item) }}>
                                    <IdsRadioButton
                                        defaultChecked={item.algorithmNumber === selectedAlgorithmNumber}
                                        clickHandler={() => onAlgorithmSelect(item)}
                                    />
                                </IdsTableCell>
                                <IdsTableCell style={getCellStyle(item)}>
                                    <IdsTag variant="brand-c" size="sm">
                                        <>{item.sector}</>
                                    </IdsTag>
                                </IdsTableCell>
                                <IdsTableCell style={getCellStyle(item)}>
                                    <IdsTag variant="brand-d" size="sm">
                                        <>{item.endUse}</>
                                    </IdsTag>
                                </IdsTableCell>
                                <IdsTableCell style={getCellStyle(item)}>
                                    <IdsTag size="sm">
                                        <>{item.vintage}</>
                                    </IdsTag>
                                </IdsTableCell>
                                <IdsTableCell style={getCellStyle(item)}>{item.strategy}</IdsTableCell>
                                <IdsTableCell style={getCellStyle(item)}>
                                    <span>
                                        {item.offTrmAdjustments && (
                                            <Tooltip message={item.notes}>
                                                <FontAwesomeIcon icon={triangleExclamationSolidIcon} className="text-warning mr-1" />
                                            </Tooltip>
                                        )}
                                        <span dangerouslySetInnerHTML={{ __html: formatAlgorithmText(item.algorithm) }} />
                                    </span>
                                </IdsTableCell>
                                {editRights && (
                                    <IdsTableCell style={{ ...getCellStyle(item), verticalAlign: "middle" }}>
                                        <IdsButtonGroup spaceBetween="sm" position="right" customClasses="flex-no-wrap">
                                            <IconButton
                                                icon={trashRegularIcon}
                                                size="lg"
                                                title="Delete Algorithm"
                                                onClick={() => setAlgorithmForDelete(item)}
                                            />
                                            <IconButton
                                                icon={copyRegularIcon}
                                                size="lg"
                                                title="Duplicate Algorithm"
                                                onClick={() => onAlgorithmDuplicate(item.algorithmNumber)}
                                            />
                                            <IconButton
                                                icon={penToSquareRegularIcon}
                                                size="lg"
                                                title="Edit Algorithm"
                                                onClick={() => onAlgorithmEdit(item)}
                                            />
                                            {isAdminUser() && (
                                                <IconButton
                                                    icon={arrowProgressRegularIcon}
                                                    size="lg"
                                                    title="Standardize Algorithm"
                                                    badgeNumber={item.permutationsCount}
                                                    badgeVariant={item.permutationsCount === 0 ? "critical" : undefined}
                                                    onClick={() => onStandardizeAlgorithm(item)}
                                                />
                                            )}
                                        </IdsButtonGroup>
                                    </IdsTableCell>
                                )}
                            </IdsTableRow>
                        ))}
                    </IdsTable>
                )}
            </div>
        </Tile>
    );
};
