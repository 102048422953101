import {
    IdsFieldWrapper,
    IdsText,
    IdsTextarea,
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsModal,
    IdsDatePicker,
} from "@emergn-infinity/ids-react";
import { useUpdateMeasureMutation } from "store/apiSlice";
import { Measure } from "types";
import { dateInputToJson, jsonDateToInputDate } from "utils/date";
import { EditFormTags } from "components/EditFormTags";
import { useCallback } from "react";

export const MeasureEdit: React.FC<{
    measure: Measure;
    onClose: () => void;
}> = ({ measure, onClose }) => {
    const [updateMeasure, updateMeasureStatus] = useUpdateMeasureMutation();

    const onModalRef = useCallback((modalNode: HTMLDivElement) => {
        if (modalNode) {
            expandTextArea(modalNode);
        }
    }, []);

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (updateMeasureStatus.isLoading) {
            return;
        }

        if (measure) {
            const measureCode = (document.getElementById("measure-code") as HTMLInputElement)?.value;
            const measureDesc = (document.getElementById("measure-desc") as HTMLTextAreaElement)?.value;
            const effectiveDate = dateInputToJson((document.getElementById("effective-date") as HTMLInputElement)?.value);
            const sunsetDate = dateInputToJson((document.getElementById("sunset-date") as HTMLInputElement)?.value);

            const measureUpdateModel = {
                measureCode,
                measureVerified: measure.measureVerified,
                measureDesc,
                effectiveDate,
                sunsetDate,
            };

            try {
                await updateMeasure({
                    measureNumber: measure.measureNumber,
                    measure: measureUpdateModel,
                }).unwrap();
                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Edit Measure Details
                </IdsText>
            </div>
            <div slot="main" ref={onModalRef}>
                <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} />
                <IdsFieldWrapper htmlFor="measure-code" wrapperLabel="Measure Code">
                    <IdsTextInput idValue="measure-code" defaultValue={measure?.measureCode} />
                </IdsFieldWrapper>
                <IdsDatePicker idValue="effective-date" label="Effective Date" selectedDate={jsonDateToInputDate(measure.effectiveDate)} />
                <IdsDatePicker idValue="sunset-date" label="Sunset Date" selectedDate={jsonDateToInputDate(measure.sunsetDate)} />
                <IdsFieldWrapper htmlFor="measure-desc" wrapperLabel="Measure Description">
                    <IdsTextarea idValue="measure-desc" defaultValue={measure?.measureDesc} />
                </IdsFieldWrapper>
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSave}>
                        <>{updateMeasureStatus.isLoading ? "Saving..." : "Save"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

const expandTextArea = (modalNode: HTMLDivElement) => {
    let timeoutHandler: NodeJS.Timeout;

    const observer = new MutationObserver((mutations, obs) => {
        for (const mutation of mutations) {
            for (const node of mutation.addedNodes) {
                if (
                    node.nodeType === 1 &&
                    (node as HTMLElement).classList.contains("attr-injector") &&
                    (node as HTMLElement).querySelector("ids-textarea")
                ) {
                    const modalMain = modalNode.closest(".modal-main");
                    if (!modalMain) {
                        return;
                    }

                    const container = modalMain.children[0];
                    const formWrapper = container?.querySelector("[html-for='measure-desc']");
                    const attrInjector = formWrapper?.querySelector(".attr-injector");

                    container?.classList.add("fill-height");
                    container?.children[0]?.classList.add("flex-column", "fill-height");
                    formWrapper?.classList.add("flex-grow", "pb-4");
                    attrInjector?.classList.add("fill-height");
                    attrInjector?.querySelector("ids-textarea")?.classList.add("fill-height");
                    attrInjector?.querySelector("ids-textarea")?.querySelector("textarea")?.classList.add("fill-height");

                    clearTimeout(timeoutHandler);
                    obs.disconnect();
                }
            }
        }
    });

    observer.observe(modalNode, {
        childList: true,
        subtree: true,
    });

    timeoutHandler = setTimeout(() => {
        observer.disconnect();
    }, 2000);
};
