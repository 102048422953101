import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { IdsText, IdsFieldWrapper, IdsPasswordInput, IdsTextInput, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { ReturnButton } from "./ReturnButton";

import { Wrapper } from "pages/Login/Wrapper";

import { useChangePasswordMutation, useTwoFactorEnabledQuery } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";
import { login } from "store/login/actions";

import { clearBrowserUrl } from "utils/window";

export const ChangePassword: React.FC<{
    accessToken: string;
    userName: string;
}> = ({ accessToken, userName }) => {
    const dispatch = useAppDispatch();

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [authCode, setAuthCode] = useState("");

    const [error, setError] = useState<string | null>(null);

    const { data, isLoading } = useTwoFactorEnabledQuery({ userName });

    const isAuthCodeRequired = data !== undefined && data.twoFactorRequired && data.qrCodeExists;

    const [changePassword, changePasswordStatus] = useChangePasswordMutation();

    const onChange = useCallback((value: string, handler: (value: string) => void) => {
        handler(value);
    }, []);

    const onSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (newPassword.trim() !== repeatPassword.trim()) {
            setError("Passwords do not match");

            return;
        }

        try {
            const response = await changePassword({
                params: {
                    accessToken,
                    oldPassword,
                    newPassword,
                    authCode,
                },
            }).unwrap();

            if (response.responseStatus === "failure") {
                setError(response.responseMessage);

                return;
            }

            clearBrowserUrl();

            dispatch(login({ userName, password: newPassword, authCode }));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper>
            <form className="flex-column align-center gap-5" onSubmit={onSave}>
                <IdsText size="sm" weight="bold" component="h3">
                    Change password
                </IdsText>
                {!isLoading && (
                    <div className="fill-width">
                        <IdsFieldWrapper
                            wrapperLabel="Old password"
                            isRequired
                            isInvalid={error !== null}
                            helperInvalidText={error ?? undefined}
                        >
                            <IdsPasswordInput
                                id="password"
                                placeholder="Enter your old password"
                                defaultValue={oldPassword}
                                changeHandler={(value) => onChange(value, setOldPassword)}
                                rest={{
                                    autoFocus: true,
                                }}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper
                            wrapperLabel="New password"
                            isRequired
                            isInvalid={error !== null}
                            helperInvalidText={error ?? undefined}
                        >
                            <IdsPasswordInput
                                placeholder="Enter your new password"
                                defaultValue={newPassword}
                                changeHandler={(value) => onChange(value, setNewPassword)}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper
                            wrapperLabel="Confirm password"
                            isRequired
                            isInvalid={error !== null}
                            helperInvalidText={error ?? undefined}
                        >
                            <IdsPasswordInput
                                placeholder="Confirm your new password"
                                defaultValue={repeatPassword}
                                changeHandler={(value) => onChange(value, setRepeatPassword)}
                            />
                        </IdsFieldWrapper>
                        {isAuthCodeRequired && (
                            <IdsFieldWrapper wrapperLabel="Authentication Code" isRequired>
                                <IdsTextInput
                                    placeholder="Enter authentication code"
                                    defaultValue={authCode}
                                    changeHandler={(value) => onChange(value, setAuthCode)}
                                />
                            </IdsFieldWrapper>
                        )}
                        <IdsButtonGroup position="justify">
                            <ReturnButton isForForm />
                            <IdsButton
                                type="submit"
                                isDisabled={
                                    isEmpty(oldPassword) ||
                                    isEmpty(newPassword) ||
                                    isEmpty(repeatPassword) ||
                                    (isAuthCodeRequired && isEmpty(authCode)) ||
                                    changePasswordStatus.isLoading
                                }
                            >
                                <>{changePasswordStatus.isLoading ? "Saving..." : "Save New Password"}</>
                            </IdsButton>
                        </IdsButtonGroup>
                    </div>
                )}
            </form>
        </Wrapper>
    );
};
