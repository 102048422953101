import { isEmpty } from "lodash";
import { useCallback, useRef, useState } from "react";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";

import { IdsContainer, IdsRow, IdsCol } from "@emergn-infinity/ids-react";

import { Sidebar } from "./Sidebar";
import { UpgradingTrmBanner } from "./UpgradingTrmBanner";
import { TrmDetailsTile } from "./TrmDetails/TrmDetailsTile";
import { MeasureLivesTile } from "./MeasureLives/MeasureLivesTile";
import { MeasureCostsTile } from "./MeasureCosts/MeasureCostsTile";
import { AlgorithmsTile } from "./Algorithms/AlgorithmsTile";
import { VariablesTile } from "./Variables/VariablesTile";
import { SavingsCalculationTile } from "./SavingsCalculationTile";
import { MeasureDetailsTile } from "./MeasureDetailsTile";

import { ChooseItem } from "components/ChooseItem";

import { useGetTrmDetailsQuery } from "store/apiSlice";

import { bookSolidIcon, fileSlashSolidIcon } from "utils/icons";

import { copyVariablesToClipboard } from "./utils";

import { MeasureAlgorithm, AlgorithmVariable, Variable } from "types";

export const ExploreTrms = () => {
    const dispatch = useAppDispatch();

    const { selectedTrm, selectedMeasure } = useAppSelector((state) => state.exploreTrms);
    const { isUpgrading } = useAppSelector((state) => state.upgradeTrm);

    const [selectedAlgorithm, setSelectedAlgorithm] = useState<MeasureAlgorithm>();
    const [tempAssignedCalculation, setTempAssignedCalculation] = useState<string>(); // this state handles lookup equations
    const [algorithmVariables, setAlgorithmVariables] = useState<Variable[]>([]);

    const selectTrmRef = useRef<HTMLIdsDropdownElement>(null);
    const selectMeasureRef = useRef<HTMLIdsDropdownElement>(null);

    const { data: trmDetails } = useGetTrmDetailsQuery({ trmNumber: selectedTrm }, { skip: isEmpty(selectedTrm) });

    const showTrmDetails = !isEmpty(selectedTrm);
    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);

    const onChooseClick = useCallback((ref: React.RefObject<HTMLIdsDropdownElement>) => {
        setTimeout(() => {
            ref.current?.querySelector("ids-icon")?.click();
        }, 100);
    }, []);

    const onTrmSelect = (trmNumber: string) => {
        dispatch(selectorChange({ selector: "trm", value: trmNumber }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onMeasureSelect = (measureNumber: string) => {
        dispatch(selectorChange({ selector: "measure", value: measureNumber }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onMeasureClear = () => {
        dispatch(selectorChange({ selector: "measure", value: "" }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onAlgorithmSelect = (algorithm: MeasureAlgorithm) => {
        setSelectedAlgorithm(algorithm);
        setTempAssignedCalculation(undefined);
        setAlgorithmVariables([]);
    };

    const onVariableChange = (variable: Variable) => {
        setAlgorithmVariables((prev) => {
            const index = prev.findIndex((v) => v.name === variable.name);
            if (index === -1) {
                return [...prev, variable];
            }

            return prev.map((v, i) => (i === index ? variable : v));
        });
    };

    const onExportVariables = async (variables: AlgorithmVariable[]) => {
        await copyVariablesToClipboard(variables, algorithmVariables);
    };

    return (
        <IdsContainer fullHeight customClasses="p-0">
            <IdsRow noGutters>
                <Sidebar
                    selectedTrm={selectedTrm}
                    selectedMeasure={selectedMeasure}
                    selectTrmRef={selectTrmRef}
                    selectMeasureRef={selectMeasureRef}
                    onTrmSelect={onTrmSelect}
                    onMeasureSelect={onMeasureSelect}
                    onMeasureClear={onMeasureClear}
                />
                <IdsCol xs="9">
                    {showTrmDetails ? (
                        <>
                            {isUpgrading && <UpgradingTrmBanner />}
                            <div key={`${selectedTrm}`} className="flex-column fill-height with-scroll gap-3 p-3">
                                <TrmDetailsTile selectedTrm={selectedTrm} selectedMeasure={selectedMeasure} trmDetails={trmDetails} />
                                {showMainContent ? (
                                    <div key={`${selectedTrm}-${selectedMeasure}`} className="flex-column gap-3">
                                        <MeasureDetailsTile selectedMeasure={selectedMeasure} />
                                        <MeasureLivesTile selectedMeasure={selectedMeasure} />
                                        <MeasureCostsTile selectedMeasure={selectedMeasure} />
                                        <AlgorithmsTile
                                            selectedMeasure={selectedMeasure}
                                            selectedAlgorithmNumber={selectedAlgorithm?.algorithmNumber}
                                            onAlgorithmSelect={onAlgorithmSelect}
                                        />
                                        {/* NOTE: Added React.key here, because sometimes it can cause "Failed to execute 'removeChild' on 'Node'" */}
                                        <div key={`${selectedAlgorithm?.algorithmNumber}`}>
                                            <VariablesTile
                                                selectedMeasure={selectedMeasure}
                                                algorithm={selectedAlgorithm}
                                                algorithmVariables={algorithmVariables}
                                                tempAssignedCalculation={tempAssignedCalculation}
                                                setTempAssignedCalculation={setTempAssignedCalculation}
                                                onVariableChange={onVariableChange}
                                                onExport={onExportVariables}
                                            />
                                        </div>
                                        <SavingsCalculationTile
                                            algorithm={selectedAlgorithm}
                                            tempAssignedCalculation={tempAssignedCalculation}
                                            variables={algorithmVariables}
                                        />
                                    </div>
                                ) : (
                                    <ChooseItem
                                        icon={fileSlashSolidIcon}
                                        title="No Measure Chosen"
                                        message="Please select a measure to view its details."
                                        actionLabel="Choose Measure"
                                        onActionClick={() => onChooseClick(selectMeasureRef)}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <ChooseItem
                            icon={bookSolidIcon}
                            title="No TRM Chosen"
                            message="Please select a TRM to view its details."
                            actionLabel="Choose TRM"
                            onActionClick={() => onChooseClick(selectTrmRef)}
                        />
                    )}
                </IdsCol>
            </IdsRow>
        </IdsContainer>
    );
};
