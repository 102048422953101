import { isEmpty } from "lodash";
import { useContext, useMemo, useState } from "react";
import { IdsCheckbox, IdsDropdown, IdsTableRow, IdsTableCell, IdsTag, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";
import { Tooltip } from "components/Tooltip";

import { BenchmarkTrmContext } from "pages/BenchmarkTrm";
import { getSavingsCalculationsGridStyle } from "pages/BenchmarkTrm/utils";

import { circleCheckSolidIcon, circleXmarkSolidIcon, linkSimpleRegularIcon, penToSquareRegularIcon } from "utils/icons";
import { isAdminUser } from "utils/user";

import { VariableText } from "./VariableText";

import type { IndexSignature } from "types";
import type { SavingsCalculationsMatrix } from "pages/BenchmarkTrm/MainPanel/SavingsCalculations";

import "./style.scss";

export const SavingsCalculationsGridRows: React.FC<{
    rowItems: SavingsCalculationsMatrix;
    assumptionsForStandardizing: SavingsCalculationsMatrix;
    setAssumptionsForStandardizing: (assumptionsForStandardizing: SavingsCalculationsMatrix) => void;
    setAssumptionNumberForMapping: (assumptionNumber: string) => void;
}> = ({ rowItems, assumptionsForStandardizing, setAssumptionsForStandardizing, setAssumptionNumberForMapping }) => {
    const { isSelecting } = useContext(BenchmarkTrmContext);

    // Following structure:
    //
    //  {
    //      "Quantity installed": {
    //          "Arkansas Statewide TRM V9.1 (2022)-Electric Vehicle Charging Systems (EV Chargers)": "5.5",
    //          ...
    //      },
    //      ...
    //  }
    const [additionalDetails, setAdditionalDetails] = useState<IndexSignature<IndexSignature<string>>>({});

    const style = useMemo(() => {
        return getSavingsCalculationsGridStyle(isSelecting);
    }, [isSelecting]);

    const onRowSelect = (key: string) => {
        let newAssumptionsForStandardizing = { ...assumptionsForStandardizing };

        // Select assumption
        if (assumptionsForStandardizing[key] === undefined) {
            newAssumptionsForStandardizing[key] = rowItems[key];
        }
        // Deselect assumption
        else {
            delete newAssumptionsForStandardizing[key];
        }

        setAssumptionsForStandardizing(newAssumptionsForStandardizing);
    };

    /**
     * Changes lookup value by bulk.
     *
     * @param k1 - (key1) assumption's key
     * @param k2 - (key2) algorithm's key (needed for clearing dropdowns)
     * @param lookupValue - lookup value to bulk change
     */
    const onAdditionalDetailsChange = (k1: string, k2: string, lookupValue?: string) => {
        setAdditionalDetails((prev) => {
            const prevState = { ...prev };

            // Clear dropdowns
            if (!lookupValue) {
                const value = prevState[k1][k2]; // get value from user interacted dropdown

                // Loop through row dropdown values
                Object.keys(prevState[k1]).forEach((algorithmKey) => {
                    // If row dropdown value is the same as
                    // value from user interacted dropdown,
                    // clear row dropdown value
                    if (prevState[k1][algorithmKey] === value) {
                        delete prevState[k1][algorithmKey];
                    }
                });

                return prevState;
            }

            // Select values for dropdowns
            //
            // Loop through TRMs & Measures
            Object.keys(rowItems[k1]).forEach((algorithmKey) => {
                const additionalDetailItems = rowItems[k1][algorithmKey].additionalDetails ?? []; // get TRM & Measure additional details array (if there is)

                // If additional details array includes lookup value
                // from user interacted dropdown, select that lookup
                if (additionalDetailItems.find((lookup) => lookup.lookupValue.toString() === lookupValue)) {
                    prevState[k1] = {
                        ...prevState[k1],
                        [algorithmKey]: lookupValue,
                    };
                }
            });

            return prevState;
        });
    };

    return Object.keys(rowItems).map((assumptionKey) => (
        <IdsTableRow key={`assumption-row-${assumptionKey}`} customClasses="savings-calculation-grid-row">
            {isSelecting && (
                <IdsTableCell style={{ ...style.select, verticalAlign: "middle" }}>
                    <IdsCheckbox
                        idValue={assumptionKey}
                        defaultChecked={assumptionsForStandardizing[assumptionKey] !== undefined}
                        isDisabled={rowItems[assumptionKey].isStandardized}
                        changeHandler={() => onRowSelect(assumptionKey)}
                    />
                </IdsTableCell>
            )}
            {isAdminUser() && (
                <IdsTableCell style={{ ...style.standardized, verticalAlign: "middle" }}>
                    <div title={rowItems[assumptionKey].isStandardized ? `Standardized to: ${assumptionKey}` : "Not Standardized"}>
                        <FontAwesomeIcon
                            icon={rowItems[assumptionKey].isStandardized ? circleCheckSolidIcon : circleXmarkSolidIcon}
                            color={`var(${rowItems[assumptionKey].isStandardized ? "--theme-feedback-success" : "--theme-feedback-danger"})`}
                            size="lg"
                            fixedWidth
                        />
                    </div>
                </IdsTableCell>
            )}
            <IdsTableCell style={style.variableDescription}>
                <IdsText size="md">{assumptionKey}</IdsText>
            </IdsTableCell>
            {Object.keys(rowItems[assumptionKey]).map((algorithmKey, index) =>
                // Do not render isStandardized key because it does not have any data
                algorithmKey === "isStandardized" ? null : (
                    <IdsTableCell
                        key={`assumption-data-${index}-${rowItems[assumptionKey][algorithmKey].assumptionNumber}`}
                        style={{ ...getSavingsCalculationsGridStyle(isSelecting, index - 1).relAlgorithm, verticalAlign: "middle" }} // isStandardized index is 0
                    >
                        <div className="flex-column gap-2">
                            <div className="flex-row align-center gap-2" style={{ minHeight: 32 }}>
                                {rowItems[assumptionKey][algorithmKey].formattedVariable && (
                                    <IdsText customClasses="fill-width" size="md">
                                        <VariableText
                                            text={rowItems[assumptionKey][algorithmKey].formattedVariable}
                                            lookupValue={additionalDetails?.[assumptionKey]?.[algorithmKey]}
                                        />
                                    </IdsText>
                                )}
                                <div
                                    className="flex-row align-center justify-end no-shrink gap-2"
                                    style={{ width: "calc(79px + 28px + 0.5rem)" }}
                                >
                                    {rowItems[assumptionKey][algorithmKey].variableSource && (
                                        <Tooltip
                                            message={rowItems[assumptionKey][algorithmKey].variableSource}
                                            placement={index === 1 ? "right-start" : "bottom-start"}
                                            offset={
                                                index === 1 &&
                                                rowItems[assumptionKey].isStandardized &&
                                                !isEmpty(rowItems[assumptionKey][algorithmKey].assumptionNumber)
                                                    ? [0, 44] // to avoid clipping
                                                    : undefined
                                            }
                                        >
                                            <IdsTag size="sm">
                                                <div className="flex-row gap-2 align-center">
                                                    <FontAwesomeIcon icon={linkSimpleRegularIcon} fixedWidth />
                                                    {rowItems[assumptionKey][algorithmKey].variableSourceYear ?? "-"}
                                                </div>
                                            </IdsTag>
                                        </Tooltip>
                                    )}
                                    {rowItems[assumptionKey].isStandardized &&
                                        !isEmpty(rowItems[assumptionKey][algorithmKey].assumptionNumber) && (
                                            <div className="savings-calculation-grid-row--edit-standardizing">
                                                <IconButton
                                                    icon={penToSquareRegularIcon}
                                                    size="lg"
                                                    title="Edit Standardizing"
                                                    onClick={() =>
                                                        setAssumptionNumberForMapping(
                                                            rowItems[assumptionKey][algorithmKey].assumptionNumber,
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div>
                                {!isEmpty(rowItems[assumptionKey][algorithmKey].additionalDetails) && (
                                    <IdsDropdown
                                        size="sm"
                                        idValue="savings-calculations-additional-details"
                                        placeholder=" "
                                        items={rowItems[assumptionKey][algorithmKey].additionalDetails.map((detail) => ({
                                            value: detail.lookupValue.toString(),
                                            label: detail.lookupCriteria,
                                        }))}
                                        initialSelectedItems={
                                            !isEmpty(additionalDetails?.[assumptionKey]?.[algorithmKey])
                                                ? [additionalDetails[assumptionKey][algorithmKey]]
                                                : undefined
                                        }
                                        changeHandler={(lookupValue) => onAdditionalDetailsChange(assumptionKey, algorithmKey, lookupValue)}
                                        clearHandler={() => onAdditionalDetailsChange(assumptionKey, algorithmKey)}
                                        style={{ width: "auto" }}
                                    />
                                )}
                            </div>
                        </div>
                    </IdsTableCell>
                ),
            )}
        </IdsTableRow>
    ));
};
