import { isEmpty } from "lodash";
import { useMemo } from "react";

import { IdsProgressCircular, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetUpgradeTrmVersionStatusesQuery } from "store/apiSlice";
import { useEventsQuery } from "store/signalrSlice";

import { arrowsRotateSolidIcon } from "utils/icons";

export const UpgradingTrmBanner: React.FC = () => {
    const { data: allStatuses } = useGetUpgradeTrmVersionStatusesQuery();
    const { data: currentStatuses } = useEventsQuery("PostStatusAsync");

    const statuses = currentStatuses?.filter((_, index) => index !== 0); // first element in the list is stagingNumber

    const progress = useMemo(() => {
        let progress = 0;

        if (allStatuses && statuses) {
            const stepsCount = allStatuses.reduce((acc, cv) => (acc.statusOrder > cv.statusOrder ? acc : cv)).statusOrder;

            progress = (statuses.length / stepsCount) * 100;
        }

        return progress;
    }, [allStatuses, statuses]);

    return (
        <div className="flex-row align-center gap-3 bg-theme-base-blue p-3" style={{ borderBottom: "1px solid var(--theme-base-border)" }}>
            <FontAwesomeIcon icon={arrowsRotateSolidIcon} size="lg" color="var(--ids-semantic-ink-color-brand-a-accent)" fixedWidth />
            <div className="fill-width">
                <IdsText weight="bold" style={{ color: "var(--ids-text-heading-color)" }}>
                    Update is Under Review
                </IdsText>
                <IdsText size="md" style={{ color: "var(--theme-text-text)" }}>
                    We're currently processing your submission by comparing it with the current version. This may take a few moments.
                </IdsText>
            </div>
            <div className="flex-row align-center gap-2">
                <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)", textWrap: "nowrap" }}>
                    <>{statuses === undefined || isEmpty(statuses) ? "Starting..." : statuses[statuses.length - 1]}</>
                </IdsText>
                <IdsProgressCircular progress={progress} color="brand" size="xs" />
            </div>
        </div>
    );
};
