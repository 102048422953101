import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: MenuState = {};

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setIsOpen: (state, action: PayloadAction<SetIsOpenPayload>) => {
            state[action.payload.id] = {
                isMainMenuOpen: action.payload.isMainMenuOpen,
                isSubMenuOpen: action.payload.isSubMenuOpen,
            };
        },
    },
});

type MenuState = {
    [key: string]: {
        isMainMenuOpen: boolean;
        isSubMenuOpen: boolean;
    };
};

type SetIsOpenPayload = {
    id: string;
    isMainMenuOpen: boolean;
    isSubMenuOpen: boolean;
};

export const { setIsOpen } = menuSlice.actions;

export default menuSlice.reducer;
