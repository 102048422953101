import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { IdsTablePagination, IdsText } from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { useGetAssumptionsByStdVariableQuery } from "store/apiSlice";

import { DEFAULT_ITEMS_PER_PAGE } from "utils/constants";
import { alignSlashSolidIcon } from "utils/icons";

import { AssumptionsTable } from "./AssumptionsTable";

import "./style.scss";

export const AssumptionsMapTable: React.FC<{
    stdVariableNumber: string;
}> = ({ stdVariableNumber }) => {
    const [pageNumber, setPageNumber] = useState(1);

    const { data } = useGetAssumptionsByStdVariableQuery(
        { stdVariableNumber, pageNumber, pageSize: DEFAULT_ITEMS_PER_PAGE },
        { skip: isEmpty(stdVariableNumber) },
    );
    const assumptions = data?.assumptionsByStdVariableList;
    const totalRows = data?.totalRows;
    const totalPages = data?.totalPages;

    const totalItemsText = useMemo(() => {
        let totalItemsText = "";

        const fromNumber = pageNumber * DEFAULT_ITEMS_PER_PAGE - DEFAULT_ITEMS_PER_PAGE + 1;
        let toNumber = pageNumber * DEFAULT_ITEMS_PER_PAGE;

        if (totalRows) {
            if (totalRows < toNumber) {
                toNumber = totalRows;
            }

            totalItemsText = `${fromNumber}-${toNumber} of ${totalRows} items`;
        }

        return totalItemsText;
    }, [pageNumber, totalRows]);

    return (
        <>
            <IdsText weight="bold">Selected Standard Variable also maps to:</IdsText>
            {assumptions === undefined || isEmpty(assumptions) || isEmpty(stdVariableNumber) ? (
                <NothingFoundBlock
                    icon={alignSlashSolidIcon}
                    title={isEmpty(stdVariableNumber) ? "No Standard Variable Chosen" : "No Mapping Found"}
                />
            ) : (
                <>
                    <div className="rounded-edges-round px-2" style={{ border: "1px solid var(--theme-base-border)" }}>
                        <AssumptionsTable standardizingList={assumptions} />
                    </div>
                    <div className="pt-2 assumptions-pagination-container">
                        <IdsText>{totalItemsText}</IdsText>
                        <IdsTablePagination
                            customClasses="input-table-pagination"
                            currentPage={pageNumber}
                            totalItems={DEFAULT_ITEMS_PER_PAGE}
                            totalPages={totalPages}
                            type="input"
                            iconOnly
                            pageChangeHandler={(pageNumber) => setPageNumber(pageNumber)}
                        />
                    </div>
                </>
            )}
        </>
    );
};
