import { isEmpty, isNil } from "lodash";
import React, { useMemo, useState } from "react";

import {
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsTable,
    IdsTableRow,
    IdsTableCell,
    IdsText,
    IdsTablePagination,
} from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";
import { NothingFoundBlock } from "components/NothingFoundBlock";

import { DEFAULT_ITEMS_PER_PAGE, LookupVariants } from "utils/constants";
import {
    checkLightIcon,
    circlePlusRegularIcon,
    penToSquareRegularIcon,
    subtitlesSlashSolidIcon,
    trashRegularIcon,
    xmarkLightIcon,
} from "utils/icons";
import { createPaginationItems } from "utils/pagination";
import { sanitizeAndSetNumericInput } from "utils/string";

import type { LookupState } from ".";

export const LookupsGrid: React.FC<{
    lookups: LookupState[];
    lookupVariant: keyof typeof LookupVariants;
    hasErrors: boolean;
    setLookups: (lookups: LookupState[]) => void;
}> = ({ lookups, lookupVariant, hasErrors, setLookups }) => {
    const [tempLookups, setTempLookups] = useState(lookups);
    const [search, setSearch] = useState("");
    const [editIndex, setEditIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);

    const isEditingLookups = editIndex !== -1;

    // list of refs to input elements
    const inputRefs = useMemo(() => {
        const inputRefs: React.RefObject<HTMLInputElement>[] = [];

        if (lookupVariant === LookupVariants.Equation) {
            return inputRefs;
        }

        for (let index = 0; index < DEFAULT_ITEMS_PER_PAGE; index++) {
            inputRefs.push(React.createRef<HTMLInputElement>());
        }

        return inputRefs;
    }, [lookupVariant]);

    const paginationItem = useMemo(() => {
        const filteredLookups = [...tempLookups].filter((lookup) =>
            lookup.lookupCriteria.toLowerCase().includes(search.toLowerCase().trim()),
        );
        const items = createPaginationItems(filteredLookups, DEFAULT_ITEMS_PER_PAGE);
        const totalRows = filteredLookups.length;
        let fromNumber = currentPage * DEFAULT_ITEMS_PER_PAGE - DEFAULT_ITEMS_PER_PAGE + 1;
        let toNumber = currentPage * DEFAULT_ITEMS_PER_PAGE;

        if (totalRows < toNumber) {
            toNumber = totalRows;
        }

        const totalItemsText = totalRows > 0 ? `${fromNumber}-${toNumber} of ${totalRows} items` : `${totalRows} items`;

        return { lookups: items, totalItemsText };
    }, [tempLookups, search, currentPage]);

    const getCellStyle = (isEditing: boolean) => {
        if (!isEditingLookups || isEditing) {
            return undefined;
        }

        return { backgroundColor: "var(--ids-semantic-background-color-neutral-subtlest-default)" };
    };

    const onSearchChange = (value: string) => {
        setEditIndex(-1);
        setSearch(value);
    };

    const onAddLookup = () => {
        const newTempLookups = [...tempLookups, { lookupCriteria: "", lookupValue: undefined, lookupEquation: undefined }];
        const index = newTempLookups.length - 1;

        const itemPage = Math.ceil(newTempLookups.length / DEFAULT_ITEMS_PER_PAGE);

        if (currentPage !== itemPage) {
            setCurrentPage(itemPage);
        }

        setEditIndex(index);
        setTempLookups(newTempLookups);

        // focus the added line
        setTimeout(() => {
            const criteriaInputs = document.querySelectorAll(".lookup-criteria");
            if (criteriaInputs.length > 0) {
                criteriaInputs[criteriaInputs.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    };

    const onCriteriaChange = (value: string, index: number) => {
        setTempLookups(tempLookups.map((item, i) => (i === index ? { ...item, lookupCriteria: value } : item)));
    };

    const onValueChange = (value: string, lookupIndex: number, refIndex?: number) => {
        let newTempLookups: LookupState[] = [];

        if (lookupVariant === LookupVariants.Value && refIndex !== undefined) {
            newTempLookups = tempLookups.map((item, i) =>
                i === lookupIndex
                    ? { ...item, lookupValue: sanitizeAndSetNumericInput(value, inputRefs[refIndex], { allowNegative: true }) }
                    : item,
            );
        } else if (lookupVariant === LookupVariants.Equation) {
            newTempLookups = tempLookups.map((item, i) => (i === lookupIndex ? { ...item, lookupEquation: value } : item));
        }

        setTempLookups(newTempLookups);
    };

    const onCriteriaValuePaste = (event: React.ClipboardEvent<HTMLIdsTextInputElement>, index: number) => {
        const lookupTable = event.clipboardData.getData("text/plain");

        const newLookups = lookupTable
            .split("\n")
            .map((row) => row.split("\t"))
            .map((rowData) => ({
                lookupCriteria: (rowData[0] ?? "").replace("\r", ""),
                lookupValue: isFinite(Number(rowData[1])) ? Number(rowData[1]) : undefined!,
            }))
            // Remove invalid values
            .filter((item) => !isEmpty(item.lookupCriteria) || !isNil(item.lookupValue));

        if (newLookups.length === 0 || (newLookups.length === 1 && isNil(newLookups[0].lookupValue))) {
            return;
        }

        event.preventDefault();

        const allLookups = [...tempLookups, ...newLookups];
        // Remove the empty item at index
        allLookups.splice(index, 1);

        setTempLookups(allLookups.map((lookup) => ({ ...lookup, lookupValue: String(lookup.lookupValue) })));

        // focus last input field
        setTimeout(() => {
            const inputs = document.querySelectorAll(".lookup-value");
            if (inputs.length > 0) {
                inputs[inputs.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    };

    const onCriteriaEquationPaste = (event: React.ClipboardEvent<HTMLIdsTextInputElement>, index: number) => {
        const lookupTable = event.clipboardData.getData("text/plain");

        const newLookups = lookupTable
            .split("\n")
            .map((row) => row.split("\t"))
            .map((rowData) => ({
                lookupCriteria: (rowData[0] ?? "").replace("\r", ""),
                lookupEquation: rowData[1],
            }))
            // Remove invalid values
            .filter((item) => !isEmpty(item.lookupCriteria) || !isEmpty(item.lookupEquation));

        if (newLookups.length === 0) {
            return;
        }

        event.preventDefault();

        const allLookups = [...tempLookups, ...newLookups];
        // Remove the empty item at index
        allLookups.splice(index, 1);

        setTempLookups(allLookups);

        // focus last input field
        setTimeout(() => {
            const inputs = document.querySelectorAll(".lookup-equation");
            if (inputs.length > 0) {
                inputs[inputs.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    };

    const onCancel = () => {
        // Go to previous page if last item in the current page
        // is about to be removed
        if (paginationItem.lookups?.[currentPage]?.length === 1 && currentPage !== 1) {
            setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
        }

        setEditIndex(-1);
        setTempLookups(lookups);
    };

    const onSave = () => {
        setEditIndex(-1);
        setLookups(tempLookups);
    };

    const onRemove = (index: number) => {
        const newTempLookups = [...tempLookups].filter((_, i) => i !== index);

        // Go to previous page if last item in the current page
        // is about to be removed
        if (paginationItem.lookups?.[currentPage]?.length === 1 && currentPage !== 1) {
            setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
        }

        setTempLookups(newTempLookups);
        setLookups(newTempLookups);
    };

    const onPageChange = (pageNumber: number) => {
        setEditIndex(-1);
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div
                className="flex-row align-center justify-space-between sticky-top bg-white p-2"
                style={{
                    borderColor: "var(--theme-base-border)",
                    borderStyle: "solid",
                    borderWidth: "1px 1px 0 1px",
                    borderRadius: "4px 4px 0 0",
                }}
            >
                <IdsTextInput
                    defaultValue={search}
                    placeholder="Search..."
                    size="sm"
                    iconLeft="ui-search-search"
                    changeHandler={onSearchChange}
                />
                <IdsButton padding="sm" variant="tertiary" isDisabled={isEditingLookups} clickHandler={onAddLookup}>
                    <div className="flex-row gap-2 align-center">
                        <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                        Add Lookup
                    </div>
                </IdsButton>
            </div>
            {isEmpty(tempLookups) ? (
                <div
                    className="py-4 bg-theme-base"
                    style={{
                        border: "1px solid var(--theme-base-border)",
                        borderRadius: "0 0 4px 4px",
                    }}
                >
                    <NothingFoundBlock icon={subtitlesSlashSolidIcon} title="No Lookups" message="Added lookups will show here" />
                </div>
            ) : (
                <>
                    <div
                        className="p-2"
                        style={{
                            border: "1px solid var(--theme-base-border)",
                            borderRadius: "0 0 4px 4px",
                        }}
                    >
                        <IdsTable spacing="sm">
                            <IdsTableRow rowType="table-heading-row">
                                <IdsTableCell cellType="table-heading-cell" heading="" style={{ minWidth: 0, width: "2rem" }} />
                                <IdsTableCell cellType="table-heading-cell" heading="Criteria" style={{ minWidth: 0, width: "auto" }} />
                                {lookupVariant === LookupVariants.Value && (
                                    <IdsTableCell cellType="table-heading-cell" heading="Value" style={{ minWidth: 0, width: "6rem" }} />
                                )}
                                {lookupVariant === LookupVariants.Equation && (
                                    <IdsTableCell cellType="table-heading-cell" heading="Equation" style={{ minWidth: 0 }} />
                                )}
                                <IdsTableCell cellType="table-heading-cell" heading="" style={{ minWidth: 0, width: "0" }} />
                            </IdsTableRow>
                            {paginationItem.lookups?.[currentPage]?.map((item, index) => {
                                const lookupIndex = currentPage * DEFAULT_ITEMS_PER_PAGE - DEFAULT_ITEMS_PER_PAGE + index;
                                const isEditing = editIndex === lookupIndex;

                                return (
                                    <IdsTableRow key={`lookup-${currentPage}-${index}`} rowType="table-body-row">
                                        <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>{lookupIndex + 1}.</IdsTableCell>
                                        <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>
                                            <div>
                                                {isEditing ? (
                                                    <IdsTextInput
                                                        customClasses="lookup-criteria"
                                                        defaultValue={item.lookupCriteria}
                                                        changeHandler={(value) => onCriteriaChange(value, lookupIndex)}
                                                        isInvalid={hasErrors && isEmpty(item.lookupCriteria)}
                                                        onPaste={(event) =>
                                                            lookupVariant === LookupVariants.Value
                                                                ? onCriteriaValuePaste(event, lookupIndex)
                                                                : onCriteriaEquationPaste(event, lookupIndex)
                                                        }
                                                    />
                                                ) : (
                                                    <IdsText>{item.lookupCriteria}</IdsText>
                                                )}
                                            </div>
                                        </IdsTableCell>
                                        <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>
                                            <div>
                                                {lookupVariant === LookupVariants.Value &&
                                                    (isEditing ? (
                                                        <IdsTextInput
                                                            customClasses="lookup-value"
                                                            innerRef={inputRefs[index]}
                                                            defaultValue={item.lookupValue ?? ""}
                                                            changeHandler={(value) => onValueChange(value, lookupIndex, index)}
                                                            isInvalid={hasErrors && !item.lookupValue}
                                                        />
                                                    ) : (
                                                        <IdsText>{item.lookupValue ?? ""}</IdsText>
                                                    ))}
                                                {lookupVariant === LookupVariants.Equation &&
                                                    (isEditing ? (
                                                        <IdsTextInput
                                                            customClasses="lookup-equation"
                                                            defaultValue={item.lookupEquation}
                                                            changeHandler={(value) => onValueChange(value, lookupIndex)}
                                                            isInvalid={hasErrors && !item.lookupEquation}
                                                        />
                                                    ) : (
                                                        <IdsText>{item.lookupEquation}</IdsText>
                                                    ))}
                                            </div>
                                        </IdsTableCell>
                                        <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0, width: 104 }}>
                                            <IdsButtonGroup position="right" spaceBetween="md">
                                                {isEditing ? (
                                                    <>
                                                        <IconButton
                                                            icon={xmarkLightIcon}
                                                            size="lg"
                                                            square
                                                            padding="sm"
                                                            title="Cancel"
                                                            onClick={onCancel}
                                                        />
                                                        <IconButton
                                                            icon={checkLightIcon}
                                                            size="lg"
                                                            square
                                                            padding="sm"
                                                            title="Save"
                                                            onClick={onSave}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            icon={trashRegularIcon}
                                                            size="lg"
                                                            square
                                                            padding="sm"
                                                            title="Remove Criteria"
                                                            isDisabled={isEditingLookups}
                                                            onClick={() => onRemove(lookupIndex)}
                                                        />
                                                        <IconButton
                                                            icon={penToSquareRegularIcon}
                                                            size="lg"
                                                            square
                                                            padding="sm"
                                                            title="Edit Criteria"
                                                            isDisabled={isEditingLookups}
                                                            onClick={() => setEditIndex(lookupIndex)}
                                                        />
                                                    </>
                                                )}
                                            </IdsButtonGroup>
                                        </IdsTableCell>
                                    </IdsTableRow>
                                );
                            })}
                        </IdsTable>
                    </div>
                    <div className="flex-row align-center justify-space-between pt-2">
                        <IdsText>{paginationItem.totalItemsText}</IdsText>
                        <div key={`lookups-pagination-${Object.keys(paginationItem.lookups).length}`}>
                            <IdsTablePagination
                                customClasses="input-table-pagination"
                                currentPage={currentPage}
                                totalItems={DEFAULT_ITEMS_PER_PAGE}
                                totalPages={Object.keys(paginationItem.lookups).length}
                                type="input"
                                iconOnly
                                pageChangeHandler={onPageChange}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
