import { isEmpty } from "lodash";

import { IdsText, IdsFieldWrapper, IdsTextInput, IdsButton } from "@emergn-infinity/ids-react";

import { useAutoFillDetect } from "utils/useAutoFillDetect";

export const UsernameStep: React.FC<{
    userName: string;
    onChange: (name: string, value: string) => void;
    onNext: React.FormEventHandler<HTMLFormElement>;
}> = ({ userName, onChange, onNext }) => {
    const isAutoFilled = useAutoFillDetect(["username"]);

    return (
        <form className="flex-column align-center gap-5" onSubmit={onNext}>
            <IdsText size="sm" weight="bold" component="h3">
                Sign in to your account
            </IdsText>
            <div className="fill-width">
                <IdsFieldWrapper htmlFor="username" wrapperLabel="Username">
                    <IdsTextInput
                        idValue="username"
                        placeholder="Enter your username"
                        defaultValue={userName}
                        changeHandler={(value) => onChange("userName", value)}
                        rest={{
                            autoComplete: "username",
                            autoFocus: true,
                            name: "username",
                        }}
                    />
                </IdsFieldWrapper>
                <IdsButton type="submit" isDisabled={!isAutoFilled && isEmpty(userName)} fullWidth>
                    Next
                </IdsButton>
            </div>
        </form>
    );
};
