import { IdsCard } from "@emergn-infinity/ids-react";

import { Copyright } from "components/Copyright";

export const Wrapper: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    return (
        <div className="flex-row fill-height">
            <div
                className="flex-column align-center justify-center no-shrink gap-4"
                style={{
                    backgroundImage: "url(https://files.insight.visiondsm.com/public/TRMulator-Background.webp)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    minWidth: 464,
                    width: "34%",
                    marginLeft: "-1px", // resolves having white thin line next to the image on the left side
                }}
            >
                <img src="https://files.insight.visiondsm.com/public/Vision-Icon-White.svg" alt="Vision Logo" height={80} width={80} />
                <div
                    className="text-center"
                    style={{
                        fontFamily: "var(--ids-semantic-font-font-family-display)",
                        fontSize: "2rem",
                        color: "var(--ids-semantic-ink-color-neutral-accent-ondark)",
                    }}
                >
                    <div>The industry's first</div>
                    <strong>
                        <div>TRM comparison</div>
                        <div>platform</div>
                    </strong>
                </div>
            </div>
            <div className="flex-column align-center fill-height fill-width with-scroll">
                <div className="flex-column my-auto">
                    {/* Empty div needed for spacing at the top when rendering with scrollbar */}
                    <div style={{ height: 59 }} />
                    <div className="flex-column gap-6">
                        <div className="flex-row align-center justify-center gap-3">
                            <img
                                src="https://files.insight.visiondsm.com/public/Vision-Icon-Black.svg"
                                alt="Vision Logo"
                                height={44}
                                width={44}
                            />
                            <div
                                style={{
                                    fontFamily: "var(--ids-semantic-font-font-family-heading)",
                                    fontWeight: 700,
                                    fontSize: "2rem",
                                    color: "var(--theme-dark-color)",
                                }}
                            >
                                TRMulator
                            </div>
                        </div>
                        <IdsCard
                            className="rounded-edges-rounder px-10 py-8"
                            style={{ border: "1px solid var(--theme-base-border)", boxShadow: "none", width: 514 }}
                        >
                            <div slot="slot1">{children}</div>
                        </IdsCard>
                    </div>
                    {/* Empty div needed for spacing at the bottom when rendering with scrollbar */}
                    <div style={{ height: 59 }} />
                </div>
                <Copyright />
            </div>
        </div>
    );
};
