import { memo, useCallback } from "react";
// @ts-ignore
import { ScrollSyncPane } from "react-scroll-sync";
import ClassNames from "classnames";

import ErrorBoundary from "components/ErrorBoundary";

import { availableViews } from "pages/configureViews";
import { WindowContext } from "components/WindowManager/Windows/WindowsContainer/Window";
import WindowHeader from "components/WindowManager/Windows/WindowsContainer/Window/WindowHeader";
import WindowContent from "components/WindowManager/Windows/WindowsContainer/Window/WindowContent";

import { useViews } from "utils/useViews";
import { EMPTY_SPLIT_VIEW_NAME } from "utils/window";

import { WindowContainer } from "types";

const SplitWindow = memo((props: SplitWindowProps) => {
    const { className, name, containerName, onOpen, onClose, onRemoveSplit, onClearSplit } = props;

    const views = useViews({ containerName });

    const view = views.find((view) => view.name === name)!;

    const {
        showHeader,
        active,
        close,
        clear,
        isSplitViewSync,
        isSplitViewActive,
        leftViewClassName,
        leftViewName,
        leftViewComponent,
        leftViewHeaderTitle,
        leftViewHeaderSubTitle,
        leftViewProps,
        rightViewClassName,
        rightViewName,
        rightViewComponent,
        rightViewHeaderTitle,
        rightViewHeaderSubTitle,
        rightViewProps,
    } = view;

    const leftViewClassNames = ClassNames("flex-column flex-one-in-row with-scroll no-shrink split-window-left", leftViewClassName);
    const rightViewClassNames = ClassNames("flex-column flex-one-in-row with-scroll no-shrink split-window-right", rightViewClassName);

    const leftView = {
        className: leftViewClassName,
        name: leftViewName || "",
        containerName,
        component: leftViewComponent,
        headerTitle: leftViewHeaderTitle,
        headerSubTitle: leftViewHeaderSubTitle,
        props: leftViewProps,
    };

    const rightView = {
        className: rightViewClassName,
        name: rightViewName || "",
        containerName,
        component: rightViewComponent,
        headerTitle: rightViewHeaderTitle,
        headerSubTitle: rightViewHeaderSubTitle,
        isSplitViewActive,
        props: rightViewProps,
    };

    const LeftViewComponent = leftViewComponent ? availableViews[leftViewComponent] : null;
    const RightViewComponent = rightViewComponent ? availableViews[rightViewComponent] : null;

    const onSplitViewClose = useCallback(
        (side) => {
            if (clear) {
                // Clear only one side
                onClearSplit(name, side);
            } else if (close) {
                // Close view
                onClose(name);
            }
        },
        [name, close, clear, onClose, onClearSplit],
    );

    return (
        <div id={active ? "presentation-mode-window" : ""} className={className} hidden={active === false}>
            <WindowContent active={active === true}>
                <>
                    <WindowContext.Provider value={{ name: leftViewName, position: "left", active, isSplitViewActive }}>
                        <div className={leftViewClassNames}>
                            <WindowHeader
                                view={leftView}
                                completeName={name}
                                isSplitViewEnabled={view.isSplitViewEnabled}
                                isSplitViewActive
                            />
                            <div className="flex-one-in-column with-scroll">
                                <div className="flex-column flex-one fill-height">
                                    <ScrollSyncPane>
                                        <ErrorBoundary>
                                            {LeftViewComponent && (
                                                <div className="flex-column flex-one-in-column with-scroll">
                                                    <div className="flex-column flex-one">
                                                        <LeftViewComponent
                                                            {...leftViewProps}
                                                            viewIndex={0}
                                                            isSplitViewSync={isSplitViewSync}
                                                            onOpen={onOpen}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </ErrorBoundary>
                                    </ScrollSyncPane>
                                </div>
                            </div>
                        </div>
                    </WindowContext.Provider>
                    <WindowContext.Provider value={{ name: rightViewName, position: "right", active, isSplitViewActive }}>
                        <div className={rightViewClassNames}>
                            {showHeader && (
                                <WindowHeader
                                    view={rightView}
                                    completeName={name}
                                    close={view.close}
                                    isSplitViewEnabled={view.isSplitViewEnabled}
                                    isSplitViewActive
                                    onClose={rightViewComponent !== EMPTY_SPLIT_VIEW_NAME ? () => onSplitViewClose("right") : undefined}
                                    onRemoveSplit={() => onRemoveSplit(name)}
                                />
                            )}
                            <div className="flex-one-in-column with-scroll">
                                <div className="flex-column flex-one fill-height">
                                    <ScrollSyncPane>
                                        <ErrorBoundary>
                                            {RightViewComponent && (
                                                <div className="flex-column flex-one-in-column with-scroll">
                                                    <div className="flex-column flex-one">
                                                        <RightViewComponent
                                                            {...rightViewProps}
                                                            viewIndex={1}
                                                            isSplitViewSync={isSplitViewSync}
                                                            onOpen={onOpen}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </ErrorBoundary>
                                    </ScrollSyncPane>
                                </div>
                            </div>
                        </div>
                    </WindowContext.Provider>
                </>
            </WindowContent>
        </div>
    );
});

interface SplitWindowProps {
    className?: string;

    /**
     * Name of the View.
     */
    name: string;

    /**
     * View container type.
     */
    containerName: WindowContainer;

    /**
     * Function that opens a new View.
     */
    onOpen: (name: string) => void;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit: (name: string) => void;

    /**
     * Function that clears the chosen side
     * of the Split View to blank View.
     */
    onClearSplit: (name: string, side: "left" | "right") => void;
}

export default SplitWindow;
