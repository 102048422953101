import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IdsText, IdsButton } from "@emergn-infinity/ids-react";

export const NothingFoundBlock: React.FC<{
    title: string;
    icon?: IconProp;
    message?: string;
    actionLabel?: string;
    actionIcon?: IconProp;
    onActionClick?: () => void;
}> = ({ title, icon, message, actionLabel, actionIcon, onActionClick }) => {
    return (
        <div className="flex-column align-center justify-center fill-height rounded-edges-rounder gap-3 p-2 bg-theme-base">
            <div className="flex-column align-center gap-1">
                {icon && (
                    <div>
                        <FontAwesomeIcon icon={icon} size="2xl" color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                    </div>
                )}
                <div className="flex-column align-center gap-1">
                    <IdsText weight="bold" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                        <>{title}</>
                    </IdsText>
                    {message && (
                        <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                            <>{message}</>
                        </IdsText>
                    )}
                </div>
            </div>
            {actionLabel && onActionClick && (
                <div>
                    <IdsButton variant="tertiary" padding="sm" clickHandler={onActionClick}>
                        <div className="flex-row align-center gap-2">
                            {actionIcon && <FontAwesomeIcon icon={actionIcon} size="lg" />}
                            {actionLabel}
                        </div>
                    </IdsButton>
                </div>
            )}
        </div>
    );
};
