import { isEmpty } from "lodash";

import { isAdminUser } from "utils/user";

import type { RelevantAlgorithm } from "types";

export const sortRelAlgorithms = (
    relAlgorithms: RelevantAlgorithm[],
    trmNumber: string,
    trmFamiliarName: string,
    measureNumber: string,
    measure: string,
    algorithmNumber?: string,
) => {
    const sortedRelAlgorithms = [...relAlgorithms].sort((a, b) => {
        // Compare trmFamiliarName
        if (a.trmFamiliarName.localeCompare(b.trmFamiliarName) === 0) {
            // If same trmFamiliarName, compare measure
            if (a.measure.localeCompare(b.measure) === 0) {
                // If same measure, compare sector
                if (a.sector.localeCompare(b.sector) === 0) {
                    // If same sector, sort alphabetically by vintage
                    return a.vintage.localeCompare(b.vintage);
                } else {
                    // If sector differs, sort alphabetically by sector
                    return a.sector.localeCompare(b.sector);
                }
            } else {
                // If mesaure differs, sort alphabetically by measure
                return a.measure.localeCompare(b.measure);
            }
        }

        // If trmFamiliarName differs, sort alphabetically by trmFamiliarName
        return a.trmFamiliarName.localeCompare(b.trmFamiliarName);
    });

    if (!isEmpty(relAlgorithms)) {
        // Add selected TRM at the start of the list
        sortedRelAlgorithms.unshift({
            algorithmNumber: algorithmNumber ?? "",
            trmNumber,
            trmFamiliarName,
            algorithmDescr: "",
            isSameClimateZone: true,
            sector: "",
            measure,
            vintage: "",
            endUse: "",
            fuelType: "",
            measureNumber,
            sectorNumber: "",
            vintageNumber: "",
            fuelTypeNumber: "",
        });
    }

    return sortedRelAlgorithms;
};

export const getSavingsCalculationsGridStyle = (isSelecting?: boolean, index?: number) => {
    let variableDescriptionLeft: string | number = 0;
    let relAlgorithmLeft: string | number = "11.25rem"; // variable description width

    if (isAdminUser() && isSelecting === true) {
        variableDescriptionLeft = "calc(44px + 44px)"; // select width + standardized width
        relAlgorithmLeft = "calc(44px + 44px + 11.25rem)"; // select width + standardized width + variable description width
    } else if (isAdminUser() && isSelecting === false) {
        variableDescriptionLeft = 44; // standardized width
        relAlgorithmLeft = "calc(44px + 11.25rem)"; // standardized width + variable description width
    }

    return {
        select: {
            zIndex: isSelecting !== undefined ? 2 : undefined,
            position: isSelecting !== undefined ? "sticky" : undefined,
            left: 0,
            minWidth: 44, // using width here will cause inconsistencies between devices
        } as React.CSSProperties,
        standardized: {
            zIndex: isSelecting !== undefined ? 2 : undefined,
            position: isSelecting !== undefined ? "sticky" : undefined,
            left: isSelecting ? 44 : 0,
            minWidth: 44, // using width here will cause inconsistencies between devices
        } as React.CSSProperties,
        variableDescription: {
            zIndex: isSelecting !== undefined ? 2 : undefined,
            position: isSelecting !== undefined ? "sticky" : undefined,
            left: variableDescriptionLeft,
            width: "11.25rem",
        } as React.CSSProperties,
        relAlgorithm: {
            zIndex: index === 0 ? 2 : undefined,
            position: index === 0 ? "sticky" : undefined,
            left: relAlgorithmLeft,
            minWidth: "18rem",
        } as React.CSSProperties,
    };
};
