import { IdsModal, IdsText, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { EditFormTags } from "components/EditFormTags";

const DELETE_MODAL_STYLE = {
    width: "452px",
    maxWidth: "100%",
};

export const TrmDetailsDelete: React.FC<{
    headerText: string;
    trmFamiliarName: string;
    confirmationMessage: string;
    isLoading?: boolean;
    children: React.ReactNode;
    onClose: () => void;
    onDelete: () => void;
}> = ({ headerText, trmFamiliarName, confirmationMessage, isLoading, children, onClose, onDelete }) => {
    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout no-overlay">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {headerText}
                </IdsText>
            </div>
            <div slot="main" style={DELETE_MODAL_STYLE}>
                <EditFormTags trmName={trmFamiliarName} />
                <IdsText customClasses="pb-4">{confirmationMessage}</IdsText>
                {children}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onDelete} color="system-critical">
                        <>{isLoading ? "Deleting..." : "Delete"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};
