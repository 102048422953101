export const loadStateFromStorage = (key: string, fallbackState: any) => {
    try {
        const serializedState = sessionStorage.getItem(key);
        if (serializedState) {
            return JSON.parse(serializedState);
        }
    } catch (error) {
        console.error("Could not load state", error);
    }

    return fallbackState;
};
