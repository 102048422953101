import { useState } from "react";

import { IdsModal, IdsText, IdsLabel, IdsTextInput, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { IndexSignature } from "types";

export const AddStandardModal: React.FC<{
    title: string;
    modalFields: ModalField[];
    isLoading?: boolean;
    isSuccess?: boolean;
    onSave?: (params: any) => Promise<void>;
    onClose: () => void;
}> = ({ title, modalFields, isLoading, isSuccess, onSave, onClose }) => {
    const [values, setValues] = useState<IndexSignature<string>>(() => {
        const values = {};

        modalFields.forEach((field) => {
            values[field.idValue] = field.defaultValue;
        });

        return values;
    });

    const onChange = (e: string, key: string) => {
        const newValues = { ...values };

        newValues[key] = e;

        setValues(newValues);
    };

    const onSaveClick = () => {
        onSave?.(values);

        if (isSuccess) {
            onClose();
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div className="flex-column gap-4" slot="main">
                {modalFields.map((field) => (
                    <div key={field.idValue} className="flex-column gap-1">
                        {field.label && <IdsLabel htmlFor={field.idValue}>{field.label}</IdsLabel>}
                        <IdsTextInput
                            idValue={field.idValue}
                            defaultValue={values[field.idValue]}
                            placeholder={field.placeholder}
                            changeHandler={(event) => onChange(event, field.idValue)}
                        />
                    </div>
                ))}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSaveClick}>
                        <>{isLoading ? "Saving..." : "Save"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

export type ModalField = {
    idValue: string;
    defaultValue: string;
    placeholder: string;
    label?: string;
};
