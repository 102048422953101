import { isEmpty } from "lodash";
import { useState } from "react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { sortRelAlgorithms } from "pages/BenchmarkTrm/utils";

import { VariableMapping } from "sidebars/VariableMapping";

import { useGetAlgorithmsQuery } from "store/apiSlice";
import { useAppSelector } from "store/hooks";

import { filterSlashSolidIcon } from "utils/icons";

import { StandardizeVariables } from "./StandardizeVariables";
import { GridFilter } from "./GridFilter";
import { SavingsCalculationsGrid } from "./SavingsCalculationsGrid";

import type { SavingsBenchmark } from "types";

export const SavingsCalculations = () => {
    const {
        selectedTrm,
        selectedTrmName,
        selectedMeasure,
        selectedMeasureName,
        selectedSector,
        selectedSectorName,
        selectedVintage,
        selectedVintageName,
        selectedFuelType,
        selectedFuelTypeName,
        selectedRelAlgorithms,
    } = useAppSelector((state) => state.benchmarkTrm);

    const [isStandardizing, setIsStandardizing] = useState(false);
    const [assumptionNumberForMapping, setAssumptionNumberForMapping] = useState<string>();
    const [assumptionsForStandardizing, setAssumptionsForStandardizing] = useState<SavingsCalculationsMatrix>({});

    const { data } = useGetAlgorithmsQuery(
        {
            measureNumber: selectedMeasure,
            sectorNumber: selectedSector,
            vintageNumber: selectedVintage,
        },
        { skip: isEmpty(selectedMeasure) || isEmpty(selectedSector) || isEmpty(selectedVintage) },
    );
    const selectedAlgorithm = data?.find((algorithm) => algorithm.fuelTypeNumber === selectedFuelType)?.algorithmNumber ?? "";

    const sortedRelAlgorithms = sortRelAlgorithms(
        selectedRelAlgorithms,
        selectedTrm,
        selectedTrmName,
        selectedMeasure,
        selectedMeasureName,
        selectedAlgorithm,
    );

    const areFiltersEmpty = isEmpty(selectedSector) || isEmpty(selectedVintage) || isEmpty(selectedFuelType);

    return (
        <div className="flex-column fill-height">
            {isStandardizing && (
                <StandardizeVariables assumptionsForStandardizing={assumptionsForStandardizing} onClose={() => setIsStandardizing(false)} />
            )}
            {assumptionNumberForMapping && (
                <VariableMapping
                    assumptionNumber={assumptionNumberForMapping}
                    trmFamiliarName={selectedTrmName}
                    measureName={selectedMeasureName}
                    sector={selectedSectorName}
                    vintage={selectedVintageName}
                    fuelType={selectedFuelTypeName}
                    onClose={() => setAssumptionNumberForMapping(undefined)}
                />
            )}
            <GridFilter
                selectedMeasure={selectedMeasure}
                selectedSector={selectedSector}
                selectedVintage={selectedVintage}
                selectedFuelType={selectedFuelType}
                isSavingsCalculationsBenchmark
                assumptionsForStandardizing={assumptionsForStandardizing}
                setIsStandardizing={setIsStandardizing}
                setAssumptionsForStandardizing={setAssumptionsForStandardizing}
            />
            <div className="flex-one-in-column with-scroll">
                {areFiltersEmpty ? (
                    <div className="flex-column fill-height justify-center gap-2">
                        <NothingFoundBlock
                            icon={filterSlashSolidIcon}
                            title="No Variables Found"
                            message="Apply filters to discover savings calculations"
                        />
                    </div>
                ) : (
                    <SavingsCalculationsGrid
                        selectedTrmName={selectedTrmName}
                        selectedMeasureName={selectedMeasureName}
                        items={sortedRelAlgorithms}
                        assumptionsForStandardizing={assumptionsForStandardizing}
                        setAssumptionsForStandardizing={setAssumptionsForStandardizing}
                        setAssumptionNumberForMapping={setAssumptionNumberForMapping}
                    />
                )}
            </div>
        </div>
    );
};

export type SavingsCalculationsMatrix = {
    [key: string]: {
        [key: string]: SavingsBenchmark;
    } & { isStandardized: boolean };
};
