import { NAME_KEY, EXPIRES_IN_KEY, USER_STORAGE_KEY } from "./defines";

import { Rights, StandardizeRights } from "utils/constants";
import { triggerSessionStorageSync } from "utils/store";

import { User } from "types";

export const isAuthenticated = (user: User | null) => {
    return Boolean(user && user.userStatus === "active" && !user.qrCode);
};

export const isStatusExpired = (user: User | null) => {
    return Boolean(user && user.userStatus === "expired");
};

export const isQrCode = (user: User | null) => {
    return Boolean(user && user.userStatus === "active" && user.qrCode);
};

const getUserNameFromToken = (accessToken: string) => {
    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    const userData = JSON.parse(decodedData);

    return userData[NAME_KEY] || "";
};

export const tokenExpired = (user: User) => {
    const currentTime = Date.now();

    return isAuthenticated(user) ? user.tokenExpires && user.tokenExpires < currentTime : true;
};

export const createUser = (params: User) => {
    const { firstName, lastName, userName, userStatus, rights, accessToken, refreshToken, qrCode, settings } = params;

    const name = !userName && accessToken ? getUserNameFromToken(accessToken) : userName;

    if (userStatus !== "active") {
        return {
            userName: name,
            userStatus,
            accessToken,
            qrCode,
            settings,
        };
    }

    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    const userData = JSON.parse(decodedData);

    return {
        firstName,
        lastName,
        userName: name,
        userNumber: userData.sub,
        userStatus,
        isActive: userStatus === "active",
        rights,
        accessToken,
        refreshToken,
        tokenExpires: Date.now() + userData[EXPIRES_IN_KEY] * 60000,
        tokenExpiresIn: userData[EXPIRES_IN_KEY],
        qrCode,
        settings,
    };
};

export const getUser = () => {
    const user = sessionStorage.getItem(USER_STORAGE_KEY);
    let parsedUser: User | null = null;

    if (user !== null) {
        parsedUser = JSON.parse(user);
    }

    return parsedUser;
};

export const saveUser = (user: User) => {
    sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    triggerSessionStorageSync(USER_STORAGE_KEY);
};

export const deleteUser = () => {
    sessionStorage.removeItem(USER_STORAGE_KEY);
    triggerSessionStorageSync(USER_STORAGE_KEY);
};

export const getUserInitials = (firstName?: string, lastName?: string) => {
    let initials = "U U";
    let name = `${firstName} ${lastName}`;

    if (firstName) {
        if (lastName) {
            initials = `${firstName[0]} ${lastName[0]}`;
        } else {
            initials = firstName.trim();

            const index = initials.indexOf(" ");

            if (index) {
                initials = `${firstName[0]} ${firstName[index + 1]}`;
            } else {
                initials = `${firstName[0]} ${firstName[1]}`;
            }
        }
    } else {
        if (lastName) {
            initials = lastName.trim();

            const index = initials.indexOf(" ");

            if (index) {
                initials = `${lastName[0]} ${lastName[index + 1]}`;
            } else {
                initials = `${lastName[0]} ${lastName[1]}`;
            }
        }
    }

    return { initials, name };
};

export const hasRights = (code: (typeof Rights)[number]) => {
    const user = getUser();

    return Boolean(user && isAuthenticated(user) && user.rights?.find((r) => r.clearanceCode === code));
};

export const hasAllRights = (rights: (typeof Rights)[number][]) => {
    const user = getUser();

    return Boolean(user && isAuthenticated(user) && rights.every((r) => user.rights?.find((ur) => ur.clearanceCode === r)));
};

export const isAdminUser = () => {
    return hasAllRights(StandardizeRights);
};
