import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { IdsText, IdsFieldWrapper, IdsTextInput, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { ReturnButton } from "./ReturnButton";

import { Captcha } from "components/Captcha";

import { Wrapper } from "pages/Login/Wrapper";

import { useForgotPasswordMutation } from "store/apiSlice";

export const ForgotPassword: React.FC<{
    userName: string;
}> = (props) => {
    const [userName, setUserName] = useState(props.userName ?? "");
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const [error, setError] = useState<string | null>(null);

    const [forgotPassword, forgotPasswordStatus] = useForgotPasswordMutation();

    const onChange = useCallback((name: string, value: string | null) => {
        if (name === "userName" && typeof value === "string") {
            setUserName(value);
        } else if (name === "recaptcha") {
            setRecaptcha(value);
        }
    }, []);

    const onSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!recaptcha) {
            return;
        }

        try {
            const response = await forgotPassword({
                params: {
                    userName,
                    recaptcha,
                },
            }).unwrap();

            if (response.responseStatus === "failure") {
                setError(response.responseMessage);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper>
            {forgotPasswordStatus.isSuccess ? (
                <div className="flex-column align-center gap-5">
                    <IdsText size="sm" weight="bold" component="h3">
                        Check in your e-mail!
                    </IdsText>
                    <IdsText>We just e-mailed you with the instructions to reset your password.</IdsText>
                    <ReturnButton />
                </div>
            ) : (
                <form className="flex-column align-center gap-5" onSubmit={onSave}>
                    <IdsText size="sm" weight="bold" component="h3">
                        Forgot your password?
                    </IdsText>
                    <IdsText>No problem. Just enter your username below and we'll send you an e-mail with instructions.</IdsText>
                    <div className="fill-width">
                        <IdsFieldWrapper
                            htmlFor="username"
                            wrapperLabel="Username"
                            isRequired
                            isInvalid={error !== null}
                            helperInvalidText={error ?? undefined}
                        >
                            <IdsTextInput
                                idValue="username"
                                placeholder="Enter your username"
                                defaultValue={userName}
                                changeHandler={(value) => onChange("userName", value)}
                                rest={{
                                    autoFocus: true,
                                    name: "username",
                                }}
                            />
                        </IdsFieldWrapper>
                        <div className="flex-column align-center pb-4">
                            <Captcha onChange={(token) => onChange("recaptcha", token)} />
                        </div>
                        <IdsButtonGroup position="justify">
                            <ReturnButton isForForm />
                            <IdsButton type="submit" isDisabled={isEmpty(userName) || recaptcha === null || forgotPasswordStatus.isLoading}>
                                <>{forgotPasswordStatus.isLoading ? "Requesting..." : "Request Password"}</>
                            </IdsButton>
                        </IdsButtonGroup>
                    </div>
                </form>
            )}
        </Wrapper>
    );
};
