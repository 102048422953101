import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import {
    useGetAlgorithmsForStandardizingQuery,
    useGetFuelTypesQuery,
    useGetStdSectorsQuery,
    useGetStdEndUsesQuery,
    useGetStdMeasuresQuery,
    useGetStdVintagesQuery,
    useUpdateStandardizingForAlgorithmMutation,
} from "store/apiSlice";

import { Standardize } from "pages/StandardizeData/MainPanel/common/Standardize";
import { SuggestedStandard } from "pages/StandardizeData/MainPanel/common/SuggestedStandard";

import { AlgorithmsTable } from "./AlgorithmsTable";
import { StandardPermutations } from "./StandardPermutations";
import { MeasureDescription } from "./MeasureDescription";

import { AlgorithmStandardizingListItem } from "types";

export const Algorithms: React.FC<{
    id: string;
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    onChange: (e: any, name: string) => void;
}> = ({ id, name, fullName, showAll, hideInactive, search, pageSize, pageNumber, totalPages, onChange }) => {
    const [activeAlgorithm, setActiveAlgorithm] = useState<AlgorithmStandardizingListItem | null>(null);
    const [selectedAlgorithms, setSelectedAlgorithms] = useState<AlgorithmStandardizingListItem[]>([]);

    const [stdPermutationValues, setStdPermutationValues] = useState<StandardPermutationValues>({
        fuelTypeNumber: "",
        stdSectorNumber: "",
        stdEndUseNumber: "",
        stdMeasureNumber: "",
        stdVintageNumber: "",
    });

    const fetchStdPermutations = activeAlgorithm !== null || !isEmpty(selectedAlgorithms); // only fetch standard permutations when an algorithm is selected
    const allStdPermutationsSelected = Object.keys(stdPermutationValues).every((key) => stdPermutationValues[key] !== "");
    const standardizeDisabled = isEmpty(selectedAlgorithms) || !allStdPermutationsSelected;

    const { data: algorithmsForStandardizing } = useGetAlgorithmsForStandardizingQuery({
        includeSuggested: showAll,
        hideInactive,
        pageSize,
        pageNumber,
        totalPages,
        measureNameSearch: search,
    });

    const { data: fuelTypes, isLoading: isLoadingFuelTypes } = useGetFuelTypesQuery(undefined, { skip: !fetchStdPermutations });
    const { data: stdSectors, isLoading: isLoadingStdSectors } = useGetStdSectorsQuery(undefined, { skip: !fetchStdPermutations });
    const { data: stdEndUses, isLoading: isLoadingStdEndUses } = useGetStdEndUsesQuery({}, { skip: !fetchStdPermutations });
    const { data: stdMeasures, isLoading: isLoadingStdMeasures } = useGetStdMeasuresQuery(undefined, { skip: !fetchStdPermutations });
    const { data: stdVintages, isLoading: isLoadingStdVintages } = useGetStdVintagesQuery(undefined, { skip: !fetchStdPermutations });

    const [updateAlgorithmStandardizing, updateAlgorithmStandardizingStatus] = useUpdateStandardizingForAlgorithmMutation();

    const allSelected =
        selectedAlgorithms.length === algorithmsForStandardizing?.algorithmStandardizingList.length &&
        !isEmpty(algorithmsForStandardizing?.algorithmStandardizingList);

    const isLoadingStdPermutations =
        isLoadingFuelTypes || isLoadingStdSectors || isLoadingStdEndUses || isLoadingStdMeasures || isLoadingStdVintages;

    const fuelTypeList = useMemo(() => {
        return (fuelTypes ?? []).map((ft) => ({
            label: ft.fuelType,
            value: ft.fuelTypeNumber,
        }));
    }, [fuelTypes]);

    const stdSectorList = useMemo(() => {
        return (stdSectors ?? []).map((sector) => ({
            label: sector.stdSectorName,
            value: sector.stdSectorNumber,
        }));
    }, [stdSectors]);

    const stdEndUseList = useMemo(() => {
        return (stdEndUses ?? []).map((endUse) => ({
            label: endUse.stdEndUseName,
            value: endUse.stdEndUseNumber,
        }));
    }, [stdEndUses]);

    const stdMeasureList = useMemo(() => {
        return (stdMeasures ?? []).map((measure) => ({
            label: measure.stdMeasureName,
            value: measure.stdMeasureNumber,
        }));
    }, [stdMeasures]);

    const stdVintageList = useMemo(() => {
        return (stdVintages ?? []).map((vintage) => ({
            label: vintage.stdVintageName,
            value: vintage.stdVintageNumber,
        }));
    }, [stdVintages]);

    const onSelectAll = () => {
        let newSelectedAlgorithms: AlgorithmStandardizingListItem[] = [];

        // Select all algorithms
        if (algorithmsForStandardizing !== undefined && !allSelected) {
            newSelectedAlgorithms = algorithmsForStandardizing.algorithmStandardizingList.map((algorithm) => algorithm);
        }

        setSelectedAlgorithms(newSelectedAlgorithms);
    };

    const onPageChange = (pageNumber: number) => {
        setSelectedAlgorithms([]);

        onChange(pageNumber, "pageNumber");
    };

    const onAlgorithmClick = (algorithm: AlgorithmStandardizingListItem) => {
        setActiveAlgorithm(algorithm);

        const newStdPermutationValues = { ...stdPermutationValues };

        newStdPermutationValues.fuelTypeNumber = algorithm.fuelTypeNumber ?? "";
        newStdPermutationValues.stdSectorNumber = algorithm.stdSectorNumber ?? "";
        newStdPermutationValues.stdEndUseNumber = algorithm.stdEndUseNumber ?? "";
        newStdPermutationValues.stdMeasureNumber = algorithm.stdMeasureNumber ?? "";
        newStdPermutationValues.stdVintageNumber = algorithm.stdVintageNumber ?? "";

        setStdPermutationValues(newStdPermutationValues);
    };

    const onAlgorithmChecked = (algorithm: AlgorithmStandardizingListItem) => {
        let newSelectedAlgorithms: AlgorithmStandardizingListItem[] = [];

        // Uncheck algorithm
        if (selectedAlgorithms.find((a) => a.algorithmNumber === algorithm.algorithmNumber)) {
            newSelectedAlgorithms = selectedAlgorithms.filter((a) => a.algorithmNumber !== algorithm.algorithmNumber);
        }
        // Check algorithm
        else {
            newSelectedAlgorithms = [...selectedAlgorithms];

            newSelectedAlgorithms.push(algorithm);
        }

        setSelectedAlgorithms(newSelectedAlgorithms);
    };

    const onSelectStandardPermutation = (value: string, name: string) => {
        const newStdPermutationValues = { ...stdPermutationValues };

        newStdPermutationValues[name] = value;

        setStdPermutationValues(newStdPermutationValues);
    };

    const onStandardizeClick = async () => {
        // Do nothing if saving is in progress
        if (updateAlgorithmStandardizingStatus.isLoading) {
            return;
        }

        // To update standardizing for algorithm,
        // an algorithm has to be selected
        // and all permutations have to be selected
        if (!isEmpty(selectedAlgorithms) && allStdPermutationsSelected) {
            const dateActivated = new Date(Date.now()).toISOString();

            const algorithms = selectedAlgorithms.map((algorithm) => ({
                algorithmNumber: algorithm.algorithmNumber,
                fuelTypeNumber: stdPermutationValues.fuelTypeNumber,
                stdSectorNumber: stdPermutationValues.stdSectorNumber,
                stdEndUseNumber: stdPermutationValues.stdEndUseNumber,
                stdVintageNumber: stdPermutationValues.stdVintageNumber,
                stdMeasureNumber: stdPermutationValues.stdMeasureNumber,
                dateActivated,
            }));

            try {
                await updateAlgorithmStandardizing({ algorithms });
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="flex-row fill-height">
            <div className="w-70 flex-grow pr-4">
                {algorithmsForStandardizing !== undefined && (
                    <Standardize
                        name={name}
                        fullName={fullName}
                        showAll={showAll}
                        hideInactive={hideInactive}
                        search={search}
                        allSelected={allSelected}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        tableComponent={
                            <AlgorithmsTable
                                activeAlgorithm={activeAlgorithm}
                                selectedAlgorithms={selectedAlgorithms}
                                standardizingList={algorithmsForStandardizing?.algorithmStandardizingList}
                                onClick={onAlgorithmClick}
                                onChange={onAlgorithmChecked}
                            />
                        }
                        totalRows={algorithmsForStandardizing?.totalRows}
                        totalPages={algorithmsForStandardizing?.totalPages || 1} // 0 causes issues for IdsTablePagination
                        onSelectAll={onSelectAll}
                        onChange={onChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
            <div className="vertical-divider" />
            {/* NOTE: Key here causes small freeze up and errors in console,
                      but otherwise dropdown default values do not get updated */}
            <div
                key={
                    activeAlgorithm === null
                        ? "algorithm-none"
                        : `algorithm-${activeAlgorithm.algorithmNumber}-${selectedAlgorithms.length}`
                }
                className="w-30 flex-grow pl-4"
            >
                {fetchStdPermutations && !isLoadingStdPermutations && (
                    <SuggestedStandard
                        id={id}
                        name={name}
                        standardComponent={
                            <StandardPermutations
                                stdPermutationValues={stdPermutationValues}
                                fuelTypeList={fuelTypeList}
                                stdSectorList={stdSectorList}
                                stdEndUseList={stdEndUseList}
                                stdMeasureList={stdMeasureList}
                                stdVintageList={stdVintageList}
                                onSelect={onSelectStandardPermutation}
                            />
                        }
                        standardizeDisabled={standardizeDisabled}
                        additionalComponent={<MeasureDescription measureDescription={activeAlgorithm?.measureDescription} />}
                        onStandardizeClick={onStandardizeClick}
                    />
                )}
            </div>
        </div>
    );
};

export type StandardPermutationValues = {
    fuelTypeNumber: string;
    stdSectorNumber: string;
    stdEndUseNumber: string;
    stdMeasureNumber: string;
    stdVintageNumber: string;
};
