import { isEmpty } from "lodash";
import { QRCodeSVG } from "qrcode.react";
import { useMemo, useState } from "react";

import { IdsText, IdsFieldWrapper, IdsTextInput, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { ReturnButton } from "./ReturnButton";
import { Wrapper } from "./Wrapper";

import { useSetupAuthenticatorMutation, useDeleteQrCodeMutation } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";
import { logout, loginClearQrCode, resetLogin } from "store/login/actions";

import { clearBrowserUrl } from "utils/window";

export const SetupAuthenticator: React.FC<{
    userNumber?: string;
    userName?: string;
    qrCode?: string;
}> = ({ userNumber, userName, qrCode }) => {
    const dispatch = useAppDispatch();

    const [authCode, setAuthCode] = useState("");

    const [error, setError] = useState<string | null>(null);

    const [setupAuthenticator, setupAuthenticatorStatus] = useSetupAuthenticatorMutation();
    const [deleteQrCode] = useDeleteQrCodeMutation();

    const qrCodeValue = useMemo(() => {
        if (!userName || !qrCode) {
            return;
        }

        return `otpauth://totp/VisionDSM:${userName}?secret=${qrCode}&issuer=AEG`;
    }, [userName, qrCode]);

    const onReturn = () => {
        try {
            userNumber && deleteQrCode({ userNumber });
            dispatch(logout());
        } catch (error) {
            console.error(error);
        }
    };

    const onSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await setupAuthenticator({ authCode }).unwrap();

            if (response.responseStatus === "failure") {
                setError("Invalid Authentication Code");

                return;
            }

            clearBrowserUrl();

            dispatch(loginClearQrCode());
            dispatch(resetLogin());
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper>
            <form className="flex-column align-center gap-5" onSubmit={onSave}>
                <IdsText size="sm" weight="bold" component="h3">
                    Set up Authenticator
                </IdsText>
                <div className="fill-width">
                    <div className="flex-column">
                        <ol className="flex-column gap-2 pb-4">
                            <li>
                                <IdsText>
                                    Get the <strong>Google Authenticator</strong> or <strong>Microsoft Authenticator</strong> app from your
                                    application store.
                                </IdsText>
                            </li>
                            <li>
                                <IdsText>
                                    Once the app is installed, select <strong>Set Up Account</strong> in the app.
                                </IdsText>
                            </li>
                            <li>
                                <IdsText>
                                    Choose <strong>Scan barcode</strong>.
                                </IdsText>
                            </li>
                            <li>
                                <IdsText>
                                    Scan the barcode below to configure the authentication code you will use to log into the TRMulator
                                    system
                                </IdsText>
                            </li>
                        </ol>
                        {qrCodeValue && (
                            <div className="pb-4">
                                <QRCodeSVG value={qrCodeValue} style={{ width: "100%" }} />
                            </div>
                        )}
                    </div>
                    <IdsFieldWrapper
                        wrapperLabel="Authentication Code"
                        isRequired
                        isInvalid={error !== null}
                        helperInvalidText={error ?? undefined}
                    >
                        <IdsTextInput
                            placeholder="Enter authentication code"
                            defaultValue={authCode}
                            changeHandler={(value) => setAuthCode(value)}
                            rest={{
                                autoFocus: true,
                            }}
                        />
                    </IdsFieldWrapper>
                    <IdsButtonGroup position="justify">
                        <ReturnButton isForForm onReturn={onReturn} />
                        <IdsButton type="submit" isDisabled={isEmpty(authCode) || setupAuthenticatorStatus.isLoading}>
                            <>{setupAuthenticatorStatus.isLoading ? "Setting Up..." : "Continue"}</>
                        </IdsButton>
                    </IdsButtonGroup>
                </div>
            </form>
        </Wrapper>
    );
};
