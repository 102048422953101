import cn from "classnames";
import { isEmpty, map } from "lodash";
import { useRef, useState } from "react";

import { IdsModal, IdsText, IdsTabs, IdsTabItem, IdsTabPanel, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { EditFormTags } from "components/EditFormTags";

import {
    useGetAssumptionQuery,
    useGetAssumptionsByStdVariableQuery,
    useUpdateStandardizingForAssumptionMutation,
    useCreateStdVariableMutation,
    useGetStdVariablesQuery,
} from "store/apiSlice";

import { DEFAULT_ITEMS_PER_PAGE } from "utils/constants";

import { SelectStdVariable } from "./SelectStdVariable";
import { CreateStdVariable } from "./CreateStdVariable";
import { VariableMappingTabs } from "./utils";

import type { AlgorithmVariable } from "types";

const VARIABLE_MAPPING_MODAL_STYLE = {
    width: "948px",
    maxWidth: "100%",
    height: "100%",
};

export const VariableMapping: React.FC<{
    assumptionNumber?: string;
    assumptionForMapping?: AlgorithmVariable;
    trmFamiliarName: string;
    measureName: string;
    sector: string;
    endUse?: string;
    vintage: string;
    fuelType: string;
    onClose: () => void;
}> = ({ assumptionNumber, assumptionForMapping, trmFamiliarName, measureName, sector, endUse, vintage, fuelType, onClose }) => {
    const [activeTab, setActiveTab] = useState<VariableMappingTab>(VariableMappingTabs.SelectExisting.id);
    // const [selectedStdVariable, setSelectedStdVariable] = useState(assumption.stdVariableNumber ?? "");
    const [selectedStdVariable, setSelectedStdVariable] = useState("");
    const [description, setDescription] = useState("");
    const [unit, setUnit] = useState("");
    const [pageNumber, setPageNumber] = useState(1);

    const modalRef = useRef<HTMLIdsModalElement>();

    const { data, isLoading: isLoadingAssumption } = useGetAssumptionQuery(
        { assumptionNumber: assumptionNumber! },
        { skip: isEmpty(assumptionNumber) },
    );
    const assumption = (isEmpty(assumptionNumber) ? assumptionForMapping : data) as AlgorithmVariable | undefined;

    const { data: stdVariables, isLoading: isLoadingStdVariables } = useGetStdVariablesQuery();

    const { data: assumptionsByStdVariable } = useGetAssumptionsByStdVariableQuery(
        { stdVariableNumber: selectedStdVariable, pageNumber, pageSize: DEFAULT_ITEMS_PER_PAGE },
        { skip: isEmpty(selectedStdVariable) },
    );
    const assumptions = assumptionsByStdVariable?.assumptionsByStdVariableList;
    const totalRows = assumptionsByStdVariable?.totalRows;
    const totalPages = assumptionsByStdVariable?.totalPages;

    const [updateAssumptionStandardizing, updateAssumptionStandardizingStatus] = useUpdateStandardizingForAssumptionMutation();
    const [createStdVariable, createStdVariableStatus] = useCreateStdVariableMutation();

    const areChangesMade =
        (activeTab === VariableMappingTabs.SelectExisting.id &&
            selectedStdVariable !== assumption?.stdVariableNumber &&
            !isEmpty(selectedStdVariable)) ||
        (activeTab === VariableMappingTabs.CreateNew.id && !isEmpty(description) && !isEmpty(unit));

    const onMount = (element: HTMLIdsModalElement | null) => {
        if (!element || modalRef.current || !assumption?.stdVariableNumber) {
            return;
        }

        modalRef.current = element;

        setSelectedStdVariable(assumption.stdVariableNumber);
    };

    const onChange = (value: string, name: string) => {
        if (name === "stdVariable") {
            setSelectedStdVariable(value);
        } else if (name === "description") {
            setDescription(value);
        } else if (name === "unit") {
            setUnit(value);
        }
    };

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (
            updateAssumptionStandardizingStatus.isLoading ||
            (activeTab === VariableMappingTabs.CreateNew.id && createStdVariableStatus.isLoading) ||
            !assumption
        ) {
            return;
        }

        try {
            let stdVariableNumber = selectedStdVariable;

            if (activeTab === VariableMappingTabs.CreateNew.id) {
                const stdVariableModel = {
                    stdVariableDesc: description,
                    stdVariableUnits: unit,
                };

                const response = await createStdVariable({ stdVariable: stdVariableModel }).unwrap();

                if (response.responseStatus === "failure") {
                    return;
                }

                stdVariableNumber = response.stdVariableNumber;
            }

            const dateActivated = new Date(Date.now()).toISOString();

            const assumptions = [
                {
                    assumptionNumber: assumption.assumptionNumber,
                    stdVariableNumber,
                    dateActivated,
                },
            ];

            await updateAssumptionStandardizing({ assumptions });

            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    const onTabChange = (id: VariableMappingTab) => {
        setActiveTab(id);
    };

    return (
        <IdsModal
            ref={onMount}
            version={2}
            isOpen
            closeHandler={onClose}
            showCloseButton
            customClasses={cn("slideout", {
                "empty-state": isEmpty(selectedStdVariable) || isEmpty(assumptions),
            })}
        >
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Standardize Variable
                </IdsText>
            </div>
            <div className="flex-column" slot="main" style={VARIABLE_MAPPING_MODAL_STYLE}>
                <div>
                    <EditFormTags
                        trmName={trmFamiliarName}
                        measureName={measureName}
                        sector={sector}
                        endUse={endUse}
                        vintage={vintage}
                        fuelType={fuelType}
                        variable={assumption?.variable}
                    />
                </div>
                <IdsTabs customClasses="fill-height">
                    {map(VariableMappingTabs, (tab) => (
                        <IdsTabItem
                            key={`tab-header-${tab.id}`}
                            slot="header"
                            idValue={tab.id}
                            label={tab.name}
                            isActive={activeTab === tab.id}
                            onClick={() => onTabChange(tab.id)}
                        />
                    ))}

                    {map(VariableMappingTabs, (tab) => (
                        <IdsTabPanel
                            key={`tab-panel-${tab.id}`}
                            customClasses="fill-height pt-3 pb-4"
                            slot="panel"
                            idValue={tab.id}
                            // Horizontal paddings fix outline being out of bounds
                            style={{
                                backgroundColor: "inherit",
                                paddingRight: 3,
                                paddingLeft: 3,
                            }}
                        >
                            <div className="fill-height">
                                {activeTab === VariableMappingTabs.SelectExisting.id && (
                                    <SelectStdVariable
                                        selectedStdVariable={selectedStdVariable}
                                        stdVariables={stdVariables}
                                        assumptions={assumptions}
                                        pageNumber={pageNumber}
                                        totalRows={totalRows}
                                        totalPages={totalPages}
                                        isLoading={isLoadingAssumption || isLoadingStdVariables}
                                        onChange={onChange}
                                        onPageChange={setPageNumber}
                                    />
                                )}
                                {activeTab === VariableMappingTabs.CreateNew.id && (
                                    <CreateStdVariable description={description} unit={unit} onChange={onChange} />
                                )}
                            </div>
                        </IdsTabPanel>
                    ))}
                </IdsTabs>
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="lg">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton isDisabled={!areChangesMade} clickHandler={onSave}>
                        Save
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

type VariableMappingTab = (typeof VariableMappingTabs)[keyof typeof VariableMappingTabs]["id"];
