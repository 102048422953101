import cn from "classnames";

export const ViewPlaceholder = (props: ViewPlaceholderProps) => {
    return (
        <div
            className={cn("flex-column flex-one fill-height align-center justify-center", props.className)}
            style={{ fontWeight: 700, fontSize: "2rem", color: "rgba(0, 0, 0, 0.11)" }}
        >
            <div className="text-center">{props.children}</div>
        </div>
    );
};

interface ViewPlaceholderProps {
    className?: string;
    children: React.ReactNode;
}
