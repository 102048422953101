import { IdsTable } from "@emergn-infinity/ids-react";

import { MeasureLivesGridHeader } from "./MeasureLivesGridHeader";
import { MeasureLivesGridRows } from "./MeasureLivesGridRows";

import { Tabs } from "pages/Home/utils";

import { RelevantAlgorithm } from "types";
import { isEmpty } from "lodash";
import { useGridMeasureLivesQuery } from "store/apiSlice";
import { NothingFoundBlock } from "components/NothingFoundBlock";
import { subtitlesSlashSolidIcon } from "utils/icons";

const defaultStyles: GridStyle = {
    trmAndMeasure: {
        minWidth: "10rem",
    },
    sector: {
        minWidth: 0,
        width: "10rem",
    },
    vintage: {
        minWidth: 0,
        width: "8.125rem",
    },
    lookupSelection: {
        minWidth: 0,
        maxWidth: "13.75rem",
        width: "13.75rem",
    },
    eul: {
        minWidth: 0,
        width: "4.375rem",
    },
    rul: {
        minWidth: "5rem",
        // TODO: Use these values when Beta tag is removed
        // minWidth: 0,
        // width: "4.375rem",
    },
    source: {
        minWidth: 0,
    },
    action: {
        minWidth: 0,
    },
};

export const MeasureLivesGrid: React.FC<{
    gridType: GridType;
    measureNumber?: string;
    items?: RelevantAlgorithm[];
    editRights?: boolean;
    styles?: GridStyle;
    onEdit?: (eulNumber: string) => void;
    onDelete?: (eulNumber: string) => void;
}> = ({ gridType, measureNumber, items, editRights = false, styles = defaultStyles, onEdit, onDelete }) => {
    styles.trmAndMeasure = styles.trmAndMeasure ?? defaultStyles.trmAndMeasure;
    styles.sector = styles.sector ?? defaultStyles.sector;
    styles.vintage = styles.vintage ?? defaultStyles.vintage;
    styles.lookupSelection = styles.lookupSelection ?? defaultStyles.lookupSelection;
    styles.eul = styles.eul ?? defaultStyles.eul;
    styles.source = styles.source ?? defaultStyles.source;
    styles.action = styles.action ?? defaultStyles.action;

    if (gridType === Tabs.ExploreTrms.id) {
        return (
            <ExploreTrmsMeasureLivesGrid
                gridType={gridType}
                measureNumber={measureNumber}
                editRights={editRights}
                styles={styles}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        );
    }

    return (
        <IdsTable variant="alternate" spacing="sm">
            <MeasureLivesGridHeader gridType={gridType} editRights={editRights} styles={styles} />
            {items?.map((item) => (
                <MeasureLivesGridRows
                    key={item.algorithmNumber}
                    gridType={gridType}
                    styles={styles}
                    measureNumber={item.measureNumber}
                    trmFamiliarName={item.trmFamiliarName}
                    measure={item.measure}
                    sector={item.sector}
                />
            ))}
        </IdsTable>
    );
};

const ExploreTrmsMeasureLivesGrid: React.FC<{
    gridType: GridType;
    measureNumber?: string;
    editRights?: boolean;
    styles?: GridStyle;
    onEdit?: (eulNumber: string) => void;
    onDelete?: (eulNumber: string) => void;
}> = ({ gridType, measureNumber, editRights = false, styles = defaultStyles, onEdit, onDelete }) => {
    const { data, isLoading } = useGridMeasureLivesQuery({ measureNumber: measureNumber! }, { skip: !measureNumber });

    if (isEmpty(data) && !isLoading && !isEmpty(measureNumber)) {
        return (
            <div className="py-4 bg-theme-base">
                <NothingFoundBlock icon={subtitlesSlashSolidIcon} title="No Measure Live" message="Measure lives added will show here" />
            </div>
        );
    }

    return (
        <IdsTable variant="alternate" spacing="sm">
            <MeasureLivesGridHeader gridType={gridType} editRights={editRights} styles={styles} />
            <MeasureLivesGridRows
                gridType={gridType}
                styles={styles}
                measureNumber={measureNumber}
                editRights={editRights}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        </IdsTable>
    );
};

export type GridType = (typeof Tabs)[keyof typeof Tabs]["id"];

/**
 * Maps the properties that are not provided
 * to their default values.
 */
export type GridStyle = {
    trmAndMeasure?: React.CSSProperties;
    sector?: React.CSSProperties;
    vintage?: React.CSSProperties;
    lookupSelection?: React.CSSProperties;
    eul?: React.CSSProperties;
    rul?: React.CSSProperties;
    source?: React.CSSProperties;
    action?: React.CSSProperties;
};
