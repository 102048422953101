import { isEmpty, isNil, isNumber } from "lodash";
import { createRef, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetAlgorithmAssumptionsQuery, useUpdateSavingsCalculationMutation, useGetLookupQuery } from "store/apiSlice";

import {
    IdsButton,
    IdsButtonGroup,
    IdsCheckbox,
    IdsDropdown,
    IdsHelper,
    IdsTable,
    IdsTableCell,
    IdsTableRow,
    IdsTag,
    IdsTextInput,
} from "@emergn-infinity/ids-react";

import { IconButton } from "components/IconButton";
import { NothingFoundBlock } from "components/NothingFoundBlock";
import { Tile } from "components/Tile";
import { Tooltip } from "components/Tooltip";

import { getVariableDescription, useMeasureDetails } from "pages/ExploreTrms/utils";

import { VariableMapping } from "sidebars/VariableMapping";

import { AssumptionsRights, VarStrategy as Strategy } from "utils/constants";
import {
    arrowProgressRegularIcon,
    circleExclamationLightIcon,
    circlePlusRegularIcon,
    fileExportRegularIcon,
    linkSimpleRegularIcon,
    penToSquareRegularIcon,
    subtitlesSlashSolidIcon,
    trashRegularIcon,
} from "utils/icons";
import { formatNumber, formatAlgorithmText, formatVariableText, sanitizeAndSetNumericInput } from "utils/string";
import { hasRights, isAdminUser } from "utils/user";

import { VariableEdit } from "./VariableEdit";
import { VariableDelete } from "./VariableDelete";

import { MeasureAlgorithm, AlgorithmVariable, Variable } from "types";

export const VariablesTile: React.FC<{
    selectedMeasure: string;
    algorithm?: MeasureAlgorithm;
    algorithmVariables: Variable[];
    tempAssignedCalculation?: string;
    setTempAssignedCalculation: (tempAssignedCalculation: string | undefined) => void;
    onVariableChange: (variable: Variable) => void;
    onExport: (variables: AlgorithmVariable[]) => void;
}> = ({
    selectedMeasure,
    algorithm,
    algorithmVariables,
    tempAssignedCalculation,
    setTempAssignedCalculation,
    onVariableChange,
    onExport,
}) => {
    const [assumptionForEdit, setAssumptionForEdit] = useState<AlgorithmVariable>();
    const [assumptionForDelete, setAssumptionForDelete] = useState<AlgorithmVariable>();
    const [assumptionForMapping, setAssumptionForMapping] = useState<AlgorithmVariable>();
    const [isAdding, setIsAdding] = useState(false);
    const [showAll, setShowAll] = useState(true);

    const measure = useMeasureDetails(selectedMeasure);

    const { data, isFetching, isLoading, isError } = useGetAlgorithmAssumptionsQuery(
        { algorithmNumber: algorithm?.algorithmNumber! },
        { skip: !algorithm?.algorithmNumber, refetchOnMountOrArgChange: true },
    );
    const algorithmAssumptions = data?.assumptionList;

    const [updateSavingsCalculation, updateSavingsCalculationStatus] = useUpdateSavingsCalculationMutation();

    const showFilter = (algorithmAssumptions ?? []).some((item) => !item.isFoundInAlgorithm);
    const editRights = hasRights(AssumptionsRights);
    const allVariablesSet = algorithmVariables.every((variable) => !isEmpty(variable.value?.toString()));

    const [additionalDetails, setAdditionalDetails] = useState<
        {
            id: number;
            lookupNumber: string;
        }[]
    >([]);

    const [userInputs, setUserInputs] = useState<
        {
            id: number;
            userInput?: number;
            errorMessage?: string;
        }[]
    >([]);

    const tableData = useMemo(
        () =>
            isEmpty(algorithm) || isEmpty(measure) ? [] : (algorithmAssumptions ?? []).filter((item) => showAll || item.isFoundInAlgorithm),
        [algorithm, measure, algorithmAssumptions, showAll],
    );

    const variables = useMemo(() => {
        return algorithmVariables.reduce((acc, cv) => {
            acc[cv.name] = {
                value: cv.value,
                equation: cv.equation,
            };

            return acc;
        }, {});
    }, [algorithmVariables]);

    const showEmptyState = isEmpty(tableData) && !isLoading && !isError && !isEmpty(measure);

    const inputRefs = useMemo(() => tableData.map(() => createRef<HTMLInputElement>()), [tableData]);

    const showVariablesErrorMessage = useMemo(
        () => !isEmpty(algorithm) && tableData.some((item) => !isEmpty(item.additionalDetails) || item.overrideAllowed),
        [algorithm, tableData],
    );

    if (!isEmpty(tableData) && tableData.some((item) => item.strategy === Strategy.Binary) && !isFetching && isEmpty(userInputs)) {
        const newUserInputs = tableData
            .filter((item) => item.strategy === Strategy.Binary)
            .map((filteredItem) => ({
                id: filteredItem.id,
                userInput: 1,
            }));

        setUserInputs(newUserInputs);
    }

    if (isFetching && !isEmpty(userInputs)) {
        setUserInputs([]);
    }

    if (isFetching && !isEmpty(additionalDetails)) {
        setAdditionalDetails([]);
    }

    const onAdditionalDetailsChange = (id: number, lookupNumber?: string) => {
        setAdditionalDetails((prev) => {
            if (!lookupNumber) {
                return prev.filter((detail) => detail.id !== id);
            }

            const index = prev.findIndex((detail) => detail.id === id);
            if (index === -1) {
                return [...prev, { id, lookupNumber }];
            }

            return prev.map((detail, i) => (i === index ? { id, lookupNumber } : detail));
        });
    };

    const updateAssignedSavingsCalculation = async ({
        assumptionNumber,
        lookupNumber,
        assignedCalculation,
    }: {
        assumptionNumber: string;
        lookupNumber?: string;
        assignedCalculation?: string;
    }) => {
        if (!algorithm || !data) {
            return;
        }

        // Substitute variable with lookup equation
        if (lookupNumber) {
            try {
                const response = await updateSavingsCalculation({
                    savingsCalculation: {
                        assumptionNumber,
                        lookupNumber,
                        algorithmNumber: algorithm.algorithmNumber,
                        assignedCalculation: assignedCalculation ?? data.assignedSavingsCalculation,
                    },
                }).unwrap();

                if (response.assignedSavingsCalculation) {
                    setTempAssignedCalculation(response.assignedSavingsCalculation);
                }
            } catch (error) {
                console.error(error);
            }
        }
        // Substitute lookup equation with variable
        else {
            setTempAssignedCalculation(assignedCalculation);
        }
    };

    /**
     * Substitutes lookup equation with variable.
     * @param assignedCalculation - calculation
     * @param equation - lookup equation to substitute
     * @param variableName - name of the variable that will substitute lookup equation
     * @returns calculation without the lookup equation
     */
    const revertAssignedSavingsCalculation = (assignedCalculation: string, equation: string, variableName: string) => {
        return assignedCalculation.replace(`(${equation})`, `<${variableName}>`);
    };

    const onUserInputCheckboxChange = (id: number, value: boolean) => {
        setUserInputs((prev) => prev.map((detail) => (detail.id === id ? { id, userInput: value === true ? 1 : 0 } : detail)));
    };

    const onUserInputNumberChange = (variable: AlgorithmVariable, value: string, inputIndex: number) => {
        const { id, assignedValue_Min, assignedValue_Max } = variable;

        const userInputAsString = sanitizeAndSetNumericInput(value, inputRefs[inputIndex]);
        const userInput = userInputAsString ? Number(userInputAsString) : undefined;

        setUserInputs((prev) => {
            let minValueError = false;
            let maxValueError = false;
            let errorMessage = "";

            const index = prev.findIndex((detail) => detail.id === id);

            if (userInput !== undefined) {
                if (assignedValue_Min) {
                    minValueError = userInput < assignedValue_Min;
                }
                if (assignedValue_Max) {
                    maxValueError = userInput > assignedValue_Max;
                }
            }

            if (minValueError) {
                errorMessage = `${formatVariableText(variable.variable)} value is less than allowed minimum value.`;
            } else if (maxValueError) {
                errorMessage = `${formatVariableText(variable.variable)} value is greater than allowed maximum value.`;
            }

            if (index === -1) {
                return [...prev, { id, userInput, errorMessage }];
            }

            return prev.map((detail, i) => (i === index ? { id, userInput, errorMessage } : detail));
        });
    };

    const getInputValue = (variable: AlgorithmVariable) => {
        const selectedInput = userInputs.find((input) => input.id === variable.id);
        return !isNil(selectedInput?.userInput) ? String(selectedInput.userInput) : (variable.userInput ?? undefined);
    };

    const getInputError = (id: number) => {
        return !isEmpty(userInputs.find((input) => input.id === id)?.errorMessage ?? "");
    };

    const getCellStyle = (variable: AlgorithmVariable) => {
        if (!variable.isFoundInAlgorithm) {
            return {
                minWidth: 0,
                backgroundColor: "var(--ids-semantic-background-color-neutral-subtlest-default)",
                color: "var(--ids-semantic-ink-color-neutral-subtlest)",
            };
        }
        return {
            backgroundColor: "var(--ids-semantic-background-color-neutral-subtle-default)",
            color: "var(--ids-semantic-ink-color-neutral-subtle-onlight)",
            minWidth: 0,
        };
    };

    const getInputStyle = (variable: AlgorithmVariable) => {
        const hasAssignedValue = isNumber(variable.assignedValue);
        const hasUserInput = !isEmpty(getInputValue(variable));
        const hasLookup = !isEmpty(additionalDetails.find((detail) => detail.id === variable.id));

        const hasUserInputError = getInputError(variable.id);

        return (hasAssignedValue || hasUserInput || hasLookup) && !hasUserInputError
            ? undefined
            : {
                  "--ids-input-field-border-color-default": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-input-field-border-color-hover": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-input-field-focus-ring-color-default": "var(--ids-semantic-focus-ring-color-critical)",
                  "--ids-input-field-text-color-filled": "var(--ids-semantic-ink-color-critical-subtlest)",
              };
    };

    const getAdditionalDetailsStyle = (variable: AlgorithmVariable) => {
        const defaultStyle = { width: "auto" };
        const selectedDetail = additionalDetails.find((detail) => detail.id === variable.id);

        return selectedDetail
            ? defaultStyle
            : {
                  ...defaultStyle,
                  "--ids-dropdown-field-border-color-default": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-dropdown-field-border-color-hover": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-dropdown-field-focus-ring-color-default": "var(--ids-semantic-focus-ring-color-critical)",
              };
    };

    const onStandardizeVariable = (variable: AlgorithmVariable) => {
        setAssumptionForMapping(variable);
    };

    return (
        <Tile
            title="Variables and Assumptions"
            action={
                <div className="flex-row gap-4">
                    {showFilter && (
                        <IdsCheckbox
                            idValue="measure-variables-filter"
                            label="Show All Variables"
                            defaultChecked={showAll}
                            clickHandler={(e: any) => setShowAll(e.target.checked)}
                        />
                    )}
                    <div className="flex-row gap-2">
                        {editRights && !isEmpty(algorithm) && (
                            <IdsButton padding="sm" variant="secondary" clickHandler={() => setIsAdding(true)}>
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                                    Add Variable
                                </div>
                            </IdsButton>
                        )}
                        {!isEmpty(algorithmAssumptions) && (
                            <IdsButton
                                padding="sm"
                                variant="secondary"
                                clickHandler={() => onExport(algorithmAssumptions ?? [])}
                                title="Copy variables to clipboard"
                            >
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={fileExportRegularIcon} fixedWidth />
                                    Export
                                </div>
                            </IdsButton>
                        )}
                    </div>
                </div>
            }
        >
            <div className="p-3">
                {/* Add Assumption */}
                {isAdding && measure && algorithm && (
                    <VariableEdit measure={measure} algorithm={algorithm} onClose={() => setIsAdding(false)} />
                )}

                {/* Edit Assumption */}
                {assumptionForEdit && measure && algorithm && (
                    <VariableEdit
                        measure={measure}
                        assumption={assumptionForEdit}
                        algorithm={algorithm}
                        onClose={() => setAssumptionForEdit(undefined)}
                    />
                )}

                {/* Delete Assumption */}
                {assumptionForDelete && measure && algorithm && (
                    <VariableDelete
                        measure={measure}
                        assumption={assumptionForDelete}
                        algorithm={algorithm}
                        onClose={() => setAssumptionForDelete(undefined)}
                    />
                )}

                {/* Standardize Variable */}
                {assumptionForMapping && measure && algorithm && (
                    <VariableMapping
                        assumptionForMapping={assumptionForMapping}
                        trmFamiliarName={measure.trmFamiliarName}
                        measureName={measure.measureName}
                        sector={algorithm.sector}
                        endUse={algorithm.endUse}
                        vintage={algorithm.vintage}
                        fuelType={algorithm.fuelType}
                        onClose={() => setAssumptionForMapping(undefined)}
                    />
                )}

                {showEmptyState ? (
                    <div className="py-4 bg-theme-base">
                        <NothingFoundBlock icon={subtitlesSlashSolidIcon} title="No Variables" message="Variables added will show here" />
                    </div>
                ) : (
                    <>
                        <IdsTable variant="alternate" spacing="sm">
                            <IdsTableRow rowType="table-heading-row" customClasses="sticky-top" style={{ top: -25 }}>
                                <IdsTableCell cellType="table-heading-cell" heading="Variable" style={{ minWidth: 0, width: "10rem" }} />
                                <IdsTableCell cellType="table-heading-cell" heading="Description" style={{ minWidth: 0 }} />
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="Lookup Selection"
                                    style={{ minWidth: "12rem", width: "12rem" }}
                                />
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="User Input"
                                    style={{ minWidth: "5rem", width: "7rem" }}
                                />
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="Assigned Value"
                                    style={{ minWidth: 0, width: "5rem", textAlign: "center" }}
                                />
                                <IdsTableCell cellType="table-heading-cell" heading="Units" style={{ minWidth: 0, width: "5%" }} />
                                <IdsTableCell cellType="table-heading-cell" heading="Source" style={{ minWidth: 0 }} />
                                {editRights && <IdsTableCell cellType="table-heading-cell" style={{ minWidth: 0 }} />}
                            </IdsTableRow>
                            {tableData?.map((item, index) => (
                                <IdsTableRow key={item.id} rowType="table-body-row">
                                    <IdsTableCell style={getCellStyle(item)}>
                                        <IdsTag variant="information" size="sm">
                                            <div dangerouslySetInnerHTML={{ __html: formatVariableText(item.variable) }} />
                                        </IdsTag>
                                    </IdsTableCell>
                                    <IdsTableCell style={getCellStyle(item)}>
                                        <div>{getVariableDescription(item)}</div>
                                        {(item.equation || variables[item.variable]?.equation) && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: formatAlgorithmText(item.equation ?? variables[item.variable]?.equation ?? ""),
                                                }}
                                            />
                                        )}
                                    </IdsTableCell>
                                    <IdsTableCell style={{ ...getCellStyle(item), maxWidth: "12rem", verticalAlign: "top" }}>
                                        <div>
                                            {item.additionalDetails && !isEmpty(item.additionalDetails) && (
                                                <IdsDropdown
                                                    size="sm"
                                                    idValue="measure-variables-additional-details"
                                                    placeholder=" "
                                                    items={item.additionalDetails.map((detail) => ({
                                                        value: detail.lookupNumber,
                                                        label: detail.lookupCriteria,
                                                    }))}
                                                    changeHandler={(lookupNumber) => onAdditionalDetailsChange(item.id, lookupNumber)}
                                                    clearHandler={() => onAdditionalDetailsChange(item.id)}
                                                    style={getAdditionalDetailsStyle(item)}
                                                />
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    <IdsTableCell style={{ ...getCellStyle(item), verticalAlign: "top" }}>
                                        <div>
                                            {item.overrideAllowed && (
                                                <>
                                                    {item.strategy === Strategy.Binary ? (
                                                        <IdsCheckbox
                                                            idValue={item.id.toString()}
                                                            defaultChecked={getInputValue(item) === "1"}
                                                            clickHandler={(e: any) => onUserInputCheckboxChange(item.id, e.target.checked)}
                                                        />
                                                    ) : (
                                                        <IdsTextInput
                                                            size="sm"
                                                            innerRef={inputRefs[index]}
                                                            defaultValue={getInputValue(item)}
                                                            style={getInputStyle(item)}
                                                            changeHandler={(value) => onUserInputNumberChange(item, value, index)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    <IdsTableCell style={{ ...getCellStyle(item), textAlign: "right" }}>
                                        <AssignedValueCell
                                            variable={item}
                                            lookupNumber={additionalDetails.find((detail) => detail.id === item.id)?.lookupNumber}
                                            userInput={getInputValue(item)}
                                            userInputError={getInputError(item.id)}
                                            tempAssignedCalculation={tempAssignedCalculation}
                                            isUpdating={updateSavingsCalculationStatus.isLoading}
                                            updateAssignedSavingsCalculation={updateAssignedSavingsCalculation}
                                            revertAssignedSavingsCalculation={revertAssignedSavingsCalculation}
                                            onChange={onVariableChange}
                                        />
                                    </IdsTableCell>
                                    <IdsTableCell style={getCellStyle(item)}>{item.units}</IdsTableCell>
                                    <IdsTableCell style={getCellStyle(item)}>
                                        <div>
                                            {!isEmpty(item.source) && (
                                                <Tooltip message={item.source}>
                                                    <IdsTag size="sm">
                                                        <div className="flex-row gap-2 align-center">
                                                            <FontAwesomeIcon icon={linkSimpleRegularIcon} fixedWidth />
                                                            {item.sourceYear ? item.sourceYear : "-"}
                                                        </div>
                                                    </IdsTag>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    {editRights && (
                                        <IdsTableCell style={{ ...getCellStyle(item), verticalAlign: "middle" }}>
                                            <IdsButtonGroup spaceBetween="sm" position="right" customClasses="flex-no-wrap">
                                                {!item.isFoundInAlgorithm && (
                                                    <IconButton
                                                        icon={trashRegularIcon}
                                                        size="lg"
                                                        title="Delete Variable"
                                                        onClick={() => setAssumptionForDelete(item)}
                                                    />
                                                )}
                                                <IconButton
                                                    icon={penToSquareRegularIcon}
                                                    size="lg"
                                                    title="Edit Variable"
                                                    onClick={() => setAssumptionForEdit(item)}
                                                />
                                                {isAdminUser() && (
                                                    <IconButton
                                                        icon={arrowProgressRegularIcon}
                                                        size="lg"
                                                        title="Standardize Variable"
                                                        badgeVariant={item.stdVariableNumber === null ? "critical" : "success"}
                                                        onClick={() => onStandardizeVariable(item)}
                                                    />
                                                )}
                                            </IdsButtonGroup>
                                        </IdsTableCell>
                                    )}
                                </IdsTableRow>
                            ))}
                        </IdsTable>
                        {showVariablesErrorMessage && (
                            <IdsHelper
                                customClasses="pt-2"
                                helperText="Fields outlined in red require input for savings calculations."
                                helperIcon="ui-alert-alert_circle"
                                helperInvalidText="Fields outlined in red require input for savings calculations."
                                helperInvalidIcon="ui-alert-alert_circle"
                                isInvalid={!allVariablesSet}
                            />
                        )}
                        {userInputs.some((input) => !isEmpty(input.errorMessage)) &&
                            userInputs
                                .filter((input) => !isEmpty(input.errorMessage))
                                .map((filteredInput) => (
                                    <div key={`variable-error-${filteredInput.id}`} className="flex-row align-center gap-1 py-1">
                                        <FontAwesomeIcon
                                            icon={circleExclamationLightIcon}
                                            size="sm"
                                            color="var(--ids-semantic-ink-color-critical-subtlest)"
                                        />
                                        <div
                                            dangerouslySetInnerHTML={{ __html: filteredInput.errorMessage! }}
                                            style={{
                                                color: "var(--ids-semantic-ink-color-critical-subtlest)",
                                                fontSize: "var(--ids-semantic-font-font-size-helper1-fixed)",
                                            }}
                                        />
                                    </div>
                                ))}
                    </>
                )}
            </div>
        </Tile>
    );
};

const AssignedValueCell: React.FC<{
    variable: AlgorithmVariable;
    lookupNumber?: string;
    userInput?: string;
    userInputError?: boolean;
    isUpdating?: boolean;
    tempAssignedCalculation?: string;
    updateAssignedSavingsCalculation: ({
        assumptionNumber,
        lookupNumber,
        assignedCalculation,
    }: {
        assumptionNumber: string;
        lookupNumber?: string;
        assignedCalculation?: string;
    }) => Promise<void>;
    revertAssignedSavingsCalculation: (assignedCalculation: string, equation: string, variableName: string) => string;
    onChange: (variable: Variable) => void;
}> = ({
    variable,
    lookupNumber,
    userInput,
    userInputError,
    isUpdating,
    tempAssignedCalculation,
    updateAssignedSavingsCalculation,
    revertAssignedSavingsCalculation,
    onChange,
}) => {
    const { data, isFetching } = useGetLookupQuery({ lookupNumber: lookupNumber! }, { skip: !lookupNumber });

    const lastValueRef = useRef<string>();
    const lastEquationRef = useRef<string>();

    let variableValue: string | undefined = undefined;
    let displayValue: string | undefined = variable.assignedValue;

    // User input has the highest priority
    if (!isEmpty(userInput)) {
        variableValue = userInput;
    }
    // Lookup value has the second highest priority
    else if (!isEmpty(lookupNumber)) {
        if (isFetching) {
            variableValue = "";
        } else {
            variableValue = String(data?.lookupValue ?? "");
        }
    }
    // Assigned value has the lowest priority
    else {
        variableValue = variable.assignedValue;
    }

    // If the value has changed, update the variable
    if (variableValue !== lastValueRef.current && !userInputError) {
        // "Failed to execute 'removeChild' on 'Node'" is caused by this
        // if <VariablesTile /> component is not wrapped with React.key
        onChange({
            name: variable.variable,
            value: userInput ?? variableValue ?? "",
            equation: variable.equation ?? "",
        });

        lastValueRef.current = variableValue;
    }

    // If the lookup is equation
    if (lookupNumber && data?.lookupEquation && data.lookupEquation !== lastEquationRef.current && !isUpdating) {
        onChange({
            name: variable.variable,
            value: "",
            equation: data.lookupEquation,
        });

        let assignedCalculation = tempAssignedCalculation;

        // If the same variable lookup gets changed, remove the previous equation
        if (assignedCalculation && lastEquationRef.current) {
            assignedCalculation = revertAssignedSavingsCalculation(assignedCalculation, lastEquationRef.current, variable.variable);
        }

        updateAssignedSavingsCalculation({ assumptionNumber: variable.assumptionNumber, lookupNumber, assignedCalculation });

        lastEquationRef.current = data.lookupEquation;
    }
    // If the lookup is cleared and it was equation
    else if (!lookupNumber && tempAssignedCalculation && lastEquationRef.current) {
        const assignedCalculation = revertAssignedSavingsCalculation(tempAssignedCalculation, lastEquationRef.current, variable.variable);

        updateAssignedSavingsCalculation({ assumptionNumber: variable.assumptionNumber, assignedCalculation });
    }

    // Override display value if selected from lookup
    if (!isEmpty(lookupNumber)) {
        displayValue = String(data?.lookupValue ?? "");
    }

    return <span>{formatNumber(displayValue)}</span>;
};
