import { isEmpty, isNil } from "lodash";
import { useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    useCreateCostMutation,
    useGetCostQuery,
    useGetLookupByPremisesQuery,
    useGetSectorsQuery,
    useGetVintagesQuery,
    useUpdateCostMutation,
} from "store/apiSlice";

import {
    IdsFieldWrapper,
    IdsText,
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsModal,
    IdsDropdown,
    IdsRadioButtonGroup,
    IdsRadioButton,
    IdsTag,
} from "@emergn-infinity/ids-react";

import { EditFormTags } from "components/EditFormTags";

import { ManageLookups } from "pages/ExploreTrms/ManageLookups";

import { CostTypes, CostStrategy as Strategy } from "utils/constants";
import { circlePlusRegularIcon, circleInfoRegularIcon } from "utils/icons";
import { sanitizeAndSetNumericInput } from "utils/string";

import { CostType, CostUpdateModel, Measure } from "types";

const COST_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

const initialManageLookups = {
    add: false,
    view: false,
};

export const MeasureCostEdit: React.FC<{
    measure: Measure;
    costNumber?: string;
    onClose: () => void;
}> = ({ measure, costNumber, onClose }) => {
    const [manageLookups, setManageLookups] = useState(initialManageLookups);
    const [model, setModel] = useState<CostModel>();
    const costPerUnitInputRef = useRef<HTMLInputElement>(null);
    const sourceYearInputRef = useRef<HTMLInputElement>(null);

    const { data: cost, isLoading, isError: isCostError } = useGetCostQuery({ costNumber: costNumber! }, { skip: !costNumber });
    const isLoadingCost = isLoading && !isEmpty(costNumber);

    const [create, createStatus] = useCreateCostMutation();
    const [update, updateStatus] = useUpdateCostMutation();

    const { data: sectors, isLoading: isLoadingSectors } = useGetSectorsQuery({ trmNumber: measure.trmNumber, isVerified: true });
    const sectorsList = useMemo(
        () => sectors?.map((sector) => ({ label: sector.sectorName, value: sector.sectorNumber })) || [],
        [sectors],
    );

    const { data: vintages, isLoading: isLoadingVintages } = useGetVintagesQuery({ trmNumber: measure.trmNumber, vintageVerified: true });
    const vintagesList = useMemo(
        () => vintages?.map((vintage) => ({ label: vintage.vintageName, value: vintage.vintageNumber })) || [],
        [vintages],
    );

    const { data: lookups, isLoading: isLoadingLookups } = useGetLookupByPremisesQuery({
        trmNumber: measure.trmNumber,
        lookupType: "Cost",
    });
    const lookupsList = useMemo(() => lookups?.map((lookup) => ({ label: lookup, value: lookup })) || [], [lookups]);

    const lookupBreadcrumb = useMemo(() => {
        let sector = "";
        let vintage = "";

        if (model?.sectorNumber) {
            sector = sectorsList.find((item) => item.value === model.sectorNumber)?.label ?? "";
        }
        if (model?.vintageNumber) {
            vintage = vintagesList.find((item) => item.value === model.vintageNumber)?.label ?? "";
        }

        return [
            <IdsTag key={sector} variant="brand-c" size="sm">
                {sector}
            </IdsTag>,
            <IdsTag key={vintage} size="sm">
                {vintage}
            </IdsTag>,
        ];
    }, [model, sectorsList, vintagesList]);

    if (isEmpty(model)) {
        if (!isEmpty(costNumber) && !isEmpty(cost)) {
            const newModel = {
                ...cost,
                costPerUnit: cost.costPerUnit ? String(cost.costPerUnit) : undefined,
                costSourceYear: cost.costSourceYear ? String(cost.costSourceYear) : undefined,
            };

            setModel(newModel);
        }

        if (isEmpty(costNumber)) {
            setModel({
                costStrategy: Strategy.Deemed,
                measureNumber: measure.measureNumber,
                costVerified: true,
            });
        }
    }

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (updateStatus.isLoading || createStatus.isLoading) {
            return;
        }

        if (model) {
            try {
                const costModel = {
                    ...model,
                    costPerUnit: model.costPerUnit ? Number(model.costPerUnit) : undefined,
                    costSourceYear: model.costSourceYear ? Number(model.costSourceYear) : undefined,
                };

                if (costNumber) {
                    await update({ costNumber, cost: costModel }).unwrap();
                } else {
                    await create({ cost: costModel }).unwrap();
                }
                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const title = isEmpty(costNumber) ? "Add Measure Cost" : "Edit Measure Cost";

    if (manageLookups.add || manageLookups.view) {
        return (
            <ManageLookups
                measure={measure}
                lookupType="Cost"
                lookupByPremise={manageLookups.view && model?.costLookupBy ? model.costLookupBy : undefined}
                title={title}
                breadcrumb={lookupBreadcrumb}
                onClose={() => setManageLookups(initialManageLookups)}
            />
        );
    }

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={COST_MODAL_STYLE}>
                {(isLoadingCost || isLoadingSectors || isLoadingVintages || isLoadingLookups) && <IdsText size="md">Loading...</IdsText>}
                {isCostError && <IdsText size="md">Error loading Measure Cost</IdsText>}
                {!isLoadingCost && !isLoadingSectors && !isLoadingVintages && !isLoadingLookups && !isCostError && model && (
                    <>
                        <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} />
                        <IdsFieldWrapper htmlFor="cost-sector" wrapperLabel="Sector" isRequired>
                            <IdsDropdown
                                idValue="cost-sector"
                                isSearchable
                                items={sectorsList}
                                placeholder={isLoadingSectors ? "Loading" : "Type to Search"}
                                initialSelectedItems={model.sectorNumber ? [model.sectorNumber] : []}
                                changeHandler={(value) => setModel({ ...model, sectorNumber: value })}
                                clearHandler={() => setModel({ ...model, sectorNumber: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="cost-vintage" wrapperLabel="Vintage" isRequired>
                            <IdsDropdown
                                idValue="cost-vintage"
                                isSearchable
                                items={vintagesList}
                                placeholder={isLoadingVintages ? "Loading" : "Type to Search"}
                                initialSelectedItems={model.vintageNumber ? [model.vintageNumber] : []}
                                changeHandler={(value) => setModel({ ...model, vintageNumber: value })}
                                clearHandler={() => setModel({ ...model, vintageNumber: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="cost-type" wrapperLabel="Cost Type" isRequired>
                            <IdsDropdown
                                idValue="cost-type"
                                isSearchable
                                items={[
                                    {
                                        label: CostTypes.Incremental,
                                        value: CostTypes.Incremental,
                                    },
                                    {
                                        label: CostTypes.Install,
                                        value: CostTypes.Install,
                                    },
                                    {
                                        label: CostTypes.Baseline,
                                        value: CostTypes.Baseline,
                                    },
                                    {
                                        label: CostTypes.Program,
                                        value: CostTypes.Program,
                                    },
                                    {
                                        label: CostTypes.Unspecified,
                                        value: CostTypes.Unspecified,
                                    },
                                ]}
                                placeholder={isLoadingVintages ? "Loading" : "Type to Search"}
                                initialSelectedItems={model.costType ? [model.costType] : []}
                                changeHandler={(value) => setModel({ ...model, costType: value as CostType })}
                                clearHandler={() => setModel({ ...model, costType: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="cost-radios" wrapperLabel="Assignment Strategy">
                            <IdsRadioButtonGroup horizontalOrientation>
                                <IdsRadioButton
                                    idValue={Strategy.Deemed}
                                    name="cost-radios"
                                    label={Strategy.Deemed}
                                    defaultChecked={model.costStrategy === Strategy.Deemed}
                                    changeHandler={(e: any) => setModel({ ...model, costStrategy: e.target.id, costLookupBy: undefined })}
                                />
                                <IdsRadioButton
                                    idValue={Strategy.Lookup}
                                    name="cost-radios"
                                    label={Strategy.Lookup}
                                    defaultChecked={model.costStrategy === Strategy.Lookup}
                                    changeHandler={(e: any) => setModel({ ...model, costStrategy: e.target.id, costPerUnit: undefined })}
                                />
                                <IdsRadioButton
                                    idValue={Strategy.UserDefined}
                                    name="cost-radios"
                                    label={Strategy.UserDefined}
                                    defaultChecked={model.costStrategy === Strategy.UserDefined}
                                    changeHandler={(e: any) =>
                                        setModel({ ...model, costStrategy: e.target.id, costLookupBy: undefined, costPerUnit: undefined })
                                    }
                                />
                                <IdsRadioButton
                                    idValue={Strategy.Equation}
                                    name="cost-radios"
                                    label={Strategy.Equation}
                                    defaultChecked={model.costStrategy === Strategy.Equation}
                                    changeHandler={(e: any) =>
                                        setModel({ ...model, costStrategy: e.target.id, costLookupBy: undefined, costPerUnit: undefined })
                                    }
                                />
                            </IdsRadioButtonGroup>
                        </IdsFieldWrapper>
                        {model.costStrategy === Strategy.Deemed && (
                            <IdsFieldWrapper htmlFor="cost-per-unit" wrapperLabel="Cost Per Unit" isRequired>
                                <IdsTextInput
                                    innerRef={costPerUnitInputRef}
                                    idValue="cost-per-unit"
                                    defaultValue={String(model.costPerUnit ?? "")}
                                    changeHandler={(value) =>
                                        setModel({
                                            ...model,
                                            costPerUnit: sanitizeAndSetNumericInput(value, costPerUnitInputRef),
                                        })
                                    }
                                />
                            </IdsFieldWrapper>
                        )}
                        {model.costStrategy === Strategy.Lookup && (
                            <IdsFieldWrapper htmlFor="cost-lookup" wrapperLabel="Lookup Table" isRequired>
                                <IdsDropdown
                                    idValue="cost-lookup"
                                    isSearchable
                                    items={lookupsList}
                                    placeholder={isLoadingLookups ? "Loading" : "Type to Search"}
                                    initialSelectedItems={isNil(model.costLookupBy) ? [] : [model.costLookupBy]}
                                    changeHandler={(value) => setModel({ ...model, costLookupBy: value })}
                                    clearHandler={() => setModel({ ...model, costLookupBy: "" })}
                                />
                                <IdsButtonGroup position="justify" customClasses="pt-1">
                                    <IdsButton variant="tertiary" clickHandler={() => setManageLookups({ add: true, view: false })}>
                                        <div className="flex-row gap-2 align-center">
                                            <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                                            Add New
                                        </div>
                                    </IdsButton>
                                    <IdsButton
                                        variant="tertiary"
                                        clickHandler={() => setManageLookups({ add: false, view: true })}
                                        isDisabled={isEmpty(model.costLookupBy)}
                                    >
                                        <div className="flex-row gap-2 align-center">
                                            <FontAwesomeIcon icon={circleInfoRegularIcon} fixedWidth />
                                            View Details
                                        </div>
                                    </IdsButton>
                                </IdsButtonGroup>
                            </IdsFieldWrapper>
                        )}
                        <IdsFieldWrapper htmlFor="cost-units" wrapperLabel="Units">
                            <IdsTextInput
                                idValue="cost-units"
                                defaultValue={model.costUnit}
                                changeHandler={(value) => setModel({ ...model, costUnit: value })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="cost-source" wrapperLabel="Source">
                            <IdsTextInput
                                idValue="cost-source"
                                defaultValue={model.costSource}
                                changeHandler={(value) => setModel({ ...model, costSource: value })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="cost-source-year" wrapperLabel="Year of Source">
                            <IdsTextInput
                                innerRef={sourceYearInputRef}
                                idValue="cost-source-year"
                                defaultValue={String(model.costSourceYear ?? "")}
                                changeHandler={(value) =>
                                    setModel({
                                        ...model,
                                        costSourceYear: sanitizeAndSetNumericInput(value, sourceYearInputRef, {
                                            maxLength: 4,
                                            allowDecimals: false,
                                        }),
                                    })
                                }
                            />
                        </IdsFieldWrapper>
                    </>
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSave}>
                        <>{updateStatus.isLoading || createStatus.isLoading ? "Saving..." : "Save"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

type CostModel = Omit<CostUpdateModel, "costPerUnit" | "costSourceYear"> & {
    costPerUnit?: string;
    costSourceYear?: string;
};
