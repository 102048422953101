const storage = sessionStorage;
const WINDOW_STATE_KEY = "window-state";

export const EMPTY_SPLIT_VIEW_NAME = "SplitView";
export const EMPTY_SPLIT_VIEW_TITLE = " ";

export const windowContainerTypes = {
    Root: "root-window",
    Login: "login-window",
    Home: "home-window",
} as const;

export const clearBrowserUrl = () => {
    window.history.pushState("", "", "/");
};

export const getWindowState = () => {
    const item = storage.getItem(WINDOW_STATE_KEY);

    if (item !== null) {
        return JSON.parse(item);
    } else {
        return {};
    }
};

export const setWindowState = (state: any) => {
    if (state !== null) {
        storage.setItem(WINDOW_STATE_KEY, JSON.stringify(state));
    }
};

export const deleteWindowState = () => {
    storage.removeItem(WINDOW_STATE_KEY);
};
