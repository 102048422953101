import { isEmpty, map } from "lodash";
import { useCallback, useState } from "react";

import {
    IdsText,
    IdsButtonGroup,
    IdsButton,
    IdsRadioButtonGroup,
    IdsRadioButton,
    IdsFieldWrapper,
    IdsDropdown,
    IdsTextInput,
    IdsLabel,
    IdsHelper,
} from "@emergn-infinity/ids-react";

import { AccessLevels, LinkFormats } from "utils/constants";

export const LinkEditCard: React.FC<{
    linkNumber?: string;
    availability?: string;
    format?: string;
    linkName?: string;
    linkDescription?: string;
    urlLink?: string;
    isLoading?: boolean;
    detailed?: boolean;
    onSave: (
        linkName: string,
        urlLink: string,
        availability?: string,
        format?: string,
        linkDescription?: string,
        linkNumber?: string,
    ) => void;
    onCancel: () => void;
}> = (props) => {
    const [availability, setAvailability] = useState<string>(props.availability ?? AccessLevels.Public);
    const [format, setFormat] = useState<string>(props.format ?? LinkFormats.PDF);
    const [linkName, setLinkName] = useState(props.linkName ?? "");
    const [linkDescription, setLinkDescription] = useState(props.linkDescription ?? "");
    const [urlLink, setUrlLink] = useState(props.urlLink ?? "");
    const [errors, setErrors] = useState<LinkErrors>({});

    const onChange = (value: string, name: string) => {
        switch (name) {
            case "availability":
                setAvailability(value);

                break;

            case "format":
                setFormat(value);

                break;

            case "linkName":
                setLinkName(value);

                break;

            case "linkDescription":
                setLinkDescription(value);

                break;

            case "urlLink":
                setUrlLink(value);

                break;
        }

        setErrors({
            ...errors,
            [name]: undefined,
        });
    };

    const onClearAll = useCallback(() => {
        setFormat(LinkFormats.PDF);
        setLinkName("");
        setLinkDescription("");
        setUrlLink("");
    }, []);

    const onPaste = useCallback(async () => {
        const url = await navigator.clipboard.readText();

        setUrlLink(url);
    }, []);

    const onCancelClick = () => {
        setErrors({});

        props.onCancel();
    };

    const onSaveClick = () => {
        if (isEmpty(linkName) || isEmpty(urlLink)) {
            setErrors({
                linkName: isEmpty(linkName) ? "Name is required" : undefined,
                urlLink: isEmpty(urlLink) ? "URL is required" : undefined,
            });

            return;
        }

        props.onSave(linkName, urlLink, availability, format, linkDescription, props.linkNumber);
    };

    return (
        <div className="flex-column border rounded-edges-rounder p-3">
            <div className="flex-row align-center justify-space-between pb-2">
                <IdsText weight="bold">New link</IdsText>
                <IdsButton variant="tertiary" clickHandler={onClearAll}>
                    Clear All
                </IdsButton>
            </div>
            <div className="flex-column pb-4">
                {props.detailed && (
                    <>
                        <IdsRadioButtonGroup customClasses="pt-1 pb-3" horizontalOrientation>
                            <IdsRadioButton
                                idValue={AccessLevels.Public}
                                name="availability"
                                label={AccessLevels.Public}
                                defaultChecked={availability === AccessLevels.Public}
                                changeHandler={(e: any) => onChange(e.target.id, "availability")}
                            />
                            <IdsRadioButton
                                idValue={AccessLevels.Confidential}
                                name="availability"
                                label={AccessLevels.Confidential}
                                defaultChecked={availability === AccessLevels.Confidential}
                                changeHandler={(e: any) => onChange(e.target.id, "availability")}
                            />
                        </IdsRadioButtonGroup>
                        <IdsFieldWrapper htmlFor="format" wrapperLabel="Format" isRequired>
                            <IdsDropdown
                                idValue="format"
                                items={map(LinkFormats, (format) => ({ value: format, label: format }))}
                                initialSelectedItems={[format]}
                                changeHandler={(value) => onChange(value, "format")}
                            />
                        </IdsFieldWrapper>
                    </>
                )}
                <IdsFieldWrapper
                    customClasses="fill-width"
                    htmlFor="name"
                    wrapperLabel="Name"
                    isInvalid={!isEmpty(errors.linkName)}
                    helperInvalidText={errors.linkName}
                    isRequired
                >
                    <IdsTextInput idValue="name" defaultValue={linkName} changeHandler={(value) => onChange(value, "linkName")} />
                </IdsFieldWrapper>
                {props.detailed && (
                    <IdsFieldWrapper customClasses="fill-width" htmlFor="description" wrapperLabel="Description">
                        <IdsTextInput
                            idValue="description"
                            defaultValue={linkDescription}
                            changeHandler={(value) => onChange(value, "linkDescription")}
                        />
                    </IdsFieldWrapper>
                )}
                <div className="flex-row align-center justify-space-between pb-2">
                    <IdsLabel htmlFor="url" wrapperLabel="URL" isRequired />
                    <IdsButton variant="tertiary" padding="xs" clickHandler={onPaste}>
                        Paste from clipboard
                    </IdsButton>
                </div>
                <IdsTextInput
                    idValue="url"
                    defaultValue={urlLink}
                    isInvalid={!isEmpty(errors.urlLink)}
                    changeHandler={(value) => onChange(value, "urlLink")}
                />
                <IdsHelper customClasses="pt-1" isInvalid={!isEmpty(errors.urlLink)} helperInvalidText={errors.urlLink} />
            </div>
            <IdsButtonGroup spaceBetween="lg">
                <IdsButton variant="secondary" clickHandler={onCancelClick}>
                    Cancel
                </IdsButton>
                <IdsButton clickHandler={onSaveClick}>
                    <>{props.isLoading ? "Saving..." : "Save Link"}</>
                </IdsButton>
            </IdsButtonGroup>
        </div>
    );
};

export type LinkErrors = {
    linkName?: string;
    urlLink?: string;
};
