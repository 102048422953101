import cn from "classnames";

import { IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "store/hooks";

import { useClickAway } from "utils/useClickAway";

import { setIsOpen } from "./menuSlice";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { IdsButtonColor, IdsButtonPadding, IdsButtonVariant } from "types";

import "./style.scss";

export const Menu: React.FC<{
    id: string;
    buttonLabel: string;
    buttonIconBefore?: IconProp;
    buttonIconAfter?: IconProp;
    buttonVariant?: IdsButtonVariant;
    buttonColor?: IdsButtonColor;
    buttonPadding?: IdsButtonPadding;
    buttonIsDisabled?: boolean;
    buttonFullWidth?: boolean;
    actionLabel?: string;
    actionIsDisabled?: boolean;
    width?: number;
    isSubMenu?: boolean;
    children: React.ReactNode;
    onAction?: () => void;
    onClose?: () => void;
}> = ({
    id,
    buttonLabel,
    buttonIconBefore,
    buttonIconAfter,
    buttonVariant = "primary",
    buttonColor = "brand",
    buttonPadding = "md",
    buttonIsDisabled,
    buttonFullWidth,
    actionLabel,
    actionIsDisabled,
    width,
    isSubMenu,
    children,
    onAction,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const menuState = useAppSelector((state) => state.menu[id]);
    const isMainMenuOpen = menuState?.isMainMenuOpen;
    const isSubMenuOpen = menuState?.isSubMenuOpen;

    const isOpen = Boolean((!isSubMenu && isMainMenuOpen) || (isSubMenu && isSubMenuOpen));
    const renderButtons = actionLabel !== undefined;

    const onCancelClick = () => {
        dispatch(setIsOpen({ id, isMainMenuOpen: false, isSubMenuOpen: false }));

        onClose?.();
    };

    const menuRef = useClickAway<HTMLDivElement>(onCancelClick);

    const onButtonClick = () => {
        if (isSubMenu) {
            dispatch(setIsOpen({ id, isMainMenuOpen: true, isSubMenuOpen: !isSubMenuOpen }));
        } else {
            dispatch(setIsOpen({ id, isMainMenuOpen: !isMainMenuOpen, isSubMenuOpen: false }));
        }

        if (isOpen) {
            onClose?.();
        }
    };

    const onActionClick = () => {
        dispatch(setIsOpen({ id, isMainMenuOpen: false, isSubMenuOpen: false }));

        onAction?.();
    };

    return (
        <div ref={!isSubMenu ? menuRef : undefined} className="menu">
            <IdsButton
                customClasses={cn("menu--activator", {
                    "menu--activator--open": isOpen,
                })}
                variant={buttonVariant}
                color={buttonColor}
                padding={buttonPadding}
                isDisabled={buttonIsDisabled}
                fullWidth={buttonFullWidth}
                clickHandler={onButtonClick}
            >
                {buttonIconBefore || buttonIconAfter ? (
                    <span className="flex-row align-center gap-2">
                        {buttonIconBefore && <FontAwesomeIcon icon={buttonIconBefore} fixedWidth />}
                        {buttonLabel}
                        {buttonIconAfter && <FontAwesomeIcon icon={buttonIconAfter} fixedWidth />}
                    </span>
                ) : (
                    buttonLabel
                )}
            </IdsButton>
            {isOpen && (
                <div className="rounded-edges-rounder menu--list-container" style={{ width }}>
                    <div
                        className={cn("pt-3 pr-3 pl-3", {
                            "pb-3": renderButtons,
                        })}
                        style={{ borderBottom: renderButtons ? "2px solid var(--theme-base-border)" : undefined }}
                    >
                        {children}
                    </div>
                    {renderButtons && (
                        <IdsButtonGroup customClasses="p-3" spaceBetween="md">
                            <IdsButton padding="sm" isDisabled={actionIsDisabled} clickHandler={onActionClick}>
                                {actionLabel}
                            </IdsButton>
                            <IdsButton variant="secondary" padding="sm" clickHandler={onCancelClick}>
                                Cancel
                            </IdsButton>
                        </IdsButtonGroup>
                    )}
                </div>
            )}
        </div>
    );
};
