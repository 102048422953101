import { isEmpty, map } from "lodash";
import { useMemo, useRef, useState } from "react";
import cn from "classnames";

import { useAppSelector } from "store/hooks";

import { IdsTabItem, IdsTabs } from "@emergn-infinity/ids-react";

import { TourCard } from "components/TourCard";

import { ExploreTrms } from "pages/ExploreTrms";
import { AnalyzeMeasures } from "pages/AnalyzeMeasures";
import { BenchmarkTrm } from "pages/BenchmarkTrm";
import { MeasureCharacterization } from "pages/MeasureCharacterization";
import { StandardizeData } from "pages/StandardizeData";

import { Tabs } from "./utils";
import { hasRights } from "utils/user";

import type { IndexSignature, Writeable } from "types";

import "./style.scss";

const initialTabState = sessionStorage.getItem("active-tab");

/**
 * Landing page.
 *
 * The first view user sees upon logging in.
 */
const Home = () => {
    const user = useAppSelector((state) => state.user);
    const tour = useAppSelector((state) => state.tour);

    const navigationRef = useRef<HTMLIdsTabsElement>(null);
    const navigationItemsRef = useRef<IndexSignature<number>>({});

    const welcomePage = tour.page === "Welcome";

    const tabs = useMemo(() => {
        const tabs: IndexSignature<Tab> = {};

        if (user) {
            Object.keys(Tabs).forEach((key) => {
                const tab: Writeable<Tab> = { ...Tabs[key] };

                // Hide tab if user does not have all of the
                // necessary rights to access that tab
                if (!isEmpty(tab.rights) && !tab.rights.every((code) => hasRights(code))) {
                    tab.hidden = true;
                }

                if (!tab.hidden) {
                    tabs[key] = tab;
                }
            });
        }

        return tabs;
    }, [user]);

    const [activeTab, setActiveTab] = useState<string>(initialTabState ?? tabs?.ExploreTrms.id ?? "");

    const onTabChange = (tabId: string) => {
        setActiveTab(tabId);
        sessionStorage.setItem("active-tab", tabId);
    };

    return (
        <div className="home-page flex-column fill-height" style={{ position: "relative" }}>
            <IdsTabs ref={navigationRef} customClasses="main-navigation min-h-0 no-shrink bg-white px-5">
                {map(tabs, (tab, key) => (
                    <IdsTabItem
                        ref={(el) => (navigationItemsRef.current[key] = el?.offsetLeft || 34)}
                        key={`tab-header-${tab.id}`}
                        style={{ zIndex: tour.page === key ? 13 : 0 }}
                        slot="header"
                        idValue={tab.id}
                        label={tab.name}
                        isActive={tab.id === activeTab}
                        onClick={() => onTabChange(tab.id)}
                    />
                ))}
            </IdsTabs>
            {tour.enabled && (
                <div
                    className={cn("flex-column", {
                        "align-center justify-center fill-width fill-height": welcomePage,
                    })}
                    style={{
                        position: "absolute",
                        top: welcomePage ? "unset" : (navigationRef?.current?.clientHeight ?? 0),
                        left: welcomePage ? "unset" : navigationItemsRef.current[tour.page],
                    }}
                >
                    <TourCard page={tour.page} variant={welcomePage ? "primary" : "secondary"} />
                </div>
            )}
            <div className="flex-one-in-column">
                {/* Not using tab panels because nested tab lists not supported */}
                {activeTab === Tabs.ExploreTrms.id && <ExploreTrms />}
                {activeTab === Tabs.AnalyzeMeasures.id && <AnalyzeMeasures />}
                {activeTab === Tabs.BenchmarkTrm.id && <BenchmarkTrm />}
                {activeTab === Tabs.MeasureCharacterization.id && <MeasureCharacterization />}
                {activeTab === Tabs.StandardizeData.id && <StandardizeData />}
            </div>
        </div>
    );
};

type Tab = (typeof Tabs)[keyof typeof Tabs];

export default Home;
