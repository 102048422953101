import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

import { IdsButton } from "@emergn-infinity/ids-react";

import type { IdsButtonPadding } from "types";

import "./style.scss";

// Currently supported sizes are - default, lg, xl
export const IconButton: React.FC<{
    icon: IconProp;
    color?: string;
    size?: SizeProp;
    square?: boolean;
    padding?: IdsButtonPadding;
    title?: string;
    isDisabled?: boolean;
    badgeNumber?: number;
    badgeVariant?: "critical" | "success";
    onClick?: () => void;
}> = ({ icon, color, size, square, padding, title, isDisabled, badgeNumber, badgeVariant, onClick }) => {
    let badgeContent: string | null = null;
    let sizeClassName = "";
    let paddingClassName = "";

    if (badgeNumber !== undefined) {
        badgeContent = badgeNumber < 10 ? String(badgeNumber) : "+9";
    }
    if (size) {
        sizeClassName = `size-${size}`;
    }
    if (padding) {
        paddingClassName = `padding-${padding}`;
    }

    return (
        <div className="icon-button">
            <IdsButton
                customClasses={cn(sizeClassName, paddingClassName, {
                    "square-button": square,
                })}
                variant="tertiary"
                padding={padding}
                title={title ?? ""}
                isDisabled={isDisabled}
                clickHandler={onClick}
            >
                <FontAwesomeIcon icon={icon} color={color} size={size} fixedWidth />
            </IdsButton>
            {(badgeNumber || badgeVariant) && <div className={cn("text-center icon-button--badge", badgeVariant)}>{badgeContent}</div>}
        </div>
    );
};
