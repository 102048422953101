import { isEmpty } from "lodash";
import { ReactNode, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    useCreateAlgorithmMutation,
    useGetAlgorithmQuery,
    useGetEndUsesQuery,
    useGetFuelConversionsQuery,
    useGetFuelTypesQuery,
    useGetSectorsQuery,
    useGetVintagesQuery,
    useUpdateAlgorithmMutation,
} from "store/apiSlice";

import {
    IdsButton,
    IdsButtonGroup,
    IdsDivider,
    IdsDropdown,
    IdsFieldWrapper,
    IdsIcon,
    IdsModal,
    IdsRadioButton,
    IdsRadioButtonGroup,
    IdsTagGroup,
    IdsText,
    IdsTextarea,
} from "@emergn-infinity/ids-react";

import { EditFormTags } from "components/EditFormTags";

import { validateAlgorithm, AlgorithmErrors } from "pages/ExploreTrms/utils";

import { AlgorithmStrategy as Strategy } from "utils/constants";
import { arrowRightSolidIcon } from "utils/icons";
import { sanitizeAlgorithmInput } from "utils/string";
import { isAdminUser } from "utils/user";

import { AlgorithmUpdateModel, Measure, MeasureAlgorithm } from "types";

const ALGORITHM_MODAL_STYLE = {
    width: "948px",
    maxWidth: "100%",
};

export const AlgorithmModal: React.FC<{
    title: string;
    algorithmNumber?: string;
    algorithm?: string;
    measure: Measure;
    duplicate?: boolean;
    onAdded: (algorithm: MeasureAlgorithm) => void;
    onClose: () => void;
}> = ({ title, algorithmNumber, algorithm, measure, duplicate, onAdded, onClose }) => {
    const algorithmRef = useRef<HTMLInputElement>(null);
    const [model, setModel] = useState<AlgorithmUpdateModel>();
    const [errors, setErrors] = useState<AlgorithmFormErrors>({});

    const isEditing = !isEmpty(algorithmNumber);

    const {
        data: algorithmDetails,
        isLoading,
        isError,
    } = useGetAlgorithmQuery({ algorithmNumber: algorithmNumber! }, { skip: !algorithmNumber });
    const isLoadingAlgorithm = isLoading && isEditing;

    const { data: sectors, isLoading: isLoadingSectors } = useGetSectorsQuery({ trmNumber: measure.trmNumber, isVerified: true });
    const sectorsList = useMemo(
        () =>
            sectors?.map((sector) => ({
                label: sector.sectorName,
                value: sector.sectorNumber,
            })) ?? [],
        [sectors],
    );

    const { data: vintages, isLoading: isLoadingVintages } = useGetVintagesQuery({ trmNumber: measure.trmNumber, vintageVerified: true });
    const vintagesList = useMemo(
        () =>
            vintages?.map((vintage) => ({
                label: vintage.vintageName,
                value: vintage.vintageNumber,
            })) ?? [],
        [vintages],
    );

    const { data: endUses, isLoading: isLoadingEndUses } = useGetEndUsesQuery({ trmNumber: measure.trmNumber, endUseVerified: true });
    const endUsesList = useMemo(
        () =>
            endUses?.map((endUse) => ({
                label: endUse.endUseName,
                value: endUse.endUseNumber,
            })) ?? [],
        [endUses],
    );

    const { data: fuelTypes, isLoading: isLoadingFuelTypes } = useGetFuelTypesQuery();
    const fuelTypesList = useMemo(
        () =>
            fuelTypes?.map((fuelType) => ({
                label: fuelType.fuelType,
                value: fuelType.fuelTypeNumber,
            })) ?? [],
        [fuelTypes],
    );

    const { data: fuelConversions, isLoading: isLoadingFuelConversions } = useGetFuelConversionsQuery(
        {
            fuelTypeNumber: model?.fuelTypeNumber,
        },
        { skip: isEmpty(model?.fuelTypeNumber) },
    );
    const fuelConversionsList = useMemo(
        () =>
            fuelConversions?.map((fuelConversion) => ({
                label: fuelConversion.unit,
                value: fuelConversion.fuelConvertNumber,
            })) ?? [],
        [fuelConversions],
    );

    const isLoadingResources = isLoadingAlgorithm || isLoadingSectors || isLoadingVintages || isLoadingEndUses;
    const isAlgorithmInvalid = !isEmpty(errors?.duplicate) || !isEmpty(errors?.algorithm);
    const algorithmInvalidText = errors?.duplicate ?? errors?.algorithm;

    if (isEmpty(model)) {
        if (isEditing && !isEmpty(algorithmDetails)) {
            setModel(algorithmDetails);
        }

        if (!isEditing) {
            setModel({
                algorithmStrategy: Strategy.Deemed,
                measureNumber: measure.measureNumber,
                algVerified: false,
            });
        }
    }

    const onFormChange = (value: AlgorithmUpdateModel) => {
        setModel({ ...model, ...value });

        const algorithmErrors = validateAlgorithmForm(value);
        setErrors(algorithmErrors);
    };

    const [create, createStatus] = useCreateAlgorithmMutation();
    const [update, updateStatus] = useUpdateAlgorithmMutation();

    const onAddAlgorithm = async () => {
        // Do nothing if saving is in progress
        if (createStatus.isLoading) {
            return;
        }

        if (model) {
            // validate algorithm
            const validationErrors = validateAlgorithmForm(model);
            setErrors(validationErrors);
            if (!isEmpty(validationErrors)) {
                return;
            }

            try {
                const result = await create({ algorithm: model }).unwrap();
                onAdded({
                    id: 0,
                    algorithmNumber: result.algorithmNumber,
                    algorithm: `${fuelConversions?.find((f) => f.fuelConvertNumber === model.fuelConvertNumber)?.unit} = ${
                        model.algorithm
                    }`,
                    sector: sectors?.find((s) => s.sectorNumber === model.sectorNumber)?.sectorName ?? "",
                    vintage: vintages?.find((v) => v.vintageNumber === model.vintageNumber)?.vintageName ?? "",
                    endUse: endUses?.find((e) => e.endUseNumber === model.endUseNumber)?.endUseName ?? "",
                    strategy: model.algorithmStrategy,
                    fuelConvertUnit: fuelConversions?.find((f) => f.fuelConvertNumber === model.fuelConvertNumber)?.unit ?? "",
                    fuelType: fuelTypes?.find((f) => f.fuelTypeNumber === model.fuelTypeNumber)?.fuelType ?? "",
                    permutationsCount: 0,
                    offTrmAdjustments: null,
                    trmAlgorithm: null,
                    notes: null,
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onUpdateAlgorithm = async () => {
        // Do nothing if saving is in progress
        if (updateStatus.isLoading) {
            return;
        }

        if (model && algorithmNumber) {
            // validate algorithm
            const validationErrors = validateAlgorithmForm(model);
            setErrors(validationErrors);
            if (!isEmpty(validationErrors)) {
                return;
            }

            try {
                await update({ algorithmNumber, algorithm: model }).unwrap();
                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={ALGORITHM_MODAL_STYLE}>
                {isLoadingResources && <IdsText size="md">Loading...</IdsText>}
                {isError && <IdsText size="md">Error loading Algorithm</IdsText>}
                {!isLoadingResources && !isError && model && (
                    <>
                        <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} algorithm={algorithm} />
                        <div className="flex-row gap-4">
                            <IdsFieldWrapper
                                customClasses="fill-width"
                                htmlFor="algorithm-sector"
                                wrapperLabel="Sector"
                                isRequired
                                isInvalid={!isEmpty(errors?.sectorNumber)}
                                helperInvalidText={errors?.sectorNumber}
                            >
                                <IdsDropdown
                                    idValue="algorithm-sector"
                                    isSearchable
                                    items={sectorsList}
                                    placeholder={isLoadingSectors ? "Loading" : "Type to Search"}
                                    initialSelectedItems={model.sectorNumber ? [model.sectorNumber] : []}
                                    changeHandler={(value) => onFormChange({ ...model, sectorNumber: value })}
                                    clearHandler={() => onFormChange({ ...model, sectorNumber: undefined })}
                                />
                            </IdsFieldWrapper>
                            <IdsFieldWrapper
                                customClasses="fill-width"
                                htmlFor="algorithm-enduse"
                                wrapperLabel="End Use"
                                isRequired
                                isInvalid={!isEmpty(errors?.endUseNumber)}
                                helperInvalidText={errors?.endUseNumber}
                            >
                                <IdsDropdown
                                    idValue="algorithm-enduse"
                                    isSearchable
                                    items={endUsesList}
                                    placeholder={isLoadingEndUses ? "Loading" : "Type to Search"}
                                    initialSelectedItems={model.endUseNumber ? [model.endUseNumber] : []}
                                    changeHandler={(value) => onFormChange({ ...model, endUseNumber: value })}
                                    clearHandler={() => onFormChange({ ...model, endUseNumber: undefined })}
                                />
                            </IdsFieldWrapper>
                            <IdsFieldWrapper
                                customClasses="fill-width"
                                htmlFor="algorithm-vintage"
                                wrapperLabel="Vintage"
                                isRequired
                                isInvalid={!isEmpty(errors?.vintageNumber)}
                                helperInvalidText={errors?.vintageNumber}
                            >
                                <IdsDropdown
                                    idValue="algorithm-vintage"
                                    isSearchable
                                    items={vintagesList}
                                    placeholder={isLoadingVintages ? "Loading" : "Type to Search"}
                                    initialSelectedItems={model.vintageNumber ? [model.vintageNumber] : []}
                                    changeHandler={(value) => onFormChange({ ...model, vintageNumber: value })}
                                    clearHandler={() => onFormChange({ ...model, vintageNumber: undefined })}
                                />
                            </IdsFieldWrapper>
                        </div>
                        <div className="flex-row gap-4">
                            <IdsFieldWrapper
                                key={`algorithm-fuel-type-${isLoadingFuelTypes}`}
                                customClasses="fill-width"
                                htmlFor="algorithm-fuel-type"
                                wrapperLabel="Fuel Type"
                                isRequired
                                isInvalid={!isEmpty(errors?.fuelTypeNumber)}
                                helperInvalidText={errors?.fuelTypeNumber}
                            >
                                <IdsDropdown
                                    idValue="algorithm-fuel-type"
                                    isSearchable
                                    items={fuelTypesList}
                                    placeholder={isLoadingFuelTypes ? "Loading" : "Type to Search"}
                                    initialSelectedItems={model.fuelTypeNumber ? [model.fuelTypeNumber] : []}
                                    changeHandler={(value) =>
                                        onFormChange({ ...model, fuelTypeNumber: value, fuelConvertNumber: undefined })
                                    }
                                    clearHandler={() => onFormChange({ ...model, fuelTypeNumber: undefined, fuelConvertNumber: undefined })}
                                />
                            </IdsFieldWrapper>
                            <IdsFieldWrapper
                                key={`algorithm-units-${model.fuelTypeNumber}-${isLoadingFuelConversions}`}
                                customClasses="fill-width"
                                htmlFor="algorithm-units"
                                wrapperLabel="Savings Units"
                                isRequired
                                isDisabled={isEmpty(model.fuelTypeNumber)}
                                isInvalid={!isEmpty(errors?.fuelConvertNumber)}
                                helperInvalidText={errors?.fuelConvertNumber}
                            >
                                <IdsDropdown
                                    idValue="algorithm-units"
                                    isSearchable
                                    items={fuelConversionsList}
                                    placeholder={isLoadingFuelConversions ? "Loading" : "Type to Search"}
                                    initialSelectedItems={model.fuelConvertNumber ? [model.fuelConvertNumber] : []}
                                    changeHandler={(value) => onFormChange({ ...model, fuelConvertNumber: value })}
                                    clearHandler={() => onFormChange({ ...model, fuelConvertNumber: undefined })}
                                />
                            </IdsFieldWrapper>
                        </div>
                        {isEditing && isAdminUser() && (
                            <IdsFieldWrapper htmlFor="algorithm-strategy" wrapperLabel="Strategy" isRequired>
                                <IdsRadioButtonGroup horizontalOrientation>
                                    <IdsRadioButton
                                        idValue={Strategy.Deemed}
                                        name="algorithm-strategy"
                                        label={Strategy.Deemed}
                                        defaultChecked={model.algorithmStrategy === Strategy.Deemed}
                                        changeHandler={(e: any) => onFormChange({ ...model, algorithmStrategy: e.target.id })}
                                    />
                                    <IdsRadioButton
                                        idValue={Strategy.PartiallyDeemed}
                                        name="algorithm-strategy"
                                        label={Strategy.PartiallyDeemed}
                                        defaultChecked={model.algorithmStrategy === Strategy.PartiallyDeemed}
                                        changeHandler={(e: any) => onFormChange({ ...model, algorithmStrategy: e.target.id })}
                                    />
                                    <IdsRadioButton
                                        idValue={Strategy.Calculated}
                                        name="algorithm-strategy"
                                        label={Strategy.Calculated}
                                        defaultChecked={model.algorithmStrategy === Strategy.Calculated}
                                        changeHandler={(e: any) => onFormChange({ ...model, algorithmStrategy: e.target.id })}
                                    />
                                </IdsRadioButtonGroup>
                            </IdsFieldWrapper>
                        )}
                        <IdsFieldWrapper
                            htmlFor="algorithm"
                            wrapperLabel="Algorithm"
                            isRequired
                            isInvalid={isAlgorithmInvalid}
                            helperInvalidText={algorithmInvalidText}
                            isValid={!isEmpty(model.algorithm) && isEmpty(errors?.algorithm)}
                            helperValidText="Algorithm is valid"
                        >
                            <IdsTextarea
                                innerRef={algorithmRef}
                                idValue="algorithm"
                                defaultValue={model.algorithm}
                                changeHandler={(value) =>
                                    onFormChange({ ...model, algorithm: sanitizeAlgorithmInput(value, algorithmRef) })
                                }
                            />
                        </IdsFieldWrapper>
                        <IdsDivider spacing="md" />
                        <IdsText size="xs" component="h3" customClasses="pt-2">
                            How to format your equation:
                        </IdsText>
                        <IdsTagGroup customClasses="flex-column align-start pt-3">
                            <ValidationText error={errors.brackets}>
                                Wrap all variables to be replaced with values in angled brackets {"<>"}
                            </ValidationText>
                            <ValidationText error={errors.underscore}>
                                Use a single underscore before a subscript (e.g Watts_base)
                            </ValidationText>
                            <ValidationText error={errors.multiplication}>
                                Denote multiplication with * (not x), and division with / (not +)
                            </ValidationText>
                            <ValidationText error={errors.comma}>
                                For hardcoded numbers, do not include a comma (e.g. 1000 instead of 1,000)
                            </ValidationText>
                            <ValidationText error={errors.duplicate}>
                                Variable names are case sensitive, use the same text case if you want to use the variable more than once
                            </ValidationText>
                        </IdsTagGroup>
                    </>
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    {!isEditing || duplicate ? (
                        <IdsButton variant="primary" clickHandler={onAddAlgorithm}>
                            <div className="flex-row gap-2 align-center">
                                Next
                                <FontAwesomeIcon icon={arrowRightSolidIcon} fixedWidth />
                            </div>
                        </IdsButton>
                    ) : (
                        <IdsButton variant="primary" clickHandler={onUpdateAlgorithm}>
                            <>{updateStatus.isLoading ? "Saving..." : "Save"}</>
                        </IdsButton>
                    )}
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

const ValidationText = ({ error, children }: { error?: string; children?: ReactNode }) => {
    const icon = !isEmpty(error) ? "ui-form-error_circle" : "ui-form-check_circle";
    const iconStyle = {
        fill: !isEmpty(error) ? "var(--ids-semantic-ink-color-critical-subtle)" : "var(--ids-semantic-ink-color-success-subtle)",
    };
    const textStyle = {
        color: !isEmpty(error) ? "var(--ids-semantic-ink-color-critical-subtle)" : "var(--ids-semantic-ink-color-success-subtle)",
    };

    return (
        <div className="flex-row gap-2 align-center">
            <IdsIcon icon={icon} style={iconStyle} />
            <IdsText style={textStyle}>{children}</IdsText>
        </div>
    );
};

const validateAlgorithmForm = (model: AlgorithmUpdateModel) => {
    const errors: AlgorithmFormErrors = {};

    if (isEmpty(model.sectorNumber)) {
        errors.sectorNumber = "Sector is required";
    }

    if (isEmpty(model.vintageNumber)) {
        errors.vintageNumber = "Vintage is required";
    }

    if (isEmpty(model.endUseNumber)) {
        errors.endUseNumber = "End Use is required";
    }

    if (isEmpty(model.fuelTypeNumber)) {
        errors.fuelTypeNumber = "Fuel Type is required";
    }

    if (!isEmpty(model.fuelTypeNumber) && isEmpty(model.fuelConvertNumber)) {
        errors.fuelConvertNumber = "Savings Units is required";
    }

    if (isEmpty(model.algorithm)) {
        errors.algorithm = "Algorithm is required";
    } else {
        const validationResult = validateAlgorithm(model.algorithm);
        if (!isEmpty(validationResult)) {
            Object.assign(errors, validationResult);

            // Add error if the algorithm is invalid but there is no generic message
            if (isEmpty(errors.algorithm)) {
                errors.algorithm = "Algorithm is invalid";
            }
        }
    }

    return errors;
};

type AlgorithmFormErrors = {
    [K in keyof (Omit<AlgorithmUpdateModel, "algorithmStrategy"> & AlgorithmErrors)]?: string;
};
