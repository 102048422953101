import { IdsFieldWrapper, IdsTextInput } from "@emergn-infinity/ids-react";

import type { PublisherDetails, TrmDetails } from "types";

export const TrmInformation: React.FC<{
    trmDetails: TrmDetails;
    publisherDetails: PublisherDetails;
}> = ({ trmDetails, publisherDetails }) => {
    return (
        <>
            <IdsFieldWrapper htmlFor="publisher-name" wrapperLabel="Publisher Name" isRequired>
                <IdsTextInput idValue="publisher-name" defaultValue={publisherDetails.publisherName} />
            </IdsFieldWrapper>
            <IdsFieldWrapper htmlFor="publisher-link" wrapperLabel="Publisher Link">
                <IdsTextInput idValue="publisher-link" defaultValue={publisherDetails.publisherURL} />
            </IdsFieldWrapper>
            <IdsFieldWrapper htmlFor="authored-by" wrapperLabel="Authored By">
                <IdsTextInput idValue="authored-by" defaultValue={trmDetails.preparedBy} />
            </IdsFieldWrapper>
            <div className="flex-row gap-4">
                <IdsFieldWrapper htmlFor="applicable-year" wrapperLabel="Applicable Year">
                    <IdsTextInput idValue="applicable-year" defaultValue={`${trmDetails.applicableYear ?? ""}`} />
                </IdsFieldWrapper>
                <IdsFieldWrapper htmlFor="year-authored" wrapperLabel="Year Authored">
                    <IdsTextInput idValue="year-authored" defaultValue={`${trmDetails.yearPrepared ?? ""}`} />
                </IdsFieldWrapper>
            </div>
            <IdsFieldWrapper htmlFor="summer-def" wrapperLabel="Summer Peak Definition">
                <IdsTextInput idValue="summer-def" defaultValue={trmDetails.defSummerPeak} />
            </IdsFieldWrapper>
            <IdsFieldWrapper htmlFor="winter-def" wrapperLabel="Winter Peak Definition">
                <IdsTextInput idValue="winter-def" defaultValue={trmDetails.defWinterPeak} />
            </IdsFieldWrapper>
        </>
    );
};
