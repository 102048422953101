import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

const checkPeriod = 100;
const checkDeadline = 1000; // 1 second

/**
 * Hook that determines whether or not
 * input fields have been autofilled.
 * This hook is a fix for WebKit browsers.
 *
 * WebKit browser input fields after autofill
 * are empty until user interaction with
 * the page and it is implemented on their
 * side as intended.
 *
 * More details: https://bugs.chromium.org/p/chromium/issues/detail?id=669724
 */
export const useAutoFillDetect = (elementIds: string[]): boolean => {
    const [isAutoFilled, setIsAutoFilled] = useState(false);

    useEffect(() => {
        let checkTime = checkPeriod;

        // Check every 100 ms
        const interval = setInterval(() => {
            // Stop interval if check time reaches one second
            if (checkTime === checkDeadline) {
                clearInterval(interval);
            }

            // If all input fields have "webkit-autofill" psuedo class
            // that means that fields have been autofilled
            if (elementIds.every((id) => !isEmpty(document.querySelector(`input#${id}:-webkit-autofill`)))) {
                setIsAutoFilled(true);
                clearInterval(interval);
            }

            checkTime += checkPeriod;
        }, checkPeriod);
    }, [elementIds]);

    return isAutoFilled;
};
