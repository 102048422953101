import { IdsTable, IdsTableRow, IdsTableCell, IdsCheckbox, IdsText } from "@emergn-infinity/ids-react";

import { formatVariableText } from "utils/string";

import { AssumptionListItem, AssumptionStandardizingListItem } from "types";

export const AssumptionsTable: React.FC<{
    activeAssumption?: AssumptionStandardizingListItem | null;
    selectedAssumptions?: AssumptionStandardizingListItem[];
    standardizingList: AssumptionStandardizingListItem[] | AssumptionListItem[];
    isInteractable?: boolean;
    onClick?: (assumption: AssumptionStandardizingListItem) => void;
    onChange?: (assumption: AssumptionStandardizingListItem) => void;
}> = ({ activeAssumption, selectedAssumptions, standardizingList, isInteractable, onClick, onChange }) => {
    return (
        <IdsTable spacing="lg">
            {standardizingList.map((assumption: AssumptionStandardizingListItem | AssumptionListItem) => (
                <IdsTableRow key={`${assumption.assumptionNumber}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                activeAssumption?.assumptionNumber === assumption.assumptionNumber
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                            pointerEvents: !isInteractable ? "none" : undefined,
                        }}
                        onClick={onClick ? () => onClick(assumption as AssumptionStandardizingListItem) : undefined}
                    >
                        <div className="flex-row align-center">
                            {selectedAssumptions && onChange && (
                                <div className="pr-4">
                                    <IdsCheckbox
                                        idValue={assumption.assumptionNumber}
                                        defaultChecked={
                                            selectedAssumptions.find((a) => a.assumptionNumber === assumption.assumptionNumber) !==
                                            undefined
                                        }
                                        changeHandler={() => onChange(assumption as AssumptionStandardizingListItem)}
                                    />
                                </div>
                            )}
                            <div>
                                <IdsText weight="bold">{`${assumption.trmFamiliarName} > ${assumption.measureName}`}</IdsText>
                                <IdsText>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `[${formatVariableText(assumption.variableName)}] ${assumption.variableDesc} ${
                                                (assumption as AssumptionStandardizingListItem).variableAssignedValue ||
                                                assumption.variableUnits
                                                    ? `(${
                                                          (assumption as AssumptionStandardizingListItem).variableAssignedValue ??
                                                          assumption.variableUnits
                                                      })`
                                                    : ""
                                            }`,
                                        }}
                                    />
                                </IdsText>
                                {selectedAssumptions && (
                                    <IdsText>{`Maps to: ${
                                        (assumption as AssumptionStandardizingListItem).stdVariableDesc
                                            ? (assumption as AssumptionStandardizingListItem).stdVariableDesc
                                            : ""
                                    }`}</IdsText>
                                )}
                            </div>
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
