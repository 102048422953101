import { useContext, useMemo } from "react";

import { IdsCheckbox, IdsTableRow, IdsTableCell, IdsText } from "@emergn-infinity/ids-react";

import { BenchmarkTrmContext } from "pages/BenchmarkTrm";
import { getSavingsCalculationsGridStyle } from "pages/BenchmarkTrm/utils";

import { formatAlgorithmText } from "utils/string";
import { isAdminUser } from "utils/user";

import type { Header } from "pages/BenchmarkTrm/MainPanel/SavingsCalculationsGrid";

export const SavingsCalculationsGridHeader: React.FC<{
    headers: Header[];
    areAllRowsSelected: boolean;
    onSelectAll: () => void;
    onDeselectAll: () => void;
}> = ({ headers, areAllRowsSelected, onSelectAll, onDeselectAll }) => {
    const { isSelecting } = useContext(BenchmarkTrmContext);

    const style = useMemo(() => {
        return getSavingsCalculationsGridStyle(isSelecting);
    }, [isSelecting]);

    return (
        <IdsTableRow rowType="table-heading-row" customClasses="sticky-top">
            {isSelecting && (
                <IdsTableCell style={style.select}>
                    <IdsCheckbox
                        idValue="select-all"
                        defaultChecked={areAllRowsSelected}
                        changeHandler={areAllRowsSelected ? onDeselectAll : onSelectAll}
                    />
                </IdsTableCell>
            )}
            {isAdminUser() && <IdsTableCell cellType="table-heading-cell" heading="Std." style={style.standardized} />}
            <IdsTableCell cellType="table-heading-cell" heading="Variable Description" style={style.variableDescription} />
            {headers.map((header, index) => (
                <IdsTableCell
                    key={`${header.trmFamiliarName}-${header.measureName}-${index}`}
                    style={getSavingsCalculationsGridStyle(isSelecting, index).relAlgorithm}
                >
                    <IdsText weight="bold" size="md">
                        {header.trmFamiliarName}
                    </IdsText>
                    <IdsText weight="bold" size="md">
                        {header.measureName}
                    </IdsText>
                    <IdsText size="sm">
                        <div dangerouslySetInnerHTML={{ __html: formatAlgorithmText(header.formattedAlgorithm) }} />
                    </IdsText>
                </IdsTableCell>
            ))}
        </IdsTableRow>
    );
};
