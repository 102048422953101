import { HubConnection } from "@microsoft/signalr";
import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./apiSlice";
import { canConnect, isConnected, connectToHub } from "./signalrSetup";

import type { RootState } from "store";
import type { SignalREvent } from "types";

let connection: HubConnection;

export const signalrApi = createApi({
    reducerPath: "signalr",
    baseQuery: signalrBaseQuery,
    endpoints: (build) => ({
        sendEvent: build.mutation<void, { event: SignalREvent }>({
            query: ({ event }) => ({
                url: "",
                name: event.name,
                data: event.data,
            }),
        }),
        events: build.query<string[], string>({
            queryFn: () => ({
                data: [],
            }),
            async onCacheEntryAdded(event, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                await cacheDataLoaded;

                const listener = (data: string) => {
                    updateCachedData((currentCacheData) => {
                        currentCacheData.push(data);
                    });
                };

                connection.on(event, listener);

                await cacheEntryRemoved;

                connection.off(event, listener);
            },
        }),
    }),
});

async function signalrBaseQuery(args, api, extraOptions) {
    const result = await baseQuery(args, api, extraOptions);
    const user = (api.getState() as RootState).user;

    if (user && canConnect(connection)) {
        connection = await connectToHub(api.getState().user);
    }

    if (isConnected(connection)) {
        connection.invoke(args.name, ...args.data);
    }

    return result;
}

export const { useSendEventMutation, useEventsQuery } = signalrApi;
