import { createContext, memo } from "react";
// @ts-ignore
import { ScrollSync } from "react-scroll-sync";
import ClassNames from "classnames";

import ErrorBoundary from "components/ErrorBoundary";

import SplitWindow from "./SplitWindow";
import WindowContent from "./WindowContent";
import WindowHeader from "./WindowHeader";

import { availableViews } from "pages/configureViews";

import { useViews } from "utils/useViews";

import { WindowContainer } from "types";

import "./style.scss";

export const WindowContext = createContext({});

const Window = memo((props: WindowProps) => {
    const { containerName, name, isSplitViewActive, onOpen, onClose, onSplit, onRemoveSplit, onClearSplit } = props;

    const views = useViews({ containerName });

    const view = views.find((view) => view.name === name)!;

    const { className, component, active, showHeader, isSplitViewSync } = view;

    const ViewComponent = component ? availableViews[component] : null;

    const classNames = ClassNames("flex-column flex-one-in-row fill-height with-scroll no-shrink window", className, {
        "split-view": isSplitViewActive,
        active: active,
    });

    if (isSplitViewActive) {
        return (
            <ScrollSync enabled={isSplitViewSync}>
                <SplitWindow
                    className={classNames}
                    containerName={containerName}
                    name={name}
                    onOpen={onOpen}
                    onClose={onClose}
                    onRemoveSplit={onRemoveSplit}
                    onClearSplit={onClearSplit}
                />
            </ScrollSync>
        );
    }

    return (
        <WindowContext.Provider value={{ name, active, isSplitViewActive }}>
            <ScrollSync enabled={isSplitViewSync}>
                <div className={classNames} hidden={!active}>
                    <WindowContent active={active === true}>
                        <>
                            {showHeader && (
                                <WindowHeader
                                    view={view}
                                    close={view.close}
                                    isSplitViewEnabled={view.isSplitViewEnabled}
                                    isSplitViewActive={view.isSplitViewActive}
                                    onClose={() => onClose(name)}
                                    onSplit={() => onSplit(name)}
                                    onRemoveSplit={() => onRemoveSplit(name)}
                                />
                            )}
                            <div className="flex-one-in-column with-scroll">
                                <div className="window-body flex-column flex-one fill-height">
                                    <ErrorBoundary>
                                        {ViewComponent && <ViewComponent {...view.props} viewIndex={0} onOpen={onOpen} />}
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </>
                    </WindowContent>
                </div>
            </ScrollSync>
        </WindowContext.Provider>
    );
});

interface WindowProps {
    /**
     * Name of the View.
     */
    name: string;

    /**
     * View container type.
     */
    containerName: WindowContainer;

    /**
     * Split view is active for the view.
     */
    isSplitViewActive?: boolean;

    /**
     * Function that opens a new View.
     */
    onOpen: (params: any) => void;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit: (name: string) => void;

    /**
     * Function that clears the chosen side
     * of the Split View to blank View.
     */
    onClearSplit: (name: string, side: "left" | "right") => void;
}

export default Window;
