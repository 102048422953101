import { isEmpty } from "lodash";
import { memo, useEffect, useCallback } from "react";

import { useAppDispatch } from "store/hooks";

import {
    windowContainerAdd,
    windowAdd,
    windowActivate,
    windowPersist,
    windowRemove,
    windowSplit,
    windowRemoveSplit,
    windowClearSplit,
    windowSync,
} from "store/window/actions";

import WindowsContainer from "./WindowsContainer";

import { useViews } from "utils/useViews";

import { WindowContainer } from "types";
import { View } from "store/window/types";

const Windows = memo((props: WindowsProps) => {
    const { containerName, initialViews, persist } = props;

    const dispatch = useAppDispatch();

    const views = useViews({ containerName });

    useEffect(() => {
        dispatch(windowContainerAdd({ containerName }));

        const isInitialViewActive = views.filter((view) => view.active && view.name === initialViews[0].name).length > 0;

        initialViews.forEach((initialView) => {
            if (!views.find((view) => view.name === initialView.name)) {
                dispatch(windowAdd(initialView));
            }
        });

        if (isEmpty(views) && !isInitialViewActive && !isEmpty(initialViews)) {
            dispatch(
                windowActivate({
                    containerName,
                    name: initialViews[0].name,
                }),
            );
        }

        if (persist) {
            dispatch(windowPersist());
        }
    }, [containerName, initialViews, persist, views, dispatch]);

    const onWindowOpen = useCallback(
        (params) => {
            dispatch(
                windowAdd({
                    containerName,
                    name: params.name,
                    component: params.component,
                    showHeader: params.showHeader,
                    showTabs: params.showTabs,
                    activate: true,
                    persist,
                    close: params.close !== undefined ? params.close : true,
                    props: params.props,
                }),
            );
        },
        [containerName, persist, dispatch],
    );

    const onWindowClose = useCallback(
        (name) => {
            dispatch(
                windowRemove({
                    containerName,
                    name,
                    persist: false,
                }),
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch],
    );

    const onWindowSplit = useCallback(
        (name) => {
            dispatch(
                windowSplit({
                    containerName,
                    name,
                }),
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch],
    );

    const onWindowRemoveSplit = useCallback(
        (name) => {
            dispatch(
                windowRemoveSplit({
                    containerName,
                    name,
                }),
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch],
    );

    const onWindowClearSplit = useCallback(
        (name, side) => {
            dispatch(
                windowClearSplit({
                    containerName,
                    name,
                    side,
                }),
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch],
    );

    const onWindowActivate = useCallback(
        (name) => {
            dispatch(
                windowActivate({
                    containerName,
                    name,
                    persist,
                }),
            );
        },
        [containerName, persist, dispatch],
    );

    const onWindowSync = useCallback(
        (name) => {
            dispatch(
                windowSync({
                    containerName,
                    name,
                }),
            );

            if (persist) {
                dispatch(windowPersist());
            }
        },
        [containerName, persist, dispatch],
    );

    return (
        <WindowsContainer
            containerName={containerName}
            onOpen={onWindowOpen}
            onClose={onWindowClose}
            onSplit={onWindowSplit}
            onRemoveSplit={onWindowRemoveSplit}
            onClearSplit={onWindowClearSplit}
            onActivate={onWindowActivate}
            onSync={onWindowSync}
        />
    );
});

interface WindowsProps {
    /**
     * View container type.
     */
    containerName: WindowContainer;

    /**
     * Initial views to render.
     */
    initialViews: View[];

    persist: boolean;
}

export default Windows;
