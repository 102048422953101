import { isEmpty } from "lodash";
import { useMemo } from "react";

import {
    IdsFieldWrapper,
    IdsDropdown,
    IdsLabel,
    IdsTable,
    IdsTablePagination,
    IdsTableRow,
    IdsTableCell,
    IdsText,
} from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { DEFAULT_ITEMS_PER_PAGE } from "utils/constants";
import { alignSlashSolidIcon } from "utils/icons";
import { formatVariableText } from "utils/string";

import type { AssumptionListItem, StdVariable } from "types";

export const SelectStdVariable: React.FC<{
    selectedStdVariable?: string;
    stdVariables?: StdVariable[];
    assumptions?: AssumptionListItem[];
    pageNumber: number;
    totalRows?: number;
    totalPages?: number;
    isLoading: boolean;
    onChange: (value: string, name: string) => void;
    onPageChange: (pageNumber: number) => void;
}> = ({ selectedStdVariable, stdVariables, assumptions, pageNumber, totalRows, totalPages, isLoading, onChange, onPageChange }) => {
    const stdVariableList = useMemo(() => {
        return (stdVariables ?? []).map((v) => ({
            label: `${v.stdVariableDesc} (${v.stdVariableUnits})`,
            value: v.stdVariableNumber,
        }));
    }, [stdVariables]);

    const totalItemsText = useMemo(() => {
        let totalItemsText = "";

        const fromNumber = pageNumber * DEFAULT_ITEMS_PER_PAGE - DEFAULT_ITEMS_PER_PAGE + 1;
        let toNumber = pageNumber * DEFAULT_ITEMS_PER_PAGE;

        if (totalRows) {
            if (totalRows < toNumber) {
                toNumber = totalRows;
            }

            totalItemsText = `${fromNumber}-${toNumber} of ${totalRows} items`;
        }

        return totalItemsText;
    }, [pageNumber, totalRows]);

    return (
        <div className="flex-column fill-height">
            <IdsFieldWrapper htmlFor="std-variable" wrapperLabel="Standard variable" isRequired>
                <IdsDropdown
                    idValue="std-variable"
                    isSearchable={!isLoading} // A hack to properly preselect the dropdown value
                    placeholder={isLoading ? "Loading" : "Search Standard Variable"}
                    items={stdVariableList}
                    initialSelectedItems={selectedStdVariable ? [selectedStdVariable] : undefined}
                    changeHandler={(value) => onChange(value, "stdVariable")}
                    clearHandler={() => onChange("", "stdVariable")}
                />
            </IdsFieldWrapper>
            <IdsLabel customClasses="pb-2">Also maps to:</IdsLabel>
            {isEmpty(selectedStdVariable) || isEmpty(assumptions) ? (
                <NothingFoundBlock
                    icon={alignSlashSolidIcon}
                    title={isEmpty(selectedStdVariable) ? "No Standard Variable Chosen" : "No Mapping Found"}
                />
            ) : (
                <>
                    <div className="rounded-edges-round px-2" style={{ border: "1px solid var(--theme-base-border)" }}>
                        <IdsTable spacing="lg">
                            {assumptions?.map((assumption, index) => (
                                <IdsTableRow key={`${assumption.assumptionNumber}`}>
                                    <IdsTableCell
                                        style={{ borderWidth: index === assumptions.length - 1 ? 0 : undefined, pointerEvents: "none" }}
                                    >
                                        <div>
                                            <IdsText weight="bold">{`${assumption.trmFamiliarName} > ${assumption.measureName}`}</IdsText>
                                            <IdsText>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: `[${formatVariableText(assumption.variableName)}] ${assumption.variableDesc} (${assumption.variableUnits})`,
                                                    }}
                                                />
                                            </IdsText>
                                        </div>
                                    </IdsTableCell>
                                </IdsTableRow>
                            ))}
                        </IdsTable>
                    </div>
                    <div className="flex-row align-center justify-space-between pt-2">
                        <IdsText>{totalItemsText}</IdsText>
                        <div>
                            <IdsTablePagination
                                customClasses="input-table-pagination"
                                currentPage={pageNumber}
                                totalItems={DEFAULT_ITEMS_PER_PAGE}
                                totalPages={totalPages}
                                type="input"
                                iconOnly
                                pageChangeHandler={(pageNumber) => onPageChange(pageNumber)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
