import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsText, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { STANDARDIZE_HEADER_HEIGHT } from "pages/StandardizeData/utils";
import { circlePlusRegularIcon } from "utils/icons";

import { AddStandardModal, ModalField } from "./AddStandardModal";

const renderHeader = (id: string, name: string) => {
    switch (id) {
        case "algorithms":
            return "Configure Standard Permutation:";

        case "variables":
            return `Set Standard ${name}:`;

        default:
            return `Set Suggested Standard ${name}:`;
    }
};

const renderTitle = (id: string, name: string) => {
    switch (id) {
        case "algorithms":
        case "measures":
            return `Add Standard ${name.substring(0, name.length - 1)}`;

        default:
            return `Add Standard ${name}`;
    }
};

export const SuggestedStandard: React.FC<{
    id: string;
    name: string;
    standardComponent: React.ReactElement;
    modalFields?: ModalField[];
    standardizeDisabled: boolean;
    isLoading?: boolean;
    isSuccess?: boolean;
    additionalComponent?: React.ReactElement;
    onStandardizeClick: () => Promise<void>;
    onSaveClick?: (params: any) => Promise<void>;
}> = ({
    id,
    name,
    standardComponent,
    modalFields,
    standardizeDisabled,
    isLoading,
    isSuccess,
    additionalComponent,
    onStandardizeClick,
    onSaveClick,
}) => {
    const [isAdding, setIsAdding] = useState(false);

    return (
        <div className="flex-column fill-height">
            {modalFields !== undefined && isAdding && (
                <AddStandardModal
                    title={renderTitle(id, name)}
                    modalFields={modalFields}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    onSave={onSaveClick}
                    onClose={() => setIsAdding(false)}
                />
            )}
            {/* Match <Standardize /> header height */}
            {/* Left padding fixes outline being cut off */}
            <div className="flex-row justify-space-between pr-4" style={{ minHeight: STANDARDIZE_HEADER_HEIGHT, paddingLeft: 3 }}>
                <IdsText weight="bold">{renderHeader(id, name)}</IdsText>
                {modalFields !== undefined && (
                    <IdsButton variant="secondary" title={renderTitle(id, name)} clickHandler={() => setIsAdding(true)}>
                        <div className="flex-row gap-2 align-center">
                            <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                            Add
                        </div>
                    </IdsButton>
                )}
            </div>
            {/* Top and left padding fixes outline being cut off */}
            <div className="flex-column fill-height gap-3 pr-4 with-scroll" style={{ paddingTop: 3, paddingLeft: 3 }}>
                {standardComponent}
                <IdsButtonGroup position="center">
                    <IdsButton variant="primary" isDisabled={standardizeDisabled} clickHandler={onStandardizeClick}>
                        Standardize
                    </IdsButton>
                </IdsButtonGroup>
                {additionalComponent}
            </div>
        </div>
    );
};
