export const Copyright = () => {
    return (
        <div
            className="fill-width text-center"
            style={{
                color: "#949398",
                fontFamily: "var(--ids-semantic-font-font-family-label)",
                fontWeight: 500,
                padding: "20px 0",
            }}
        >
            Applied Energy Group, Inc
        </div>
    );
};
