import { IdsTableRow, IdsTableCell } from "@emergn-infinity/ids-react";

import { Tabs } from "pages/Home/utils";

import { GridType, GridStyle } from "components/Grids/MeasureCostsGrid";

export const MeasureCostsGridHeader: React.FC<{
    gridType: GridType;
    editRights: boolean;
    styles: GridStyle;
}> = ({ gridType, editRights, styles }) => {
    return (
        <IdsTableRow
            rowType="table-heading-row"
            customClasses="sticky-top"
            style={{ top: gridType === Tabs.ExploreTrms.id ? -24 : undefined }}
        >
            {gridType === Tabs.BenchmarkTrm.id && (
                <IdsTableCell cellType="table-heading-cell" heading="TRM & Measure" style={styles.trmAndMeasure} />
            )}
            <IdsTableCell cellType="table-heading-cell" heading="Sector" style={styles.sector} />
            <IdsTableCell cellType="table-heading-cell" heading="Vintage" style={styles.vintage} />
            <IdsTableCell cellType="table-heading-cell" heading="Lookup Selection" style={styles.lookupSelection} />
            <IdsTableCell cellType="table-heading-cell" heading="Cost" style={styles.cost} />
            <IdsTableCell cellType="table-heading-cell" heading="Cost Type" style={styles.costType} />
            <IdsTableCell cellType="table-heading-cell" heading="Source" style={styles.source} />
            {gridType === Tabs.ExploreTrms.id && editRights && <IdsTableCell cellType="table-heading-cell" style={styles.action} />}
        </IdsTableRow>
    );
};
