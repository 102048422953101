import { useState } from "react";
import { Measure, MeasureAlgorithm } from "types";
import { AlgorithmVariablesModal } from "./AlgorithmVariablesModal";
import { AlgorithmModal } from "./AlgorithmModal";
import { isEmpty } from "lodash";

export const AlgorithmEdit: React.FC<{
    measure: Measure;
    algorithm?: MeasureAlgorithm;
    onAdded?: (algorithm: MeasureAlgorithm) => void;
    onClose: () => void;
}> = ({ measure, algorithm, onAdded, onClose }) => {
    const [createdAlgorithmNumber, setCreatedAlgorithmNumber] = useState<string>();
    const [createdAlgorithm, setCreatedAlgorithm] = useState<string>();
    const [editVariables, setEditVariables] = useState(false);

    const onAlgorithmAdded = (algorithm: MeasureAlgorithm) => {
        setCreatedAlgorithmNumber(algorithm.algorithmNumber);
        setCreatedAlgorithm(algorithm.algorithm);
        setEditVariables(true);
        onAdded?.(algorithm);
    };

    const title = isEmpty(algorithm) ? "Add Algorithm" : "Edit Algorithm";

    if (editVariables && createdAlgorithmNumber && createdAlgorithm) {
        return (
            <AlgorithmVariablesModal
                title={title}
                algorithmNumber={createdAlgorithmNumber}
                algorithm={createdAlgorithm}
                measure={measure}
                onClose={onClose}
            />
        );
    }

    return (
        <AlgorithmModal
            title={title}
            measure={measure}
            algorithmNumber={algorithm?.algorithmNumber}
            algorithm={algorithm?.algorithm}
            onClose={onClose}
            onAdded={onAlgorithmAdded}
        />
    );
};
