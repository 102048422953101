export const createPaginationItems = <T>(items: T[], itemsPerPage: number) => {
    let pageNumber = 1;

    return items.reduce((acc: { [key: number]: T[] }, cv) => {
        if (acc[pageNumber] === undefined) {
            acc[pageNumber] = [];
        }

        if (acc[pageNumber].length === itemsPerPage) {
            pageNumber++;

            acc[pageNumber] = [];
        }

        acc[pageNumber].push(cv);

        return acc;
    }, {});
};
