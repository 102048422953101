import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    HttpTransportType,
    IHttpConnectionOptions,
    JsonHubProtocol,
    LogLevel,
} from "@microsoft/signalr";

import { isDevEnvironment } from "utils/constants";

import { getQueryString } from "./apiSlice";

import type { User } from "types";

export const canConnect = (connection: HubConnection) => {
    return !connection || connection?.state === HubConnectionState.Disconnected;
};

// const canDisconnect = (connection: HubConnection) => {
//     return connection?.state && connection.state !== HubConnectionState.Disconnected;
// };

export const isConnected = (connection: HubConnection) => {
    return connection?.state === HubConnectionState.Connected;
};

const startSignalRConnection = async (connection: HubConnection) => {
    if (!canConnect(connection)) {
        return;
    }

    try {
        await connection.start();

        console.assert(connection.state === HubConnectionState.Connected);

        if (isDevEnvironment) {
            console.log("SignalR: Connection Established!");
        }
    } catch (error) {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.error("SignalR: Connection Error:", error);

        setTimeout(() => startSignalRConnection(connection), 5000);
    }
};

export const connectToHub = async (user: User) => {
    const connectionHub = `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/upgrade` + getQueryString({ access_token: user.accessToken });
    const logger = isDevEnvironment ? LogLevel.Debug : LogLevel.Error;

    const options: IHttpConnectionOptions = {
        logMessageContent: isDevEnvironment,
        logger,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
    };

    // Create the connection instance.
    // withAutomaticReconnect will automatically try to reconnect
    // and generate a new socket connection if needed.
    // withSeverTimeout to keep the connection open the serverTimeout
    // should be larger than the KeepAlive value that is set on the server
    const connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withAutomaticReconnect()
        .withServerTimeout(60000)
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(logger)
        .build();

    connection.onclose((error) => {
        console.assert(connection.state === HubConnectionState.Disconnected);

        if (isDevEnvironment) {
            console.log("SignalR: Connection closed due to error. Try refreshing this page to restart the connection.", error);
        }
    });

    connection.onreconnecting((error) => {
        console.assert(connection.state === HubConnectionState.Reconnecting);

        if (isDevEnvironment) {
            console.log("SignalR: Connection lost due to error. Reconnecting.", error);
        }
    });

    connection.onreconnected((connectionId) => {
        console.assert(connection.state === HubConnectionState.Connected);

        if (isDevEnvironment) {
            console.log("SignalR: Connection reestablished. Connected with connectionId:", connectionId);
        }
    });

    await startSignalRConnection(connection);

    return connection;
};

// const disconnectFromHub = (connection: HubConnection) => {
//     connection.stop();
// };
