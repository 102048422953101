import { isEmpty, isNil, isString } from "lodash";
import { renderToString } from "react-dom/server";
import { toast } from "react-toastify";
import { FunctionNode, MathNode, OperatorNode, ParenthesisNode, SymbolNode, evaluate, parse } from "mathjs";

import { useGetMeasureQuery } from "store/apiSlice";

import { LookupVariants } from "utils/constants";

import { Assumption, AssumptionAccompanyingLayers, LookupEntry, AlgorithmVariable, Variable } from "types";

export const TrmFilters = {
    Active: "active-trms-and-measures",
    All: "all-trms-and-measures",
} as const;

export const TrmEditTabs = {
    TrmInformation: {
        id: "tab-trm-information",
        name: "TRM Information",
    },
    Links: {
        id: "tab-links",
        name: "Links",
    },
    Contacts: {
        id: "tab-contacts",
        name: "Contacts",
    },
} as const;

export const TRM_MODAL_STYLE = {
    width: "452px",
    maxWidth: "100%",
    height: "100%",
};

export const DELETE_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

export const useMeasureDetails = (measureNumber: string) => {
    const {
        data: measure,
        isFetching: isMeasureFetching,
        isError: isMeasureError,
    } = useGetMeasureQuery({ measureNumber }, { skip: isEmpty(measureNumber) });

    return !isMeasureFetching && !isMeasureError ? measure : undefined;
};

export const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);

        toast.success("Text copied to clipboard");
    } catch (error) {
        toast.error("Error copying text to clipboard");
    }
};

export const copyVariablesToClipboard = async (variables: AlgorithmVariable[], variableValues: Variable[]) => {
    try {
        const table = renderToString(
            <table cellPadding="5">
                <thead>
                    <tr style={{ backgroundColor: "#F1F2F4" }}>
                        <th>TRMVariable</th>
                        <th>TRMVariableDesc</th>
                        <th>TRMVarUnits</th>
                        <th>AssignedValue</th>
                    </tr>
                </thead>
                <tbody>
                    {variables.map((item) => (
                        <tr key={`variable-${item.variable}`}>
                            <td>{item.variable}</td>
                            <td>{getVariableDescription(item)}</td>
                            <td>{item.units}</td>
                            <td>{variableValues.find((variable) => variable.name === item.variable)?.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>,
        );

        await navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([table], { type: "text/html" }),
            }),
        ]);

        toast.success("Variables copied to clipboard");
    } catch (error) {
        toast.error("Error copying variables to clipboard");
    }
};

export const copyLookupsToClipboard = async (lookups: LookupEntry[], lookupVariant: keyof typeof LookupVariants) => {
    try {
        const table = renderToString(
            <table cellPadding="5">
                <thead>
                    <tr style={{ backgroundColor: "#F1F2F4" }}>
                        <th>Lookup Criteria</th>
                        {lookupVariant === LookupVariants.Value && <th>Lookup Value</th>}
                        {lookupVariant === LookupVariants.Equation && <th>Lookup Equation</th>}
                    </tr>
                </thead>
                <tbody>
                    {lookups.map((item) => (
                        <tr key={`lookup-${item.lookupCriteria}`}>
                            <td>{item.lookupCriteria}</td>
                            {lookupVariant === LookupVariants.Value && <td>{item.lookupValue}</td>}
                            {lookupVariant === LookupVariants.Equation && <td>{item.lookupEquation}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>,
        );

        await navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([table], { type: "text/html" }),
            }),
        ]);

        toast.success("Lookups copied to clipboard");
    } catch (error) {
        toast.error("Error copying lookups to clipboard");
    }
};

export const getVariableDescription = (variable: AlgorithmVariable) => {
    let description = variable.description ?? "";

    if (!isNil(variable.assignedValue_Min)) {
        description += ` (Min. of ${variable.assignedValue_Min}`;

        if (isNil(variable.assignedValue_Max)) {
            description += ")";
        }
    }
    if (!isNil(variable.assignedValue_Max)) {
        if (isNil(variable.assignedValue_Min)) {
            description += " (";
        } else {
            description += " and ";
        }

        description += `Max. of ${variable.assignedValue_Max})`;
    }

    return description;
};

export const extractVariables = (expression: string) => {
    const variables = new Set<string>();

    function traverse(node: MathNode) {
        if ((node as SymbolNode).isSymbolNode) {
            variables.add((node as SymbolNode).name);
        } else if ((node as OperatorNode).isOperatorNode || (node as FunctionNode).isFunctionNode) {
            (node as OperatorNode).args.forEach(traverse);
        } else if ((node as ParenthesisNode).isParenthesisNode) {
            traverse((node as ParenthesisNode).content);
        }
    }

    try {
        // Replace angled brackets to be able to parse the expression
        expression = expression.replaceAll(/</g, "__");
        expression = expression.replaceAll(/>/g, "__");

        // Use a temporary variable name to replace all variables with
        const variablesMap = new Map<string, string>();
        let variableIndex = 0;
        expression = expression.replace(/__(.*?)__/g, (match, p1) => {
            const tempName = `__temp${variableIndex++}__`;
            variablesMap.set(tempName, `__${p1}__`);

            return tempName;
        });

        const node = parse(expression);
        traverse(node);

        return Array.from(variables).map((v) => {
            let variable = variablesMap.get(v)!;

            let bracketCount = 0;
            while (variable.startsWith("__")) {
                variable = variable.substring(2);
                bracketCount++;
            }

            for (let i = 0; i < bracketCount; i++) {
                variable = "<" + variable;
            }

            bracketCount = 0;
            while (variable.endsWith("__")) {
                variable = variable.substring(0, variable.length - 2);
                bracketCount++;
            }

            for (let i = 0; i < bracketCount; i++) {
                variable = variable + ">";
            }

            return variable;
        });
    } catch (e: any) {
        return [];
    }
};

/**
 * Finds and returns duplicate variables that are written
 * in different text cases (e.g., <a> and <A>).
 * @param variables list of variables
 * @returns list of duplicate variables
 */
export const findDuplicateVariables = (variables: string[]) => {
    const duplicates: string[] = [];

    for (const v1 in variables) {
        for (const v2 in variables) {
            if (v1 === v2 || variables[v1] === variables[v2] || duplicates.some((v) => v === variables[v1])) {
                continue;
            } else if (variables[v1].toLowerCase() === variables[v2].toLowerCase()) {
                duplicates.push(variables[v1]);
            }
        }
    }

    return duplicates;
};

export const validateAlgorithm = (value: string | undefined) => {
    const result: AlgorithmErrors = {};

    if (!isString(value)) {
        return result;
    }

    // remove text surrounded by []
    let expression = value.replaceAll(/\[.*?\]/g, "");

    const variables = extractVariables(expression);

    // Algorithm is invalid if input value and variables length differ
    if (!isEmpty(value) && isEmpty(variables)) {
        result.algorithm = "Algorithm is invalid";
    }

    // Wrap all variables to be replaced with values in angled brackets <>
    if (!variables.filter((v) => !(v === "x" || v === "X")).every((v) => v.startsWith("<") && v.endsWith(">"))) {
        result.brackets = "All variables must be surrounded by <>";
    }

    // Use a single underscore before a subscript (e.g Watts_base)
    if (variables.some((v) => v[v.indexOf("_") + 1] === "_")) {
        result.underscore = "Use a single underscore before a subscript (e.g Watts_base)";
    }

    // Denote multiplication with * (not x), and division with / (not +)
    if (variables.some((v) => v === "x" || v === "X")) {
        result.multiplication = "Denote multiplication with * (not x)";
    }

    // For hardcoded numbers, do not include a comma (e.g. 1000 instead of 1,000)
    if (expression.match(/\d,/g)) {
        result.comma = "For hardcoded numbers, do not include a comma (e.g. 1000 instead of 1,000)";
    }

    const duplicates = findDuplicateVariables(variables);
    const duplicateVariables = duplicates.map((v) => v.substring(1, v.length - 1));

    // Use unique names for variables
    if (!isEmpty(duplicateVariables)) {
        result.duplicate = `Duplicate variables: ${duplicateVariables.join(", ")}`;
    }

    // replace all variables with 1
    expression = expression.replaceAll(/<.*?>/g, "1");

    try {
        evaluate(expression);
    } catch (e: any) {
        result.algorithm = e.message;
    }

    return result;
};

/**
 * TODO: Create a test case for this function
 * Recursive function that loops through variables that are found in equations.
 *
 * @param variable variable to look for in equations
 * @param asmpWithEquations assumptions that have equations defined
 * @param accompanyingLayers assumption equation hierarchy
 * @returns assumption equation hierarchy
 */
export const getAccompanyingLayers = (
    variable: string,
    asmpWithEquations: Assumption[],
    accompanyingLayers: AssumptionAccompanyingLayers[],
) => {
    // Wrap variable name in angle brackets to match with variables in equation
    const variableName = `<${variable}>`;

    // Look for assumption that has variable found in equation
    const asmpWithVariable = asmpWithEquations.find((asmp) => asmp.equation!.includes(variableName));

    // If assumption is found, add assumption number at the start
    // of equation hierarchy and run this function again with the assumption
    // that was found
    if (asmpWithVariable) {
        accompanyingLayers.unshift({
            assumptionNumber: asmpWithVariable.assumptionNumber,
            layer: 0,
        });

        getAccompanyingLayers(asmpWithVariable.variable, asmpWithEquations, accompanyingLayers);
    }

    // If assumption is not found, that means we have reached the top
    // of equation hierarchy and now it is time to assign appropriate
    // layer to each assumption with equations.
    // First one being the highest layer and last one being layer = 1
    return accompanyingLayers.map((al, index) => ({ ...al, layer: accompanyingLayers.length - index }));
};

export const printPromiseErrors = (result: PromiseSettledResult<any>[]) => {
    let hasErrors = false;

    result.forEach((r) => {
        if (r.status === "rejected") {
            console.error(r.reason);
            hasErrors = true;
        }

        if (r.status === "fulfilled" && !isNil((r.value as any)?.error)) {
            console.error((r.value as any).error.data.responseMessage);
            hasErrors = true;
        }
    });

    return hasErrors;
};

export const getPhoneNumber = (phone?: string, extension?: string) => {
    let phoneNumber = phone ?? "";

    if (phoneNumber && extension) {
        phoneNumber += ` x${extension}`;
    }

    return phoneNumber;
};

export type AlgorithmErrors = {
    algorithm?: string;
    brackets?: string;
    underscore?: string;
    multiplication?: string;
    comma?: string;
    duplicate?: string;
};
