import cn from "classnames";

import { IdsTag, IdsTagGroup, IdsText } from "@emergn-infinity/ids-react";

export const EditFormTags: React.FC<{
    trmName?: string;
    measureName?: string;
    sector?: string;
    endUse?: string;
    vintage?: string;
    fuelType?: string;
    algorithm?: string;
    variable?: string;
    withoutPadding?: boolean;
}> = ({ trmName, measureName, sector, endUse, vintage, fuelType, algorithm, variable, withoutPadding }) => {
    return (
        <IdsTagGroup customClasses={cn("fill-width no-overflow", { "pb-4": !withoutPadding })}>
            {trmName && (
                <IdsTag variant="brand-b" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-b-default)" }}>
                            TRM:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-b-default)", minWidth: 0 }}>
                            <div className="text-truncate">{trmName}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {measureName && (
                <IdsTag variant="brand-b" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-b-default)" }}>
                            Measure:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-b-default)", minWidth: 0 }}>
                            <div className="text-truncate">{measureName}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {sector && (
                <IdsTag variant="brand-b" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-b-default)" }}>
                            Sector:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-b-default)", minWidth: 0 }}>
                            <div className="text-truncate">{sector}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {endUse && (
                <IdsTag variant="brand-b" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-b-default)" }}>
                            End Use:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-b-default)", minWidth: 0 }}>
                            <div className="text-truncate">{endUse}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {vintage && (
                <IdsTag variant="brand-b" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-b-default)" }}>
                            Vintage:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-b-default)", minWidth: 0 }}>
                            <div className="text-truncate">{vintage}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {fuelType && (
                <IdsTag variant="brand-b" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-b-default)" }}>
                            Fuel Type:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-b-default)", minWidth: 0 }}>
                            <div className="text-truncate">{fuelType}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {algorithm && (
                <IdsTag variant="brand" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-brand-default)" }}>
                            Algorithm:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-brand-default)", minWidth: 0 }}>
                            <div className="text-truncate">{algorithm}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
            {variable && (
                <IdsTag variant="information" size="sm" style={{ minWidth: 0 }}>
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold" style={{ color: "var(--ids-tag-label-text-color-info-default)" }}>
                            Variable:
                        </IdsText>
                        <IdsText style={{ color: "var(--ids-tag-label-text-color-info-default)", minWidth: 0 }}>
                            <div className="text-truncate">{variable}</div>
                        </IdsText>
                    </div>
                </IdsTag>
            )}
        </IdsTagGroup>
    );
};
