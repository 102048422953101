import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY;

// Ref element is no longer needed: https://github.com/dozoisch/react-google-recaptcha/issues/103#issuecomment-1708346332
export const Captcha: React.FC<{
    onChange?: (token: string | null) => void;
}> = ({ onChange }) => {
    return <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY ?? ""} onChange={onChange} />;
};
