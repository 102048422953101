import { IdsFieldWrapper, IdsTextarea, IdsTextInput } from "@emergn-infinity/ids-react";

export const CreateStdVariable: React.FC<{
    description: string;
    unit: string;
    onChange: (value: string, name: string) => void;
}> = ({ description, unit, onChange }) => {
    return (
        <div>
            <IdsFieldWrapper htmlFor="std-variable-description" wrapperLabel="Description" isRequired>
                <IdsTextarea
                    idValue="std-variable-description"
                    placeholder="Enter description"
                    defaultValue={description}
                    changeHandler={(value) => onChange(value, "description")}
                />
            </IdsFieldWrapper>
            <IdsFieldWrapper htmlFor="std-variable-unit" wrapperLabel="Unit" isRequired>
                <IdsTextInput
                    idValue="std-variable-unit"
                    placeholder="e.g. kWh"
                    defaultValue={unit}
                    changeHandler={(value) => onChange(value, "unit")}
                />
            </IdsFieldWrapper>
        </div>
    );
};
