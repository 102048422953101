import { isEmpty } from "lodash";
import { memo } from "react";

import WindowTab from "./WindowTab";

import { useViews } from "utils/useViews";

import { WindowContainer } from "types";

import "./style.scss";

const WindowTabs = memo((props: WindowTabsProps) => {
    const { containerName, onClose, onActivate, onSync } = props;

    const views = useViews({ containerName });

    const tabs = views.filter((view) => view.showTabs !== false);

    return !isEmpty(tabs) ? (
        <div className="window-tabs">
            <div className="flex-row align-end fill-height window-tabs__container">
                <div className="window-tabs__vision-logo" />
                <div className="flex-row align-end fill-height with-scroll window-tabs__tabs">
                    {tabs.map((view) => (
                        <WindowTab
                            key={view.name}
                            containerName={containerName}
                            name={view.name}
                            active={view.active}
                            close={view.close}
                            isSplitViewActive={view.isSplitViewActive}
                            onActivate={onActivate}
                            onClose={onClose}
                            onSync={onSync}
                        />
                    ))}
                </div>
            </div>
        </div>
    ) : null;
});

interface WindowTabsProps {
    /**
     * View container type.
     */
    containerName: WindowContainer;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that activates a View.
     */
    onActivate: (name: string) => void;

    /**
     * Function that enables/disables
     * sync scrolling for Split View.
     */
    onSync: (name: string) => void;
}

export default WindowTabs;
