import { useRef } from "react";

import { IdsText, IdsFieldWrapper, IdsTextInput } from "@emergn-infinity/ids-react";

import { sanitizeAndSetNumericInput } from "utils/string";

export const TrmInformationStep: React.FC<{
    trmFamiliarName: string;
    trmName: string;
    preparedBy: string;
    applicableYear: string;
    yearPrepared: string;
    defSummerPeak: string;
    defWinterPeak: string;
    onChange: (value: string | undefined, name: string) => void;
}> = ({ trmFamiliarName, trmName, preparedBy, applicableYear, yearPrepared, defSummerPeak, defWinterPeak, onChange }) => {
    const applicableYearRef = useRef<HTMLInputElement>(null);
    const yearPreparedRef = useRef<HTMLInputElement>(null);

    return (
        <div className="flex-column gap-4">
            <IdsText size="sm" weight="bold" component="h4">
                TRM Information
            </IdsText>
            <div>
                <IdsFieldWrapper htmlFor="trm-familiar-name" wrapperLabel="TRM Familiar Name" isRequired>
                    <IdsTextInput
                        idValue="trm-familiar-name"
                        defaultValue={trmFamiliarName}
                        changeHandler={(value) => onChange(value, "trmFamiliarName")}
                    />
                </IdsFieldWrapper>
                <IdsFieldWrapper htmlFor="trm-name" wrapperLabel="TRM Name" isRequired>
                    <IdsTextInput idValue="trm-name" defaultValue={trmName} changeHandler={(value) => onChange(value, "trmName")} />
                </IdsFieldWrapper>
                <IdsFieldWrapper htmlFor="authored-by" wrapperLabel="Authored By" isRequired>
                    <IdsTextInput
                        idValue="authored-by"
                        defaultValue={preparedBy}
                        changeHandler={(value) => onChange(value, "preparedBy")}
                    />
                </IdsFieldWrapper>
                <div className="flex-row gap-4">
                    <IdsFieldWrapper htmlFor="applicable-year" wrapperLabel="Applicable Year" isRequired>
                        <IdsTextInput
                            innerRef={applicableYearRef}
                            idValue="applicable-year"
                            defaultValue={applicableYear}
                            changeHandler={(value) =>
                                onChange(
                                    sanitizeAndSetNumericInput(value, applicableYearRef, { allowDecimals: false, maxLength: 4 }),
                                    "applicableYear",
                                )
                            }
                        />
                    </IdsFieldWrapper>
                    <IdsFieldWrapper htmlFor="year-authored" wrapperLabel="Year Authored" isRequired>
                        <IdsTextInput
                            innerRef={yearPreparedRef}
                            idValue="year-authored"
                            defaultValue={yearPrepared}
                            changeHandler={(value) =>
                                onChange(
                                    sanitizeAndSetNumericInput(value, yearPreparedRef, { allowDecimals: false, maxLength: 4 }),
                                    "yearPrepared",
                                )
                            }
                        />
                    </IdsFieldWrapper>
                </div>
                <IdsFieldWrapper htmlFor="summer-def" wrapperLabel="Summer Peak Definition" isRequired>
                    <IdsTextInput
                        idValue="summer-def"
                        defaultValue={defSummerPeak}
                        changeHandler={(value) => onChange(value, "defSummerPeak")}
                    />
                </IdsFieldWrapper>
                <IdsFieldWrapper htmlFor="winter-def" wrapperLabel="Winter Peak Definition" isRequired>
                    <IdsTextInput
                        idValue="winter-def"
                        defaultValue={defWinterPeak}
                        changeHandler={(value) => onChange(value, "defWinterPeak")}
                    />
                </IdsFieldWrapper>
            </div>
        </div>
    );
};
