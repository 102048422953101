import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IdsButton, IdsText } from "@emergn-infinity/ids-react";

export const ChooseItem: React.FC<{
    icon: IconProp;
    title: string;
    message: string;
    actionLabel: string;
    onActionClick: () => void;
}> = ({ icon, title, message, actionLabel, onActionClick }) => {
    return (
        <div className="flex-column align-center justify-center fill-height gap-3">
            <div className="flex-column align-center gap-1">
                <FontAwesomeIcon icon={icon} size="2xl" color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                <IdsText weight="bold" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                    {title}
                </IdsText>
                <IdsText size="md" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                    {message}
                </IdsText>
            </div>
            <IdsButton variant="secondary" padding="sm" clickHandler={onActionClick}>
                {actionLabel}
            </IdsButton>
        </div>
    );
};
