export const VariableMappingTabs = {
    SelectExisting: {
        id: "tab-select-existing",
        name: "Select Standard Variable",
    },
    CreateNew: {
        id: "tab-create-new",
        name: "Create New",
    },
} as const;
