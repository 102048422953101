import { createSlice } from "@reduxjs/toolkit";

import { loadStateFromStorage } from "store/utils";

import { TrmFilters } from "./utils";
import { clearBrowserUrl } from "utils/window";

import type { PayloadAction } from "@reduxjs/toolkit";

const STATE_KEY = "explore-trms-state";

const trmNumberPath = window.location.pathname.split("/")[1];
const measureNumberPath = window.location.pathname.split("/")[2];

const trmNumber = trmNumberPath && trmNumberPath.length === 32 ? trmNumberPath : undefined;
const measureNumber = measureNumberPath && measureNumberPath.length === 32 ? measureNumberPath : undefined;

// If trmNumber is found in the URL, save it in storage
if (trmNumber) {
    sessionStorage.setItem(
        STATE_KEY,
        JSON.stringify({
            selectedTrm: trmNumber,
            selectedMeasure: measureNumber ?? "",
            filter: TrmFilters.Active,
        }),
    );

    clearBrowserUrl();
}

export const exploreTrmsSlice = createSlice({
    name: "explore-trms",
    initialState: loadStateFromStorage(STATE_KEY, {
        selectedTrm: "",
        selectedMeasure: "",
        filter: TrmFilters.Active,
    }) as ExploreTrmsState,
    reducers: {
        selectorChange: (state, action: PayloadAction<SelectorChangePayload>) => {
            const { selector, value, persist } = action.payload;

            if (selector === "trm") {
                state.selectedTrm = value;
                state.selectedMeasure = "";
            } else if (selector === "measure") {
                state.selectedMeasure = value;
            } else if (selector === "filter") {
                state.filter = value as Filter;

                if (!persist) {
                    state.selectedTrm = "";
                    state.selectedMeasure = "";
                }
            }

            sessionStorage.setItem(STATE_KEY, JSON.stringify(state));
        },
    },
});

type Filter = (typeof TrmFilters)[keyof typeof TrmFilters];

type ExploreTrmsState = {
    selectedTrm: string;
    selectedMeasure: string;
    filter: Filter;
};

type SelectorChangePayload = {
    selector: "trm" | "measure" | "filter";
    value: string;

    /**
     * Persist selected TRM and measure when changing filter.
     */
    persist?: boolean;
};

export const { selectorChange } = exploreTrmsSlice.actions;

export default exploreTrmsSlice.reducer;
