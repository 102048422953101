import cn from "classnames";
import { isEmpty } from "lodash";
import { useState } from "react";

import { usePopper } from "utils/usePopper";

import type { ComputedPlacement } from "@popperjs/core";

import "./style.scss";

export const Tooltip = (props: TooltipProps) => {
    const [referenceElement, setReferenceElement] = useState<Element | null | undefined>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null | undefined>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null | undefined>(null);

    const placement = props.placement ?? "bottom-start";
    const offset = props.offset ?? [0, 16];
    const variant = props.variant ?? "highlight";
    const size = props.size ?? "md";

    const { styles, attributes, update } = usePopper({
        referenceElement,
        popperElement,
        arrowElement,
        placement,
        offset,
    });

    const onMouseEnter = () => {
        update?.(); // update tooltip position on hover
    };

    if (isEmpty(props.message)) return <>{props.children}</>;

    return (
        <div className="tooltip">
            <div ref={setReferenceElement} className="tooltip-activator" aria-describedby="tooltip" onMouseEnter={onMouseEnter}>
                {props.children}
            </div>
            <div
                ref={setPopperElement}
                className={cn("tooltip-container", {
                    "variant-neutral": variant === "neutral",
                    "variant-highlight": variant === "highlight",
                    "p-1 size-sm": size === "sm",
                    "p-2 size-md": size === "md",
                })}
                style={styles.popper}
                role="tooltip"
                {...attributes.popper}
            >
                {props.message}
                <div ref={setArrowElement} className="tooltip-arrow" style={styles.arrow} />
            </div>
        </div>
    );
};

interface TooltipProps {
    message?: string | null;
    placement?: ComputedPlacement;
    offset?: number[];
    variant?: "neutral" | "highlight";
    size?: "sm" | "md";
    children: React.ReactNode;
}
