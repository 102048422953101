import { useEffect } from "react";
import queryString from "query-string";

import { Wrapper } from "./Wrapper";

import { IconLoading } from "components/IconLoading";
import { ViewPlaceholder } from "components/ViewPlaceholder";

import { USER_SAVE } from "store/actionTypes";
import { refreshUser } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";
import { openWindowLogin } from "store/window/actions";

import { clearBrowserUrl } from "utils/window";

export const SsoWrapper: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const login = async () => {
            const params = queryString.parse(window.location.search);

            let accessToken: string | null = null;
            let refreshToken: string | null = null;

            if (params) {
                Object.keys(params).forEach((key) => {
                    const formattedKey = key.toLowerCase();

                    switch (formattedKey) {
                        case "accesstoken":
                            accessToken = params[key] as string;

                            break;
                        case "refreshtoken":
                            refreshToken = params[key] as string;

                            break;
                        default:
                            break;
                    }
                });
            }

            if (accessToken && refreshToken) {
                dispatch({
                    type: USER_SAVE,
                    user: {
                        accessToken,
                        refreshToken,
                    },
                });

                await dispatch(refreshUser());

                setTimeout(() => {
                    clearBrowserUrl();
                }, 100);
            } else {
                clearBrowserUrl();

                dispatch(openWindowLogin());
            }
        };

        login();
    }, [dispatch]);

    return (
        <Wrapper>
            <ViewPlaceholder>
                <IconLoading />
            </ViewPlaceholder>
        </Wrapper>
    );
};
