import { useCallback, useState } from "react";

import { UsernameStep } from "./UsernameStep";
import { PasswordStep } from "./PasswordStep";

import { Wrapper } from "pages/Login/Wrapper";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { login } from "store/login/actions";

export const LoginForm: React.FC = () => {
    const dispatch = useAppDispatch();

    const loginState = useAppSelector((state) => state.login);

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [step, setStep] = useState(0);

    const onUserChange = useCallback(() => {
        setStep(0);
    }, []);

    const onChange = useCallback((name: string, value: string) => {
        if (name === "userName") {
            setUserName(value);
        } else if (name === "password") {
            setPassword(value);
        } else if (name === "authCode") {
            setAuthCode(value);
        }
    }, []);

    const onSignIn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (step === 0 && userName) {
            setStep((prevStep) => prevStep + 1);

            return;
        }

        if (step === 1 && password) {
            dispatch(login({ userName, password, authCode }));
        }
    };

    return (
        <Wrapper>
            {step === 0 && <UsernameStep userName={userName} onChange={onChange} onNext={onSignIn} />}
            {step === 1 && (
                <PasswordStep
                    userName={userName}
                    password={password}
                    authCode={authCode}
                    errorMessage={loginState.errorMessage}
                    onUserChange={onUserChange}
                    onChange={onChange}
                    onSignIn={onSignIn}
                />
            )}
        </Wrapper>
    );
};
