import * as actionTypes from "store/actionTypes";

const initialState = {
    isLoading: false,
    errorMessage: null,
    infoMessage: null,
    logOutPending: false,
    repeatActions: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_RESET:
            state = initialState;
            break;

        case actionTypes.LOGIN_RESPONSE:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.LOGIN_ERROR:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: action.message,
            };

            break;

        default:
            break;
    }

    return state;
};
