import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
    useGetAssumptionsForStandardizingQuery,
    useGetStdVariablesQuery,
    useUpdateStandardizingForAssumptionMutation,
    useCreateStdVariableMutation,
} from "store/apiSlice";

import { Standardize } from "pages/StandardizeData/MainPanel/common/Standardize";
import { SuggestedStandard } from "pages/StandardizeData/MainPanel/common/SuggestedStandard";

import { AssumptionsTable } from "./AssumptionsTable";
import { StandardVariable } from "./StandardVariable";
import { AssumptionsMapTable } from "./AssumptionsMapTable";

import { AssumptionStandardizingListItem } from "types";

const addStdVariableFields = [
    {
        idValue: "description",
        defaultValue: "",
        placeholder: "Enter description",
        label: "Description",
    },
    {
        idValue: "unit",
        defaultValue: "",
        placeholder: "e.g. kWh",
        label: "Unit",
    },
];

export const Variables: React.FC<{
    id: string;
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    onChange: (e: any, name: string) => void;
}> = ({ id, name, fullName, showAll, hideInactive, search, pageSize, pageNumber, totalPages, onChange }) => {
    const [activeAssumption, setActiveAssumption] = useState<AssumptionStandardizingListItem | null>(null);
    const [selectedAssumptions, setSelectedAssumptions] = useState<AssumptionStandardizingListItem[]>([]);

    const [stdVariableNumber, setStdVariableNumber] = useState("");

    const fetchStdVariables = activeAssumption !== null || !isEmpty(selectedAssumptions); // only fetch standard variables when an assumption is selected
    const noStdVariablesSelected = stdVariableNumber === "";
    const standardizeDisabled = isEmpty(selectedAssumptions) || noStdVariablesSelected;

    const { data: assumptionsForStandardizing } = useGetAssumptionsForStandardizingQuery({
        includeSuggested: showAll,
        hideInactive,
        pageSize,
        pageNumber,
        totalPages,
        variableDescSearch: search,
    });

    const {
        data: stdVariables,
        isLoading: isLoadingStdVariables,
        isFetching: isFetchingStdVariables,
    } = useGetStdVariablesQuery(undefined, { skip: !fetchStdVariables });

    const [updateAssumptionStandardizing, updateAssumptionStandardizingStatus] = useUpdateStandardizingForAssumptionMutation();
    const [createStdVariable, createStdVariableStatus] = useCreateStdVariableMutation();

    const allSelected =
        selectedAssumptions.length === assumptionsForStandardizing?.assumptionStandardizingList.length &&
        !isEmpty(assumptionsForStandardizing?.assumptionStandardizingList);

    const stdVariablesList = useMemo(() => {
        return (stdVariables ?? []).map((v) => ({
            label: `${v.stdVariableDesc} (${v.stdVariableUnits})`,
            value: v.stdVariableNumber,
        }));
    }, [stdVariables]);

    const onSelectAll = () => {
        let newSelectedAssumptions: AssumptionStandardizingListItem[] = [];

        // Select all assumptions
        if (assumptionsForStandardizing !== undefined && !allSelected) {
            newSelectedAssumptions = assumptionsForStandardizing.assumptionStandardizingList.map((assumption) => assumption);
        }

        setSelectedAssumptions(newSelectedAssumptions);
    };

    const onPageChange = (pageNumber: number) => {
        setSelectedAssumptions([]);

        onChange(pageNumber, "pageNumber");
    };

    const onAssumptionClick = (assumption: AssumptionStandardizingListItem) => {
        setActiveAssumption(assumption);

        setStdVariableNumber(assumption.stdVariableNumber ?? "");
    };

    const onAssumptionChecked = (assumption: AssumptionStandardizingListItem) => {
        let newSelectedAssumptions: AssumptionStandardizingListItem[] = [];

        // Uncheck assumption
        if (selectedAssumptions.find((a) => a.assumptionNumber === assumption.assumptionNumber)) {
            newSelectedAssumptions = selectedAssumptions.filter((a) => a.assumptionNumber !== assumption.assumptionNumber);
        }
        // Check assumption
        else {
            newSelectedAssumptions = [...selectedAssumptions];

            newSelectedAssumptions.push(assumption);
        }

        setSelectedAssumptions(newSelectedAssumptions);
    };

    const onSelectStandardVariable = (value: string) => {
        setStdVariableNumber(value);
    };

    const onStandardizeClick = async () => {
        // Do nothing if saving is in progress
        if (updateAssumptionStandardizingStatus.isLoading) {
            return;
        }

        // To update standardizing for assumption,
        // an assumption has to be selected
        // and at least one standard variable has to be selected
        if (!isEmpty(selectedAssumptions) && !noStdVariablesSelected) {
            const dateActivated = new Date(Date.now()).toISOString();

            const assumptions = selectedAssumptions.map((assumption) => ({
                assumptionNumber: assumption.assumptionNumber,
                stdVariableNumber,
                dateActivated,
            }));

            try {
                await updateAssumptionStandardizing({ assumptions }).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onSaveClick = useCallback(
        async ({ description, unit }: { description: string; unit: string }) => {
            // Do nothing if saving is in progress
            if (createStdVariableStatus.isLoading) {
                return;
            }

            // To create new standard variable,
            // description and unit need to be provided
            if (!isEmpty(description) && !isEmpty(unit)) {
                const stdVariable = {
                    stdVariableDesc: description,
                    stdVariableUnits: unit,
                };

                try {
                    const response = await createStdVariable({ stdVariable }).unwrap();

                    // On success, automatically set newly created
                    // standard variable
                    if (response.responseStatus === "success") {
                        // If standard variable is not mapped,
                        // set newly created standard variable to that.
                        // If standard variable is mapped,
                        // do nothing
                        if (isEmpty(stdVariableNumber)) {
                            setStdVariableNumber(response.stdVariableNumber);
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [stdVariableNumber, createStdVariable, createStdVariableStatus],
    );

    return (
        <div className="flex-row fill-height">
            <div className="w-70 flex-grow pr-4">
                {assumptionsForStandardizing !== undefined && (
                    <Standardize
                        name={name}
                        fullName={fullName}
                        showAll={showAll}
                        hideInactive={hideInactive}
                        search={search}
                        allSelected={allSelected}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        tableComponent={
                            <AssumptionsTable
                                activeAssumption={activeAssumption}
                                selectedAssumptions={selectedAssumptions}
                                standardizingList={assumptionsForStandardizing.assumptionStandardizingList}
                                isInteractable
                                onClick={onAssumptionClick}
                                onChange={onAssumptionChecked}
                            />
                        }
                        totalRows={assumptionsForStandardizing?.totalRows}
                        totalPages={assumptionsForStandardizing?.totalPages || 1} // 0 causes issues for IdsTablePagination
                        onSelectAll={onSelectAll}
                        onChange={onChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
            <div className="vertical-divider" />
            {/* NOTE: Key here causes small freeze up and errors in console,
                      but otherwise dropdown default values do not get updated */}
            <div
                key={
                    activeAssumption === null
                        ? "assumption-none"
                        : `assumption-${activeAssumption.assumptionNumber}-${selectedAssumptions.length}-${isFetchingStdVariables}`
                }
                className="w-30 flex-grow pl-4"
            >
                {fetchStdVariables && !isLoadingStdVariables && (
                    <SuggestedStandard
                        id={id}
                        name={name}
                        standardComponent={
                            <StandardVariable
                                stdVariableNumber={stdVariableNumber}
                                stdVariablesList={stdVariablesList}
                                onSelect={onSelectStandardVariable}
                            />
                        }
                        modalFields={addStdVariableFields}
                        standardizeDisabled={standardizeDisabled}
                        isLoading={createStdVariableStatus.isLoading}
                        isSuccess={createStdVariableStatus.isSuccess}
                        additionalComponent={<AssumptionsMapTable stdVariableNumber={stdVariableNumber} />}
                        onStandardizeClick={onStandardizeClick}
                        onSaveClick={onSaveClick}
                    />
                )}
            </div>
        </div>
    );
};
