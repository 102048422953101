import type { Options } from "@popperjs/core";

export const popperOptions: { [key: string]: Options } = {
    topStart: {
        placement: "top-start",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["bottom-start"],
                },
            },
        ],
    },
    top: {
        placement: "top",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["bottom"],
                },
            },
        ],
    },
    topEnd: {
        placement: "top-end",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["bottom-end"],
                },
            },
        ],
    },
    rightStart: {
        placement: "right-start",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["left-start"],
                },
            },
        ],
    },
    right: {
        placement: "right",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["left"],
                },
            },
        ],
    },
    rightEnd: {
        placement: "right-end",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["left-end"],
                },
            },
        ],
    },
    bottomStart: {
        placement: "bottom-start",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["top-start"],
                },
            },
        ],
    },
    bottom: {
        placement: "bottom",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["top"],
                },
            },
        ],
    },
    bottomEnd: {
        placement: "bottom-end",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["top-end"],
                },
            },
        ],
    },
    leftStart: {
        placement: "left-start",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["right-start"],
                },
            },
        ],
    },
    left: {
        placement: "left",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["right"],
                },
            },
        ],
    },
    leftEnd: {
        placement: "left-end",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    padding: 80, // Without this prop the list overflows Window Tabs
                    fallbackPlacements: ["right-end"],
                },
            },
        ],
    },
};
