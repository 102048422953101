import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import {
    IdsButton,
    IdsButtonGroup,
    IdsCheckbox,
    IdsDropdown,
    IdsFieldWrapper,
    IdsModal,
    IdsTable,
    IdsTableCell,
    IdsTableRow,
    IdsText,
} from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSavingsCalculationsGridStyle } from "pages/BenchmarkTrm/utils";

import { useUpdateStandardizingForAssumptionMutation, useGetStdVariablesQuery } from "store/apiSlice";

import { circleXmarkSolidIcon } from "utils/icons";

import type { AssumptionStandardizingUpdateModel } from "types";
import type { SavingsCalculationsMatrix } from "./SavingsCalculations";

export const STANDARDIZE_VARIABLES_MODAL_STYLE = {
    width: "452px",
    maxWidth: "100%",
    height: "100%",
};

const style = getSavingsCalculationsGridStyle();

export const StandardizeVariables: React.FC<{
    assumptionsForStandardizing: SavingsCalculationsMatrix;
    onClose: () => void;
}> = ({ assumptionsForStandardizing, onClose }) => {
    const [selectedAssumptionsForStandardizing, setSelectedAssumptionsForStandardizing] =
        useState<SavingsCalculationsMatrix>(assumptionsForStandardizing);

    const [selectedStdVariable, setSelectedStdVariable] = useState("");

    const { data: stdVariables, isLoading: isLoadingStdVariables } = useGetStdVariablesQuery();
    const stdVariableList = useMemo(() => {
        return (stdVariables ?? []).map((v) => ({
            label: `${v.stdVariableDesc} (${v.stdVariableUnits})`,
            value: v.stdVariableNumber,
        }));
    }, [stdVariables]);

    const [updateAssumptionStandardizing, updateAssumptionStandardizingStatus] = useUpdateStandardizingForAssumptionMutation();

    const areAllRowsSelected = Object.keys(selectedAssumptionsForStandardizing).length === Object.keys(assumptionsForStandardizing).length;

    const onSelectAll = () => {
        // Deselect all
        if (areAllRowsSelected) {
            setSelectedAssumptionsForStandardizing({});
        }
        // Select all
        else {
            setSelectedAssumptionsForStandardizing(assumptionsForStandardizing);
        }
    };

    const onRowSelect = (key: string) => {
        let newSelectedAssumptionsForStandardizing = { ...selectedAssumptionsForStandardizing };

        // Select assumptions
        if (selectedAssumptionsForStandardizing[key] === undefined) {
            newSelectedAssumptionsForStandardizing[key] = assumptionsForStandardizing[key];
        }
        // Deselect assumption
        else {
            delete newSelectedAssumptionsForStandardizing[key];
        }

        setSelectedAssumptionsForStandardizing(newSelectedAssumptionsForStandardizing);
    };

    const onConfirm = async () => {
        // Do nothing if saving is in progress
        if (updateAssumptionStandardizingStatus.isLoading) {
            return;
        }

        try {
            const assumptions: AssumptionStandardizingUpdateModel[] = [];
            const dateActivated = new Date(Date.now()).toISOString();

            Object.keys(selectedAssumptionsForStandardizing).forEach((assumptionKey) => {
                Object.keys(selectedAssumptionsForStandardizing[assumptionKey]).forEach((algorithmKey) => {
                    // Only standardize those entries that have assumptionNumber
                    if (selectedAssumptionsForStandardizing[assumptionKey][algorithmKey].assumptionNumber) {
                        assumptions.push({
                            assumptionNumber: selectedAssumptionsForStandardizing[assumptionKey][algorithmKey].assumptionNumber,
                            stdVariableNumber: selectedStdVariable,
                            dateActivated,
                        });
                    }
                });
            });

            const response = await updateAssumptionStandardizing({ assumptions }).unwrap();

            if (response.responseStatus === "success") {
                onClose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout empty-state">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Standardize Variables
                </IdsText>
            </div>
            <div slot="main" style={STANDARDIZE_VARIABLES_MODAL_STYLE}>
                <div className="flex-column fill-height">
                    <IdsFieldWrapper htmlFor="std-variable" wrapperLabel="Standardize variables to">
                        <IdsDropdown
                            idValue="std-variable"
                            isSearchable={!isLoadingStdVariables} // A hack to properly preselect the dropdown value
                            placeholder={isLoadingStdVariables ? "Loading" : "Search Standard Variable"}
                            items={stdVariableList}
                            changeHandler={(value) => setSelectedStdVariable(value)}
                            clearHandler={() => setSelectedStdVariable("")}
                        />
                    </IdsFieldWrapper>
                    <div
                        className="flex-column flex-min-content rounded-edges-round px-2"
                        style={{ border: "1px solid var(--theme-base-border)" }}
                    >
                        <IdsTable spacing="lg">
                            <IdsTableRow rowType="table-heading-row" customClasses="sticky-top">
                                <IdsTableCell style={style.select}>
                                    <IdsCheckbox idValue="select-all" defaultChecked={areAllRowsSelected} changeHandler={onSelectAll} />
                                </IdsTableCell>
                                <IdsTableCell cellType="table-heading-cell" heading="Std." style={style.standardized} />
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="Variable description"
                                    style={{
                                        ...style.variableDescription,
                                        width: "auto",
                                        minWidth: style.variableDescription.width,
                                    }}
                                />
                            </IdsTableRow>
                            {Object.keys(assumptionsForStandardizing).map((key) => (
                                <IdsTableRow key={key}>
                                    <IdsTableCell style={style.select}>
                                        <IdsCheckbox
                                            idValue={key}
                                            defaultChecked={selectedAssumptionsForStandardizing[key] !== undefined}
                                            changeHandler={() => onRowSelect(key)}
                                        />
                                    </IdsTableCell>
                                    <IdsTableCell style={style.standardized}>
                                        <FontAwesomeIcon
                                            icon={circleXmarkSolidIcon}
                                            color="var(--theme-feedback-danger)"
                                            size="lg"
                                            fixedWidth
                                        />
                                    </IdsTableCell>
                                    <IdsTableCell
                                        style={{
                                            ...style.variableDescription,
                                            width: "auto",
                                            minWidth: style.variableDescription.width,
                                        }}
                                    >
                                        <IdsText>{key}</IdsText>
                                    </IdsTableCell>
                                </IdsTableRow>
                            ))}
                        </IdsTable>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="lg">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton isDisabled={isEmpty(selectedAssumptionsForStandardizing)} clickHandler={onConfirm}>
                        Confirm
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};
