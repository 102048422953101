import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: UpgradeTrmState = {
    publisherNumber: "",
    sourceTrmNumber: "",
    targetTrmNumber: "",
    isUpgrading: false,
};

export const upgradeTrmSlice = createSlice({
    name: "upgrade-trm",
    initialState,
    reducers: {
        startUpgrade: (state, action: PayloadAction<StartUpgradePayload>) => {
            state.publisherNumber = action.payload.publisherNumber;
            state.sourceTrmNumber = action.payload.sourceTrmNumber;
            state.targetTrmNumber = action.payload.targetTrmNumber;
            state.isUpgrading = true;
        },
    },
});

type UpgradeTrmState = {
    publisherNumber: string;
    sourceTrmNumber: string;
    targetTrmNumber: string;
    isUpgrading: boolean;
};

type StartUpgradePayload = Omit<UpgradeTrmState, "isUpgrading">;

export const { startUpgrade } = upgradeTrmSlice.actions;

export default upgradeTrmSlice.reducer;
