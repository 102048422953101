import { useAppDispatch, useAppSelector } from "store/hooks";

import { windowAdd } from "store/window/actions";

import { LoginForm } from "./LoginForm";

import { clientRoute } from "utils/constants";
import { isStatusExpired, isQrCode } from "utils/user";
import { windowContainerTypes } from "utils/window";

const Login: React.FC = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user);

    const pathParts = window.location.pathname.split("/");

    // Open Reset Password view
    if (pathParts[1] === clientRoute.resetPassword) {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Login,
                name: "Reset Password",
                component: "ResetPassword",
                showHeader: false,
                showTabs: false,
            }),
        );
    }
    // Open SAML view
    else if (pathParts.includes(clientRoute.sso)) {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Login,
                name: "Single Sign-On",
                component: "SsoWrapper",
                showHeader: false,
                showTabs: false,
            }),
        );
    }
    // Open Two Factor Authentication set up view
    else if (user && isQrCode(user)) {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Login,
                name: "Setup Authenticator",
                component: "SetupAuthenticator",
                showHeader: false,
                showTabs: false,
                props: {
                    userName: user.userName,
                    qrCode: user.qrCode,
                },
            }),
        );
    }
    // Open Change Password view
    else if (user && isStatusExpired(user)) {
        dispatch(
            windowAdd({
                containerName: windowContainerTypes.Login,
                name: "Change Password",
                component: "ChangePassword",
                showHeader: false,
                showTabs: false,
                props: {
                    accessToken: user.accessToken,
                    userName: user.userName,
                },
            }),
        );
    }

    return <LoginForm />;
};

export default Login;
