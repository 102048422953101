import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { IdsText, IdsFieldWrapper, IdsPasswordInput, IdsTextInput, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { ReturnButton } from "./ReturnButton";
import { Wrapper } from "./Wrapper";

import { Captcha } from "components/Captcha";

import { useResetPasswordMutation, useTwoFactorEnabledQuery } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";
import { resetLogin } from "store/login/actions";

import { clearBrowserUrl } from "utils/window";

export const ResetPassword: React.FC = () => {
    const dispatch = useAppDispatch();

    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const [error, setError] = useState<string | null>(null);

    const pathParts = window.location.pathname.split("/");
    const userNumber: string | undefined = pathParts[2];
    const temporaryPassword: string | undefined = pathParts[3];

    const { data, isLoading } = useTwoFactorEnabledQuery({ userName: userNumber }, { skip: userNumber === undefined });

    const [resetPassword, resetPasswordStatus] = useResetPasswordMutation();

    const isAuthCodeRequired = data !== undefined && data.twoFactorRequired && data.qrCodeExists;

    const onChange = useCallback((name: string, value: string | null) => {
        if (name === "newPassword" && typeof value === "string") {
            setNewPassword(value);
        } else if (name === "repeatPassword" && typeof value === "string") {
            setRepeatPassword(value);
        } else if (name === "authCode" && typeof value === "string") {
            setAuthCode(value);
        } else if (name === "recaptcha") {
            setRecaptcha(value);
        }
    }, []);

    const onSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!recaptcha || !userNumber || !temporaryPassword) {
            return;
        }

        if (newPassword.trim() !== repeatPassword.trim()) {
            setError("Passwords do not match");

            return;
        }

        try {
            const response = await resetPassword({
                userNumber,
                params: {
                    temporaryPassword,
                    newPassword,
                    recaptcha,
                    authCode,
                },
            }).unwrap();

            if (response.responseStatus === "failure") {
                setError(response.responseMessage);

                return;
            }

            clearBrowserUrl();

            dispatch(resetLogin());
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper>
            <form className="flex-column align-center gap-5" onSubmit={onSave}>
                <IdsText size="sm" weight="bold" component="h3">
                    Reset password for your account
                </IdsText>
                {!isLoading && (
                    <div className="fill-width">
                        <IdsFieldWrapper
                            wrapperLabel="New password"
                            isRequired
                            isInvalid={error !== null}
                            helperInvalidText={error ?? undefined}
                        >
                            <IdsPasswordInput
                                placeholder="Enter your new password"
                                defaultValue={newPassword}
                                changeHandler={(value) => onChange("newPassword", value)}
                                rest={{
                                    autoFocus: true,
                                }}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper wrapperLabel="Confirm password" isRequired>
                            <IdsPasswordInput
                                placeholder="Confirm your new password"
                                defaultValue={repeatPassword}
                                changeHandler={(value) => onChange("repeatPassword", value)}
                            />
                        </IdsFieldWrapper>
                        {isAuthCodeRequired && (
                            <IdsFieldWrapper wrapperLabel="Authentication Code" isRequired>
                                <IdsTextInput
                                    placeholder="Enter authentication code"
                                    defaultValue={authCode}
                                    changeHandler={(value) => onChange("authCode", value)}
                                />
                            </IdsFieldWrapper>
                        )}
                        <div className="flex-column align-center pb-4">
                            <Captcha onChange={(token) => onChange("recaptcha", token)} />
                        </div>
                        <IdsButtonGroup position="justify">
                            <ReturnButton isForForm />
                            <IdsButton
                                type="submit"
                                isDisabled={
                                    isEmpty(newPassword) ||
                                    isEmpty(repeatPassword) ||
                                    (isAuthCodeRequired && isEmpty(authCode)) ||
                                    recaptcha === null ||
                                    resetPasswordStatus.isLoading
                                }
                            >
                                <>{resetPasswordStatus.isLoading ? "Saving..." : "Save New Password"}</>
                            </IdsButton>
                        </IdsButtonGroup>
                    </div>
                )}
            </form>
        </Wrapper>
    );
};
