import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { IdsCard } from "@emergn-infinity/ids-react";

import { tourNextPage, tourComplete, TourPage } from "./tourSlice";

import { TourImage } from "./TourImage";
import { TourContent } from "./TourContent";

import { TourItems } from "./utils";
import { hasRights } from "utils/user";

import type { IndexSignature } from "types";

export const TourCard: React.FC<{
    page: TourPage;
    variant: "primary" | "secondary";
}> = ({ page, variant }) => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user);

    const items = useMemo(() => {
        const items: IndexSignature<Tour> = {};

        if (user) {
            Object.keys(TourItems).forEach((key) => {
                // Hide tour item if user does not have all of the
                // necessary rights to access that tab
                if (isEmpty(TourItems[key].rights) || TourItems[key].rights.every((code) => hasRights(code))) {
                    items[key] = TourItems[key];
                }
            });
        }

        return items;
    }, [user]);

    const { header, text, imageUrl } = items[page];

    const currentPage = Object.keys(items).findIndex((key) => key === page);
    const totalPages = Object.keys(items).length - 1; // exclude "Welcome" page

    const onClick = () => {
        const nextPage = Object.keys(items)[currentPage + 1];

        dispatch(tourNextPage({ page: nextPage as TourPage }));
    };

    const onClose = () => {
        dispatch(tourComplete());
    };

    return (
        <div style={{ position: "relative", zIndex: 13 }}>
            {variant === "secondary" && (
                <>
                    <div
                        style={{
                            borderLeft: "14px solid transparent",
                            borderRight: "14px solid transparent",
                            borderBottom: "14px solid var(--ids-semantic-border-color-neutral-subtlest)",
                            position: "absolute",
                            top: "-13px",
                            left: "13px",
                            width: 0,
                            height: 0,
                        }}
                    />
                    <div
                        style={{
                            borderLeft: "12px solid transparent",
                            borderRight: "12px solid transparent",
                            borderBottom: "12px solid #ffffff",
                            position: "absolute",
                            top: "-11px",
                            left: "15px",
                            width: 0,
                            height: 0,
                        }}
                    />
                </>
            )}
            <IdsCard customClasses="border-none rounded-edges-rounder no-overflow p-0">
                {variant === "primary" && (
                    <div className="flex-row" slot="slot1" style={{ height: "320px" }}>
                        <div style={{ width: "280px" }}>
                            <TourImage imageUrl={imageUrl} />
                        </div>
                        <div className="p-4" style={{ width: "320px" }}>
                            <TourContent header={header} text={text} variant={variant} onClick={onClick} />
                        </div>
                    </div>
                )}

                {variant === "secondary" && (
                    <div className="flex-column" slot="slot1" style={{ width: "320px" }}>
                        <div
                            className="p-3"
                            style={{
                                borderWidth: "1px 1px 0 1px",
                                borderStyle: "solid",
                                borderColor: "var(--ids-semantic-border-color-neutral-subtlest)",
                                borderTopLeftRadius: "var(--ids-semantic-border-radius-rounder)",
                                borderTopRightRadius: "var(--ids-semantic-border-radius-rounder)",
                            }}
                        >
                            <TourContent
                                header={header}
                                text={text}
                                variant={variant}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onClick={onClick}
                                onClose={onClose}
                            />
                        </div>
                        <div style={{ height: "148px" }}>
                            <TourImage imageUrl={imageUrl} />
                        </div>
                    </div>
                )}
            </IdsCard>
        </div>
    );
};

type Tour = (typeof TourItems)[keyof typeof TourItems];
