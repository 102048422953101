import { StandardizeRights } from "utils/constants";

// Match keys with Tabs from pages/Home/utils
export const TourItems = {
    Welcome: {
        header: "Welcome to TRMulator",
        text: "The TRMulator serves as a central repository for all available TRMs and provides answers to common research questions as interpreted according to a dedicated AEG standard.",
        imageUrl: "https://files.insight.visiondsm.com/public/TRMulator-Tour-Welcome.webp",
        rights: [],
    },
    ExploreTrms: {
        header: "Explore TRMs",
        text: "View details of any TRM measure as written and perform TRM-compliant savings calculations.",
        imageUrl: "https://files.insight.visiondsm.com/public/TRMulator-Tour-Explore_TRM.webp",
        rights: [],
    },
    AnalyzeMeasures: {
        header: "Analyze Measures",
        text: "Discover Energy Efficiency Program offerings for a measure of interest.",
        imageUrl: "https://files.insight.visiondsm.com/public/TRMulator-Tour-Analyze_Measures.webp",
        rights: [],
    },
    BenchmarkTrm: {
        header: "Benchmark TRM",
        text: "Compare measure-level details across TRMs including measure life details, cost details, savings calculations, default assumptions, and sources.",
        imageUrl: "https://files.insight.visiondsm.com/public/TRMulator-Tour-Benchmark_TRM.webp",
        rights: [],
    },
    StandardizeData: {
        header: "Standardize data",
        text: "Map TRM-specific data to dedicated AEG standards for reuse by all practice areas.",
        imageUrl: "https://files.insight.visiondsm.com/public/TRMulator-Tour-Standardize_Data.webp",
        rights: StandardizeRights,
    },
} as const;
