import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";

import { IdsButton, IdsButtonGroup, IdsDropdown, IdsFieldWrapper } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BenchmarkTrmContext } from "pages/BenchmarkTrm";
import { selectorChange } from "pages/BenchmarkTrm/benchmarkTrmSlice";

import { useGetFuelTypesByMeasureQuery, useGetSectorsByMeasureQuery, useGetVintagesByMeasureQuery } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";

import { arrowProgressRegularIcon, filterRegularIcon, xmarkRegularIcon } from "utils/icons";
import { isAdminUser } from "utils/user";

import type { SavingsCalculationsMatrix } from "./SavingsCalculations";

export const GridFilter: React.FC<{
    selectedMeasure: string;
    selectedSector?: string;
    selectedVintage?: string;
    selectedFuelType?: string;
    isSavingsCalculationsBenchmark?: boolean;
    assumptionsForStandardizing?: SavingsCalculationsMatrix;
    setIsStandardizing?: (isStandardizing: boolean) => void;
    setAssumptionsForStandardizing?: (assumptionsForStandardizing: SavingsCalculationsMatrix) => void;
}> = ({
    selectedMeasure,
    selectedSector,
    selectedVintage,
    selectedFuelType,
    isSavingsCalculationsBenchmark = false,
    assumptionsForStandardizing,
    setIsStandardizing,
    setAssumptionsForStandardizing,
}) => {
    const dispatch = useAppDispatch();

    const { isSelecting, setIsSelecting } = useContext(BenchmarkTrmContext);

    const isStandardizeVariablesDisabled = isEmpty(selectedSector) || isEmpty(selectedVintage) || isEmpty(selectedFuelType);
    const isStandardizeSelectionDisabled = isEmpty(assumptionsForStandardizing);

    const { data: sectors, isLoading: isLoadingSectors } = useGetSectorsByMeasureQuery(
        { measureNumber: selectedMeasure },
        { skip: isEmpty(selectedMeasure) },
    );
    const sectorList = useMemo(() => {
        return (sectors ?? []).map((sector) => ({
            value: sector.sectorNumber,
            label: sector.sectorName,
        }));
    }, [sectors]);

    const { data: vintages, isLoading: isLoadingVintages } = useGetVintagesByMeasureQuery(
        { measureNumber: selectedMeasure },
        { skip: isEmpty(selectedMeasure) },
    );
    const vintageList = useMemo(() => {
        return (vintages ?? []).map((vintage) => ({
            value: vintage.vintageNumber,
            label: vintage.vintageName,
        }));
    }, [vintages]);

    const { data: fuelTypes, isLoading: isLoadingFuelTypes } = useGetFuelTypesByMeasureQuery(
        { measureNumber: selectedMeasure },
        { skip: isEmpty(selectedMeasure) || !isSavingsCalculationsBenchmark },
    );
    const fuelTypeList = useMemo(() => {
        return (fuelTypes ?? []).map((ft) => ({
            value: ft.fuelTypeNumber,
            label: ft.fuelType,
        }));
    }, [fuelTypes]);

    const onCancel = () => {
        setIsSelecting?.(false);
        setAssumptionsForStandardizing?.({});
    };

    const onSectorChange = (value: string) => {
        const sector = sectorList.find((s) => s.value === value)?.label;

        dispatch(selectorChange({ selector: "sector", value, label: sector }));
    };

    const onVintageChange = (value: string) => {
        const vintage = vintageList.find((v) => v.value === value)?.label;

        dispatch(selectorChange({ selector: "vintage", value, label: vintage }));
    };

    const onFuelTypeChange = (value: string) => {
        const fuelType = fuelTypeList.find((ft) => ft.value === value)?.label;

        dispatch(selectorChange({ selector: "fuelType", value, label: fuelType }));
    };

    return (
        <div className="flex-row align-center justify-space-between py-3">
            {isSelecting && assumptionsForStandardizing ? (
                <div className="bg-theme-base rounded-edges-rounder fill-width p-2">
                    <IdsButtonGroup spaceBetween="md">
                        <IdsButton variant="tertiary" color="neutral" padding="sm" clickHandler={onCancel}>
                            <div className="flex-row align-center gap-2">
                                <FontAwesomeIcon icon={xmarkRegularIcon} size="lg" fixedWidth />
                                {Object.keys(assumptionsForStandardizing).length > 0
                                    ? `${Object.keys(assumptionsForStandardizing).length} Selected`
                                    : "Cancel Selection"}
                            </div>
                        </IdsButton>
                        <div style={{ border: "1px solid var(--theme-base-border)", height: 16 }} />
                        <IdsButton
                            variant="tertiary"
                            padding="sm"
                            isDisabled={isStandardizeSelectionDisabled}
                            clickHandler={() => setIsStandardizing?.(true)}
                        >
                            <div className="flex-row align-center gap-2">
                                <FontAwesomeIcon icon={arrowProgressRegularIcon} size="lg" fixedWidth />
                                Standardize Selection
                            </div>
                        </IdsButton>
                    </IdsButtonGroup>
                </div>
            ) : (
                <div className="flex-row align-center gap-2">
                    <div className="flex-row align-center gap-2">
                        <FontAwesomeIcon icon={filterRegularIcon} color="var(--ids-semantic-ink-color-brand-b-subtlest)" fixedWidth />
                        <IdsFieldWrapper
                            key={`sector-${selectedMeasure}`}
                            htmlFor="select-sector"
                            isDisabled={isEmpty(selectedMeasure)}
                            isInvalid={isSavingsCalculationsBenchmark && isEmpty(selectedSector) && !isEmpty(selectedMeasure)}
                            clearMargin
                        >
                            <IdsDropdown
                                idValue="select-sector"
                                isSearchable={!isLoadingSectors} // A hack to properly preselect the dropdown value
                                items={sectorList}
                                initialSelectedItems={selectedSector ? [selectedSector] : []}
                                placeholder={isLoadingSectors ? "Loading" : "TRM Sector"}
                                size="sm"
                                changeHandler={onSectorChange}
                                clearHandler={() => onSectorChange("")}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper
                            key={`vintage-${selectedMeasure}`}
                            htmlFor="select-vintage"
                            isDisabled={isEmpty(selectedMeasure)}
                            isInvalid={isSavingsCalculationsBenchmark && isEmpty(selectedVintage) && !isEmpty(selectedMeasure)}
                            clearMargin
                        >
                            <IdsDropdown
                                idValue="select-vintage"
                                isSearchable={!isLoadingVintages} // A hack to properly preselect the dropdown value
                                items={vintageList}
                                initialSelectedItems={selectedVintage ? [selectedVintage] : []}
                                placeholder={isLoadingVintages ? "Loading" : "TRM Vintage"}
                                size="sm"
                                changeHandler={onVintageChange}
                                clearHandler={() => onVintageChange("")}
                            />
                        </IdsFieldWrapper>
                        {isSavingsCalculationsBenchmark && (
                            <IdsFieldWrapper
                                key={`fuel-type-${selectedMeasure}`}
                                htmlFor="select-fuel-type"
                                isDisabled={isEmpty(selectedMeasure)}
                                isInvalid={isEmpty(selectedFuelType) && !isEmpty(selectedMeasure)}
                                clearMargin
                            >
                                <IdsDropdown
                                    idValue="select-fuel-type"
                                    isSearchable={!isLoadingFuelTypes} // A hack to properly preselect the dropdown value
                                    items={fuelTypeList}
                                    initialSelectedItems={selectedFuelType ? [selectedFuelType] : []}
                                    placeholder={isLoadingFuelTypes ? "Loading" : "TRM Fuel Type"}
                                    size="sm"
                                    changeHandler={onFuelTypeChange}
                                    clearHandler={() => onFuelTypeChange("")}
                                />
                            </IdsFieldWrapper>
                        )}
                    </div>
                    {isSavingsCalculationsBenchmark && isAdminUser() && (
                        <IdsButton
                            variant="secondary"
                            padding="sm"
                            isDisabled={isStandardizeVariablesDisabled}
                            clickHandler={() => setIsSelecting?.(true)}
                        >
                            <div className="flex-row align-center gap-2">
                                <FontAwesomeIcon icon={arrowProgressRegularIcon} size="lg" fixedWidth />
                                Standardize Variables
                            </div>
                        </IdsButton>
                    )}
                </div>
            )}
        </div>
    );
};
